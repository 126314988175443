<template>
  <profile-layout :title="$t('user.resetPassword.title')" :is-loading="isSubmitted">
    <template #menu>
      <ul class="nav flex-column profile-menu mb-5">
        <li class="nav-item">
          <router-link to="login" custom v-slot="{href}">
            <a class="nav-link" :href="href">
              {{ $t('user.forgotPassword.backToLogin') }}
            </a>
          </router-link>
        </li>
      </ul>
    </template>
    <template #content>
      <div class="row" v-if="!showSuccess && resetTokenValid">
        <div class="col">
          <p class="mb-4">
            {{ $t('user.resetPassword.reset_text') }}
          </p>
        </div>
        <div class="mb-3">
          <div class="form-floating">
            <input type="password" class="form-control" :class="{'is-invalid': showFailure}" id="password-new" aria-errormessage="password-new-error" autocomplete="off" autofocus :placeholder="$t('user.resetPassword.newPassword')" v-model="password" @keyup="analyzePassword" @change="analyzePassword" @touchend="analyzePassword" @keyup.enter="submitPassword" :feedback="false">
            <label for="password-new" class="form-label">{{ $t('user.resetPassword.newPassword') }}</label>
          </div>
        </div>
        <div class="mb-3">
          <div class="form-floating">
            <input type="password" class="form-control" :class="{'is-invalid': showFailure}" id="password-new-confirm" aria-errormessage="password-new-confirm-error" autocomplete="off" :placeholder="$t('user.resetPassword.passwordConfirm')" v-model="passwordConfirm" @keyup="analyzePassword" @change="analyzePassword" @touchend="analyzePassword" @keyup.enter="submitPassword" :feedback="false">
            <label for="password-new-confirm" class="form-label">{{ $t('user.resetPassword.passwordConfirm') }}</label>
          </div>
        </div>
        <div class="mb-5 mt-3">
          <terms-and-conditions></terms-and-conditions>
        </div>

        <div class="mb-3 privacy">
          <input type="checkbox" id="termsAndConditions" v-model="termsAndConditionsChecked"/>
          <label for="termsAndConditions">{{ $t('user.onboarding.termsAndConditionsCheck') }}</label>
        </div>
        <div class="mb-3 privacy">
          <input type="checkbox" id="privacy" v-model="privacyChecked"/>
          <Translation keypath="user.onboarding.privacyCheck" tag="label" for="privacy">
            <a :href="$t('links.privacy')" target="_blank"> {{ $t('user.onboarding.privacyLinkText') }}</a>
          </Translation>
        </div>
      </div>
      <div class="row" v-else-if="!resetTokenValid">
        <div class="col">
          <div class="alert alert-danger d-flex align-items-start" role="alert">
            <i class="bi bi-exclamation-circle-fill me-2"></i>
            <span v-html="$t('user.resetPassword.tokenInvalid')"></span>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col">
          <div class="alert alert-success d-flex align-items-start" role="alert">
            <i class="bi bi-check-circle-fill me-2"></i>
            <span v-html="$t('user.resetPassword.success')"></span>
          </div>
        </div>
      </div>
      <FaqPanel class="mt-3"/>
    </template>
    <template #aside v-if="!showSuccess && resetTokenValid">
      <ul>
        <li :class="rulePasswordLength ? 'text-success' : !password ? '' : 'text-danger'">{{ $t('profile.password.length') }}</li>
        <li :class="rulePasswordUpperLowerCase ? 'text-success' : !password ? '' : 'text-danger'">{{ $t('profile.password.upperLowerCase') }}</li>
        <li :class="rulePasswordSpecialChars ? 'text-success' : !password ? '' : 'text-danger'">{{ $t('profile.password.specialChars') }}</li>
        <li :class="rulePasswordMatch ? 'text-success' : !password ? '' : 'text-danger'">{{ $t('profile.password.match') }}</li>
      </ul>
    </template>

    <div class="col-12 col-md-5 offset-md-3">
      <DefaultButton
        :label="$t('user.resetPassword.savePassword')"
        @click="submitPassword"
        @keyup.enter="submitPassword"
        class="btn-dark"
        :disabled="!allRulesPassed ||!privacyChecked || !termsAndConditionsChecked"
        v-if="!showSuccess && resetTokenValid"
      />
      <router-link :to="{name: 'login'}" class="btn btn-dark" v-if="showSuccess">
        {{ $t('user.forgotPassword.toLogin') }}
      </router-link>
    </div>
  </profile-layout>
</template>

<script>
import {mapActions} from "pinia"
import {useLoginStore} from "@/store/modules/login/login.js";
import {useRoute} from 'vue-router';
import {Translation} from 'vue-i18n'
import ProfileLayout from "@/components/profile/ProfileLayout.vue";
import FaqPanel from "@/components/ui/FaqPanel.vue";
import TermsAndConditions from "@/components/profile/TermsAndConditions";

export default {
  data() {
    return {
      password: null,
      passwordConfirm: null,
      rulePasswordLength: false,
      rulePasswordMatch: false,
      rulePasswordSpecialChars: false,
      rulePasswordUpperLowerCase: false,
      allRulesPassed: false,
      showSuccess: false,
      showFailure: false,
      bgsAuthSet: {
        token: null,
        password: null,
      },
      resetTokenValid: false,
      privacyChecked: false,
      termsAndConditionsChecked: false,
      isSubmitted: false,
    }
  },
  methods: {
    async analyzePassword() {
      this.allRulesPassed = true;
      this.showFailure = false;
      this.showSuccess = false;

      if (this.password !== null) {
        this.rulePasswordLength = this.isPasswordLengthValid(this.password.trim(), 7);
        this.rulePasswordSpecialChars = this.containsSpecialChars(this.password.trim());
        this.rulePasswordUpperLowerCase = this.containsUpperAndLowerCase(this.password.trim());
        this.rulePasswordMatch = this.isMatchingPassword(this.password.trim(), this.passwordConfirm);
      } else {
        this.rulePasswordLength = false;
        this.allRulesPassed = false;
      }
    },
    isPasswordLengthValid(password, length) {
      if (password.trim().length < length) {
        this.allRulesPassed = false;
        return false;
      }
      return true;
    },
    isMatchingPassword(password, passwordConfirm) {
      if (passwordConfirm === null || password !== passwordConfirm.trim()) {
        this.allRulesPassed = false;
        return false;
      }
      this.bgsAuthSet.password = passwordConfirm;
      return true;
    },
    containsSpecialChars(str) {
      const specialChars = /[^\w\s]|[_]/gi;
      if (!specialChars.test(str)) {
        this.allRulesPassed = false;
        return false;
      }
      return true;
    },
    containsUpperAndLowerCase(str) {
      let containsUpperCase, containsLowerCase = false;
      for (var i = 0; i < str.length; i++) {
        if (str.charAt(i) === str.charAt(i).toUpperCase() && str.charAt(i) != str.charAt(i).toLowerCase() && !containsUpperCase) {
          containsUpperCase = true;
        } else if (str.charAt(i) === str.charAt(i).toLowerCase() && str.charAt(i) != str.charAt(i).toUpperCase() && !containsLowerCase) {
          containsLowerCase = true;
        }
      }
      if (!containsUpperCase || !containsLowerCase) {
        this.allRulesPassed = false;
      }
      return containsUpperCase && containsLowerCase;
    },
    submitPassword() {
      this.isSubmitted = true;
      this.analyzePassword();
      if (this.allRulesPassed) {
        this.resetPassword(this.bgsAuthSet).then(response => {
          if (response.status === 200) {
            this.showSuccess = true;
          } else {
            this.showFailure = true;
          }
        }).catch(error => {
          console.log(error);
          this.showFailure = true;
        }).finally(() => {
          this.isSubmitted = false;
          // TODO show Toast
        });
      }
    },
    ...mapActions(useLoginStore, ["resetPassword", "validateResetToken"])
  },
  components: {
    FaqPanel,
    ProfileLayout,
    TermsAndConditions,
    Translation,
  },
  mounted() {
    const route = useRoute();
    this.validateResetToken(route.query).then(response => {
      if (response && response.status === 200) {
        this.resetTokenValid = true;
        this.bgsAuthSet.token = route.query.token;
      } else {
        this.resetTokenValid = false;
      }
    }).catch(error => {
      this.resetTokenValid = false;
      console.log(error);
    });
  }
}
</script>

<style scoped>

.privacy {
  display: flex;
  align-items: flex-start
}

.privacy > input {
  margin-top: 4px;
}

.privacy > label {
  margin-left: .5em
}

</style>
