<template>

  <div class="container-fluid">
    <hr class="my-5" v-if="(adContentDesktop && Object.keys(adContentDesktop).length > 0)  || (adContentMobile && Object.keys(adContentMobile).length > 0)">
    <div class="row" v-if="(adContentDesktop && Object.keys(adContentDesktop).length > 0)  || (adContentMobile && Object.keys(adContentMobile).length > 0)">
      <div class="col-12 text-center text-uppercase text-muted mb-2">
        – {{ $t('advertisement.title') }} –
      </div>
      <div class="col-12" :class="adContentMobile ? 'd-none d-md-block' : ''" v-if="adContentDesktop && !adContentDesktop.body">
        <a :href="adContentDesktop.redirect_url" target="_blank" class="d-block mx-auto" :style="`width: ${adContentDesktop.width}px; max-width: 100%`">
          <img :src="adContentDesktop.image_url" :alt="adContentDesktop.alt_text" class="img-fluid">
        </a>
      </div>
      <div class="col-12 p-0 html-ad" :class="adContentMobile ? 'd-none d-md-block' : ''" v-else-if="adContentDesktop.body" v-html="adContentDesktop.body"></div>
      <div class="col-12 d-md-none" v-if="adContentMobile && !adContentMobile.body">
        <a :href="adContentMobile.redirect_url" target="_blank" class="d-block mx-auto" :style="`width: ${adContentMobile.width}px; max-width: 100%`">
          <img :src="adContentMobile.image_url" :alt="adContentMobile.alt_text" class="img-fluid">
        </a>
      </div>
      <div class="col-12 p-0 d-md-none html-ad" style="max-width: 100%;" v-else-if="adContentMobile && adContentMobile.body" v-html="adContentMobile.body"></div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "pinia";
import {useAdButlerStore} from "@/store/modules/adbutler/adbutler";

export default {
  props: {
    keywords: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      adContentDesktop: null,
      adContentMobile: null,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    };
  },
  methods: {
    getAdContent(format, zoneId) {
      const requestParams = {
        setId: zoneId,
        language: this.$i18n.locale === 'fr' ? 'fr-FR' : 'de-CH',
        format: format,
        referrer: new URL(window.location.origin + this.$route.path).href,
        userAgent: navigator.userAgent,
        screenWidth: this.screenWidth,
        screenHeight: this.screenHeight,
        ip: '',
      };
      if (this.keywords) {
        requestParams.kw = this.keywords;
      }

      return this.getAbPlacements(requestParams).then(response => {
        const data = response.data;
        const status = data.status;
        const placements = data.placements;
        if (status === 'SUCCESS') {
          return placements.placement_1;
        }
        return placements;
      }).catch(error => {
        console.error(error);
        return {};
      });
    },
    ...mapActions(useAdButlerStore, {getAbPlacements: "getAbPlacements"})
  },
  watch: {
    async adContentDesktop(content) {
      if (content.refresh_time && parseInt(content.refresh_time) > 0) {
        setTimeout(async () => {
          this.adContentDesktop = await this.getAdContent('breaker_desktop', 595655)
        }, parseInt(content.refresh_time) * 1000);
      }
    },
    async adContentMobile(content) {
      if (content.refresh_time && parseInt(content.refresh_time) > 0) {
        setTimeout(async () => {
          this.adContentMobile = await this.getAdContent('mobile', 595656)
        }, parseInt(content.refresh_time) * 1000);
      }
    },
  },
  async created() {
    this.adContentDesktop = await this.getAdContent('breaker_desktop', 595655);
    this.adContentMobile = await this.getAdContent('mobile', 595656);
  }
}
</script>

<style lang="scss">
.html-ad {
  iframe {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
}
</style>
