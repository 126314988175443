<template>
  <div class="back-next-container">
    <Button @click="back" class="p-button-secondary">{{ $t("back") }}</Button>
    <Button @click="next">{{ $t("next") }}</Button>
  </div>
</template>

<script>
export default {
  emits: ["back", "next"],
  methods: {
    back() {
      this.$emit("back");
    },
    next() {
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss" scoped>
.back-next-container {
  display: flex;
  justify-content: space-between;
}
</style>
