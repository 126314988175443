import moment from "moment/moment";
import i18n from "@/i18n";
import generateQrCode from "@/helpers/print/qrcode";
import {getSquadByKey} from "@/helpers/functions";

const {t} = i18n.global;
const stvLogos = require('@/assets/img/stv-logos.svg');
const stvLogosGrey = require('@/assets/img/stv-logos-grey.svg');
const sportLogos = require('@/assets/img/sport-icons.svg');
const insuranceLogo = require('@/assets/img/LOGO-2019-SVK-CAS__Konvertiert_.jpg');

async function openPrintWindow(memberData, memberType, clubMemberships, associationMemberships, sports, isActiveMembership, localOptions) {
  let {
    name = '_blank',
    specs = ['fullscreen=no', 'titlebar=no', 'scrollbars=no', 'menubar=no', 'toolbar=no', 'status=no', 'width=840', 'height=1188'],
    styles = [ '/css/print-single-card.css' ]
  } = localOptions;

  if (localOptions) {
    if (localOptions.name) name = localOptions.name;
    if (localOptions.specs) specs = localOptions.specs;
    if (localOptions.styles) styles = localOptions.styles;
  }
  specs = specs.length ? specs.join(',') : '';

  const url = '';
  const win = openWindow(url, name, specs);
  const element = await createMemberCard(memberData, memberType, clubMemberships, associationMemberships, sports, isActiveMembership);
  win.document.write(`
  <html lang="de">
    <head><meta charset="utf-8"><title>${window.document.title}</title></head>
    <body>${element.outerHTML}</body>
  </html>
  `);

  addStyles(win, styles);

  setTimeout(() => {
    win.document.close();
    win.focus();
    win.print();
    setTimeout(() => {
      win.window.close();
    });
  }, 1000);

  return true;
}

function openWindow(url, name, props) {
  const windowRef = window.open(url, name, props);
  if (!windowRef.opener) {
    windowRef.opener = self;
  }
  windowRef.focus();
  return windowRef;
}


function addStyles(win, styles) {
  styles.forEach(s => {
    const link = win.document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', s);
    win.document.getElementsByTagName('head')[0].appendChild(link);
  });
}

async function createMemberCard(memberData, memberType, clubMemberships, associationMemberships, sports, isActiveMembership) {
  // create base container
  const printArea = document.createElement('div');
  printArea.classList.add('single-card-container');

  // add header
  addHeader(printArea);
  await addCardGrid(printArea, memberData, memberType, clubMemberships, associationMemberships, sports);
  addAdditionalPanels(printArea, memberData, isActiveMembership);
  addFooter(printArea);

  return printArea;
}

function addHeader(container) {
  const header = document.createElement('header');
  // left header part
  const cardHeaderLeft = document.createElement('div');
  cardHeaderLeft.classList.add('header-left');
  cardHeaderLeft.innerText = t('section.member.titleMembercard');
  header.appendChild(cardHeaderLeft);
  // right header part
  const cardHeaderRight = document.createElement('div');
  cardHeaderRight.classList.add('header-right');
  cardHeaderRight.innerHTML = `<svg width="50" height="50"><use xlink:href="${stvLogos}#stv" /></svg>${t('appTitle')}`;
  header.appendChild(cardHeaderRight);

  container.appendChild(header);
}

async function addCardGrid(container, memberData, memberType, clubMemberships, associationMemberships, sports) {
  const birthday = formatDate(memberData.birthday);
  const qrCodeData = await generateQrCode(memberData);

  const cardGrid = document.createElement('div');
  cardGrid.classList.add('card-grid');
  // membercard
  const card = document.createElement('div');
  card.classList.add('card');
  if (memberType) {
    card.classList.add(memberType);
  }
  card.innerHTML = `
<h1 class="name">${memberData.firstName} ${memberData.surname}</h1>
<div class="qr-code"><img src="data:image/png;base64,${qrCodeData}" alt="QR-Code"></div>
<p>${t('section.member.memberNr')} <strong>${memberData.memberId}</strong></p>
<p>${t('personalData.person.birthday')} <span>${birthday}</span></p>`;
  cardGrid.appendChild(card);

  // member info
  const memberInfo = document.createElement('div');
  memberInfo.classList.add('member-info');
  let infoHTML = `<p><strong>${t('personalData.person.birthday')}</strong><br>${birthday}</p>`;
  if (clubMemberships.length > 0) {
    infoHTML += `<p><strong>${t('club', clubMemberships.length)}</strong><br>${clubMemberships.join(', ')}</p>`;
  }
  if (associationMemberships.length > 0) {
    infoHTML += `<p><strong>${t('association', associationMemberships.length)}</strong><br>${associationMemberships.join(', ')}</p>`;
  }
  memberInfo.innerHTML = infoHTML;
  cardGrid.appendChild(memberInfo);

  // sports
  const sportsInfo = document.createElement('div');
  sportsInfo.classList.add('sports');
  if (sports.length > 0) {
    sportsInfo.innerHTML = `<p><strong>${t('sport.title', sports.length)}</strong></p>`;
    // sport-icons
    const sportIconsWrapper = document.createElement('div');
    sportIconsWrapper.classList.add('sport-icons');
    sports.slice(0, 8).forEach(s => {
      const icon = document.createElement('div');
      icon.classList.add('icon');
      const sportDescription = t('sport.' + s);
      icon.innerHTML = `<svg width="60" height="60"><use xlink:href="${sportLogos}#${s}"/></svg><small>${sportDescription}</small>`;
      sportIconsWrapper.appendChild(icon);
    });
    sportsInfo.appendChild(sportIconsWrapper);
  }
  cardGrid.appendChild(sportsInfo);

  container.appendChild(cardGrid);
}

function addAdditionalPanels(container, memberData, isActiveMembership) {
  const additionalPanelContainer = document.createElement('div');
  additionalPanelContainer.classList.add('additional-panel-container');

  // Sportversicherungskasse
  if (isActiveMembership) {
    const insurancePanel = document.createElement('div');
    insurancePanel.classList.add('panel', 'insurance');
    const icon = document.createElement('div');
    icon.classList.add('icon');
    icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dribbble" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8c4.408 0 8-3.584 8-8s-3.592-8-8-8zm5.284 3.688a6.802 6.802 0 0 1 1.545 4.251c-.226-.043-2.482-.503-4.755-.217-.052-.112-.096-.234-.148-.355-.139-.33-.295-.668-.451-.99 2.516-1.023 3.662-2.498 3.81-2.69zM8 1.18c1.735 0 3.323.65 4.53 1.718-.122.174-1.155 1.553-3.584 2.464-1.12-2.056-2.36-3.74-2.551-4A6.95 6.95 0 0 1 8 1.18zm-2.907.642A43.123 43.123 0 0 1 7.627 5.77c-3.193.85-6.013.833-6.317.833a6.865 6.865 0 0 1 3.783-4.78zM1.163 8.01V7.8c.295.01 3.61.053 7.02-.971.199.381.381.772.555 1.162l-.27.078c-3.522 1.137-5.396 4.243-5.553 4.504a6.817 6.817 0 0 1-1.752-4.564zM8 14.837a6.785 6.785 0 0 1-4.19-1.44c.12-.252 1.509-2.924 5.361-4.269.018-.009.026-.009.044-.017a28.246 28.246 0 0 1 1.457 5.18A6.722 6.722 0 0 1 8 14.837zm3.81-1.171c-.07-.417-.435-2.412-1.328-4.868 2.143-.338 4.017.217 4.251.295a6.774 6.774 0 0 1-2.924 4.573z"/></svg>`;
    insurancePanel.appendChild(icon);

    const insuranceContent = document.createElement('div');
    insuranceContent.classList.add('panel-body');
    const insuranceTitle = t('membership.sportsInsurance.title');
    const insuranceText = t('membership.sportsInsurance.text');
    insuranceContent.innerHTML = `<h6 class="panel-header">${insuranceTitle}</h6><div class="panel-content"><img src="${insuranceLogo}" alt="${insuranceTitle}"><p>${insuranceText}</p></div>`;
    insurancePanel.appendChild(insuranceContent);
    additionalPanelContainer.appendChild(insurancePanel);
  }

  // Ehrungen
  if (memberData.honors && memberData.honors.length > 0) {
    const honorPanel = document.createElement('div');
    honorPanel.classList.add('panel', 'honors');

    const icon = document.createElement('div');
    icon.classList.add('icon');
    icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gem" viewBox="0 0 16 16"><path d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6l3-4zm11.386 3.785-1.806-2.41-.776 2.413 2.582-.003zm-3.633.004.961-2.989H4.186l.963 2.995 5.704-.006zM5.47 5.495 8 13.366l2.532-7.876-5.062.005zm-1.371-.999-.78-2.422-1.818 2.425 2.598-.003zM1.499 5.5l5.113 6.817-2.192-6.82L1.5 5.5zm7.889 6.817 5.123-6.83-2.928.002-2.195 6.828z"/></svg>`;
    honorPanel.appendChild(icon);

    const honorBody = document.createElement('div');
    honorBody.classList.add('panel-body');
    const honorTitle = t('membership.voluntaryWorks');
    honorBody.innerHTML = `<h6 class="panel-header">${honorTitle}</h6>`;

    const honorContent = document.createElement('div');
    honorContent.classList.add('panel-content');
    memberData.honors.forEach(h => {
      const honorHtml = `<h6>${h.club ? h.club : t('section.member.honor')}</h6><p>${h.description} ${formatDate(h.dateOfHonors)}</p>`;
      honorContent.innerHTML += honorHtml;
    });
    honorBody.appendChild(honorContent);
    honorPanel.appendChild(honorBody);
    additionalPanelContainer.appendChild(honorPanel);
  }

  // Leistungssport-Ausweis
  if (memberData.competitiveSports) {
    const currentYear = new Date().getFullYear();
    if (memberData.competitiveSports.paidAt.length > 0 && memberData.competitiveSports.year >= currentYear) {
      const competitivePanel = document.createElement('div');
      competitivePanel.classList.add('panel', 'competition');

      const icon = document.createElement('div');
      icon.classList.add('icon');
      icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trophy" viewBox="0 0 16 16"><path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935zM3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z"/></svg>`;
      competitivePanel.appendChild(icon);

      const competitiveBody = document.createElement('div');
      competitiveBody.classList.add('panel-body');
      const competitiveTitle = t('membership.leistungsSportLizenz');
      competitiveBody.innerHTML = `<h6 class="panel-header">${competitiveTitle}</h6><div class="panel-content"><div class="flex"><div class="picto"><svg width="60" height="60"><use xlink:href="${sportLogos}#${getSquadByKey(memberData.competitiveSports.kindOfSport)}" /></svg></div><div><h6>${memberData.competitiveSports.club}</h6><p>${memberData.competitiveSports.kindOfSport} – ${memberData.competitiveSports.year}</p></div></div></div>`;
      competitivePanel.appendChild(competitiveBody);
      additionalPanelContainer.appendChild(competitivePanel);
    }
  }

  // Brevets
  if (memberData.educations && memberData.educations.length > 0) {
    const brevetPanel = document.createElement('div');
    brevetPanel.classList.add('panel', 'brevet');

    const icon = document.createElement('div');
    icon.classList.add('icon');
    icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16"><path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/><path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/></svg>`;
    brevetPanel.appendChild(icon);

    const brevetBody = document.createElement('div');
    brevetBody.classList.add('panel-body');
    const brevetTitle = t('membership.richterbrevet', memberData.educations.length > 0);
    brevetBody.innerHTML = `<h6 class="panel-header">${brevetTitle}</h6>`;

    const brevetContent = document.createElement('div');
    brevetContent.classList.add('panel-content');
    memberData.educations.forEach(b => {
      const brevetLevel = b.level ? `<strong>${b.level}</strong> ` : '';
      const brevetHtml = `<div class="flex"><div class="picto"><svg width="60" height="60"><use xlink:href="${sportLogos}#${getSquadByKey(b.type)}" /></svg></div><div><h6>${b.curse}</h6><p>${brevetLevel}${b.type}</p></div></div>`;
      brevetContent.innerHTML += brevetHtml;
    });
    brevetBody.appendChild(brevetContent);
    brevetPanel.appendChild(brevetBody);
    additionalPanelContainer.appendChild(brevetPanel);
  }

  // Gratiseintritt
  if (memberData.freeEntry) {
    const entrancePanel = document.createElement('div');
    entrancePanel.classList.add('panel', 'entrance');

    const icon = document.createElement('div');
    icon.classList.add('icon');
    icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-ticket" viewBox="0 0 16 16"><path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5ZM1.5 4a.5.5 0 0 0-.5.5v1.05a2.5 2.5 0 0 1 0 4.9v1.05a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1.05a2.5 2.5 0 0 1 0-4.9V4.5a.5.5 0 0 0-.5-.5h-13Z"/></svg>`;
    entrancePanel.appendChild(icon);

    const entranceContent = document.createElement('div');
    entranceContent.classList.add('panel-body');

    const entrancePanelTitle = t('membership.eintritt.panel');
    const entranceTitle = t('membership.eintritt.title');
    const entranceText = t('membership.eintritt.text');
    entranceContent.innerHTML = `<h6 class="panel-header">${entrancePanelTitle}</h6><div class="panel-content"><h6>${entranceTitle}</h6><p>${entranceText}</p></div>`;
    entrancePanel.appendChild(entranceContent);
    additionalPanelContainer.appendChild(entrancePanel);
  }

  container.appendChild(additionalPanelContainer);
}

function addFooter(container) {
  const footer = document.createElement('footer');
  footer.innerHTML = `<svg width="100%" height="50"><use xlink:href="${stvLogosGrey}#coop"/></svg>`;
  container.appendChild(footer);
}

function formatDate(value) {
  const dateVal = moment(String(value));
  if (dateVal && dateVal.isAfter('1900-01-01')) {
    return dateVal.format('DD.MM.YYYY');
  }
  return '-';
}

export default openPrintWindow;
