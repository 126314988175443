<template>
  <router-link to="profile" custom v-slot="{href, navigate}" v-if="isUser && memberData">
    <a :id="id" :href="href" :class="linkClasses + (memberData && memberData.releasedMembers && memberData.releasedMembers.length > 0 ? ' pe-3' : '')" @click="wrapNavigate(navigate, $event)">
      <UserAvatar avatar-class="portrait-small" :is-navigation="true" v-if="!isEmulatingMember"/>
      <small class="ms-2">{{ currentMemberData.firstName }} {{ currentMemberData.surname }}</small>
    </a>
  </router-link>
  <span :id="id" :class="linkClasses" v-else-if="currentMemberData">
    <UserAvatar avatar-class="portrait-small" v-if="!isEmulatingMember"/> <small>{{ currentMemberData.firstName }} {{ currentMemberData.surname }}</small>
  </span>
</template>

<script>
import {Offcanvas} from "bootstrap";
import UserAvatar from "@/components/personal-data/UserAvatar";
import { mapState } from "pinia";
import { useMemberStore } from "@/store/modules/member/member.js";

export default {
  components: {
    UserAvatar
  },
  props: {
    linkClasses: {
      type: String,
      required: false,
      default: 'nav-link'
    },
    isUser: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      required: false,
    }
  },
  methods: {
    wrapNavigate(navigate, event) {
      const offcanvas = Offcanvas.getInstance('#mainNavbar');
      if(offcanvas) {
        offcanvas.hide();
      }
      navigate(event);
    },
  },
  computed: {
    currentMemberData() {
      if (this.isEmulatingMember && this.releaseMemberData) {
        return this.releaseMemberData;
      }
      return this.memberData;
    },
    ...mapState(useMemberStore, {
      memberData: "getMemberData",
      isEmulatingMember: "isEmulatingMember",
      releaseMemberData: "getReleaseMemberData",
    }),
  },
}
</script>
