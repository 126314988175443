<template>
  <header id="header">
    <nav class="p-0">
      <div id="top-navigation-wrapper">
        <!-- #top-navigation start -->
        <div id="top-navigation">
          <a id="logo" class="header-item" href="/">
            <svg width="75" height="75" class="me-2">
              <use xlink:href="@/assets/img/stv-logos.svg#stv"/>
            </svg>
            {{ $t('appTitle') }}
          </a>
          <SponsorLogos list-id="partner" list-classes="header-item d-none d-lg-flex"/>
          <UserProfileLink id="profile-link" :is-user="isUser" link-classes="header-item d-none d-md-flex" v-if="isClientInitialized"/>

          <div class="dropdown header-item d-flex ps-0" v-if="memberData && memberData.releasedMembers && memberData.releasedMembers.length > 0">
            <a href="#"
               role="button"
               id="releaseDropdown"
               class="nav-item dropdown-toggle"
               data-bs-toggle="dropdown"
               data-bs-offset="5,10"
               aria-expanded="false"
               @click.prevent="false"
               :title="$t('menu.releasedMembers.title')">
              <i class="bi bi-people" v-if="!isEmulatingMember"></i>
              <i class="bi bi-person-badge" v-if="isEmulatingMember"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-profile rounded-0 shadow-lg mt-2"
                aria-labelledby="releaseDropdown">
              <li>
                <p class="text-muted dropdown-item-text">{{ $t('section.start.familyMembers') }}</p>
              </li>
              <li v-if="isEmulatingMember">
                <router-link :to="{name: 'start'}" custom v-slot="{href, navigate}">
                  <a :href="href" class="dropdown-item" @click="resetReleaseMemberData(); wrapNavigate(navigate, $event)">
                    <i class="bi bi-box-arrow-in-left"></i> {{ $t('menu.releasedMembers.back') }}
                  </a>
                </router-link>
              </li>
              <li v-if="isEmulatingMember">
                <hr class="dropdown-divider">
              </li>
              <li v-for="member in memberData.releasedMembers" :key="member.memberId">
                <router-link :to="{name: 'start', query: {releaseMemberId: member.memberId}}" custom v-slot="{href, navigate}">
                  <a :href="href" class="dropdown-item" :class="{'active': (isEmulatingMember && releaseMemberData.memberId === member.memberId)}" @click="wrapNavigate(navigate, $event)">
                    <i class="bi bi-person-badge"></i> {{ member.firstName }} {{ member.surname }}
                  </a>
                </router-link>
              </li>
            </ul>
          </div>

          <LanguageSwitch/>
          <a id="menu-button" class="header-item"
             aria-controls="main-navigation"
             aria-expanded="false"
             aria-label="Toggle navigation"
             :class="{active: isMenuOpen}" @click="toggleMenu"
             v-if="$router.currentRoute.value.name !== 'login'">
            <div class="hamburger"></div>
          </a>

          <!-- Main Nav start-->
          <div id="main-navigation" :class="{'visible active': isMenuOpen}" v-if="$router.currentRoute.value.name !== 'login'">
            <ul class="navbar-nav justify-content-end flex-grow-1" v-if="isClientInitialized">
              <li class="nav-item mb-4 d-md-none">
                <UserProfileLink :is-user="isUser" v-if="isLoggedIn"/>
              </li>
              <li class="nav-item" v-if="isUser">
                <router-link to="start" custom v-slot="{href, navigate}">
                  <a :href="href" class="nav-link"
                     :class="{'active': ($router.currentRoute.value.name === 'start' || $router.currentRoute.value.name === 'rootPage')}"
                     @click="wrapNavigate(navigate, $event)">
                    <i class="bi bi-house me-1"></i>
                    {{ $t('menu.overview') }}
                  </a>
                </router-link>
              </li>
              <li class="nav-item" v-if="isUser">
                <router-link to="membercard" custom v-slot="{href, navigate}" replace>
                  <a :href="href" class="nav-link"
                     :class="{'active': $router.currentRoute.value.name === 'membercard'}"
                     @click="wrapNavigate(navigate, $event)">
                    <i class="bi bi-qr-code me-1"></i>
                    {{ (isEmulatingMember && releaseMemberData) ? $t('menu.releasedMembers.memberCard', {releaseMember: releaseMemberData.firstName + ' ' + releaseMemberData.surname}) : $t('menu.memberCard') }}
                  </a>
                </router-link>
              </li>
              <li class="nav-item" v-if="isUser">
                <router-link to="profile" custom v-slot="{href, navigate}">
                  <a :href="href" class="nav-link"
                     :class="{'active': isUserProfile}"
                     @click="wrapNavigate(navigate, $event)">
                    <i class="bi bi-person-lines-fill me-1"></i>
                    {{ (isEmulatingMember && releaseMemberData) ? $t('menu.releasedMembers.profile', {releaseMember: releaseMemberData.firstName + ' ' + releaseMemberData.surname}) : $t('menu.ownProfile') }}
                  </a>
                </router-link>
              </li>

              <template v-if="isClubAdmin && !isEmulatingMember">
                <li class="nav-item" v-for="(f, index) in clubFunctions" :key="index">
                  <router-link :to="{name: 'clubMembers', query: {clubId: f.organId}}"
                               class="nav-link"
                               :class="{
                                 'active': ($router.currentRoute.value.name === 'clubMembers' || $router.currentRoute.value.name === 'singleMember') && $route.query.clubId === f.organId,
                                 'visited': !($router.currentRoute.value.name === 'clubMembers' || $router.currentRoute.value.name === 'singleMember') && currentClubId === f.organId && clubFunctions.length > 1
                               }"
                               @click="toggleMenu()">
                    <i class="bi bi-people me-1"></i>
                    {{ $t('menu.manageClub', clubFunctions.length) }}: {{ f.organ || f.organId }}
                  </router-link>
                </li>
              </template>

              <li class="nav-item mt-4">
                <router-link to="member-offers" custom v-slot="{href, navigate}">
                  <a :href="href" class="nav-link"
                     :class="{'active': $router.currentRoute.value.name === 'member-offers'}"
                     @click="wrapNavigate(navigate, $event)">
                    <i class="bi bi-patch-exclamation me-1"></i>
                    {{ $t('offers.title') }}
                  </a>
                </router-link>
              </li>

              <li class="nav-item mt-4 mb-4 mb-lg-0">
                <a href="#" class="nav-link" @click.prevent="doLogout">
                  <i class="bi bi-power me-1"></i>
                  {{ $t('login.logout') }}
                </a>
              </li>
            </ul>
            <ul class="navbar-nav justify-content-end flex-grow-1" v-if="!isLoggedIn">
              <li class="nav-item">
                <router-link to="login" custom v-slot="{href, navigate}">
                  <a :href="href" class="nav-link"
                     :class="{'active': $router.currentRoute.value.name === 'login'}"
                     @click="wrapNavigate(navigate, $event)">
                    <i class="bi bi-box-arrow-in-right me-1"></i>
                    {{ $t('login.login') }}
                  </a>
                </router-link>
              </li>
            </ul>
            <SponsorLogos list-classes="list-inline d-lg-none m-0"/>
          </div>
          <!-- Main Nav end-->
        </div>
        <!-- #top-navigation end -->
      </div>

    </nav>
  </header>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {useLoginStore} from "@/store/modules/login/login.js";
import {useMemberStore} from "@/store/modules/member/member.js";
import {useMessagesStore} from "@/store/modules/messages/messages.js";

import LanguageSwitch from "@/components/navigation/LanguageSwitch.vue";
import UserProfileLink from "@/components/navigation/UserProfileLink";
import SponsorLogos from "@/components/ui/SponsorLogos";

export default {
  data() {
    return {
      isMenuOpen: false,
    }
  },
  computed: {
    isUserProfile() {
      const profileRoutes = [ 'profile',
        'change-address',
        'change-password',
        'change-email',
        'change-birthday',
        'change-sports',
        'change-two-factor-settings',
        'add-device',
      ];
      const currentRoute = this.$router.currentRoute.value.name;
      return profileRoutes.indexOf(currentRoute) > -1;
    },
    clubFunctions() {
      if (this.isClubAdmin && this.memberData && this.memberData.functions) {
        const clubFunctions = this.memberData.functions.filter(f => {
          return f.vereinAdmin
        });
        return clubFunctions.filter((f, idx, self) => {
          return idx === self.findIndex(f2 => (f.organId === f2.organId));
        });
      }
      return [];
    },
    ...mapState(
      useLoginStore, [ "isLoggedIn", "isUser", "isClubAdmin", "isClientInitialized" ],
    ),
    ...mapState(
      useMemberStore, {
        memberData: "getMemberData",
        releaseMemberData: "getReleaseMemberData",
        isEmulatingMember: "isEmulatingMember",
        currentClubId: "getCurrentClubId",
      }
    ),
  },
  methods: {
    async doLogout() {
      await this.logout();
    },
    handleScroll() {
      let navbar = document.querySelector('#top-navigation-wrapper');
      if (window.scrollY > 20) {
        navbar.classList.add('active');
      } else {
        navbar.classList.remove('active');
      }
    },
    wrapNavigate(navigate, event) {
      if (this.isMenuOpen) {
        this.toggleMenu();
      }
      navigate(event);
    },
    switchUser(releaseMemberId) {
      if (releaseMemberId) {
        this.$router.replace({query: {releaseMemberId: releaseMemberId}});
      } else {
        this.$router.replace({query: {}});
      }
    },
    async resetReleaseMemberData() {
      await this.clearReleaseMemberData();
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      const navWrapper = document.querySelector('#top-navigation-wrapper');
      const navOverlay = document.createElement('div');
      navOverlay.setAttribute('id', 'menu-overlay');
      if (this.isMenuOpen) {
        navWrapper.appendChild(navOverlay);
        document.body.classList.add('menu-open');
        window.addEventListener('keyup', this.closeMenu);
        window.addEventListener('click', (e) => {
          if (navOverlay === e.target) {
            this.toggleMenu();
          }
        });
      } else {
        document.body.classList.remove('menu-open');
        window.removeEventListener('keyup', this.closeMenu);
        document.getElementById('menu-overlay').remove();
      }
    },
    closeMenu(event) {
      if (event.key === 'Escape') {
        this.toggleMenu();
      }
    },
    ...mapActions(
      useLoginStore, [ "logout", "resetSession" ]
    ),
    ...mapActions(
      useMemberStore, [ "clearReleaseMemberData" ]
    ),
    ...mapActions(
      useMessagesStore, [ "addWarning" ]
    ),
  },
  components: {
    LanguageSwitch,
    UserProfileLink,
    SponsorLogos
  },
  beforeCreate() {
    const loginStore = useLoginStore()
    this.unsubscribe = loginStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'resetSession') {
          if (this.isLoggedIn) {
            this.doLogout()
            return
          }
          if (this.$router.currentRoute.value.name !== 'login') {
            this.$router.go();
          }
        }
      })
    })
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    this.unsubscribe();
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

