<template>
  <membership-table :title="$t('section.education.brevets')"
                    :is-empty="sortedBrevets.length === 0"
                    :empty-message="$t('section.education.noBrevetEntries')" table-name="education">
    <template #membership-table>
      <table class="table">
        <thead>
        <tr>
          <th>{{ $t('section.education.education') }}</th>
          <th>{{ $t('section.education.validity') }}</th>
          <th>{{ $t('section.education.sport') }}</th>
          <th>{{ $t('section.education.level') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="edu in sortedBrevets" :key="edu.id">
          <td>{{ edu.curse }}</td>
          <td>{{ formatDate(edu.brevetValidUntil, 'DD.MM.YYYY') }}</td>
          <td>
            <svg width="25" height="25" v-if="edu.type">
              <use :xlink:href="require('@/assets/img/sport-icons.svg') + '#' + getIcon(edu.type)"/>
            </svg>
            {{ edu.type }}
          </td>
          <td>{{ edu.level }}</td>
        </tr>
        </tbody>
      </table>
    </template>
  </membership-table>
  <hr>
  <membership-table :title="$t('section.education.title')"
                    :is-empty="sortedEvents.length === 0"
                    :empty-message="$t('section.education.noEntries')"
                    table-name="eventlines"
                    :show-info="true"
                    :info-text="$t('section.membership.info')">
    <template #membership-table>
      <table class="table">
        <thead>
        <tr>
          <th>{{ $t('section.education.education') }}</th>
          <th>{{ $t('section.education.description') }}</th>
          <th>{{ $t('section.education.startDate') }}</th>
          <th>{{ $t('section.education.endDate') }}</th>
          <th>{{ $t('section.education.status') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(event, index) in sortedEvents" :key="index">
          <td>{{ event.description }}</td>
          <td>{{ event.secondDescription }}</td>
          <td>{{ formatDate(event.startDate) }}</td>
          <td>{{ formatDate(event.endDate) }}</td>
          <td>{{ event.status }}</td>
        </tr>
        </tbody>
      </table>
    </template>
  </membership-table>
</template>

<script>
import MembershipTable from "@/components/personal-data/MembershipTable";

export default {
  props: {
    memberData: {
      type: Object,
      required: true,
    }
  },
  components: {
    MembershipTable
  },
  computed: {
    sortedBrevets() {
      // eslint-disable-next-line
      return (this.memberData.educations && this.memberData.educations.length > 0) ? this.memberData.educations.sort(this.sortMethods('Date', 'brevetValidUntil', -1)) : [];
    },
    sortedEvents() {
      // eslint-disable-next-line
      return (this.memberData.eventLines && this.memberData.eventLines.length > 0) ? this.memberData.eventLines.sort(this.sortMethods('Date', 'endDate', -1)) : [];
    },
  },
  methods: {
    getIcon(type) {
      const iconType = type.toUpperCase();
      if (iconType.indexOf('AEROB') > -1) {
        return 'aerobic';
      } else if (iconType.indexOf('FAUST') > -1) {
        return 'faustball';
      } else if (iconType.indexOf('FITNESS') > -1) {
        return 'fitness';
      } else if (iconType.indexOf('GERAETE') > -1) {
        return 'geraeteturnen';
      } else if (iconType.indexOf('GYMNAST') > -1) {
        return 'gymnastik';
      } else if (iconType.indexOf('INDIACA') > -1) {
        return 'indiaca';
      } else if (iconType.indexOf('KINDER') > -1 || iconType.indexOf('KITU') > -1) {
        return 'kinderturnen';
      } else if (iconType.indexOf('KORB') > -1) {
        return 'korbball';
      } else if (iconType.indexOf('KUTU') > -1 || iconType.indexOf('KUNSTTURN') > -1) {
        return 'kunstturnen';
      } else if (iconType.indexOf('LEICHT') > -1) {
        return 'leichtathletik';
      } else if (iconType.indexOf('NATIONAL') > -1 || iconType.indexOf('NATI') > -1 || iconType.indexOf('NATU') > -1) {
        return 'nationalturnen';
      } else if (iconType.indexOf('NETZ') > -1) {
        return 'netzball';
      } else if (iconType.indexOf('RHOEN') > -1 || iconType.indexOf('RHÖN') > -1) {
        return 'rhoenrad';
      } else if (iconType.indexOf('RHYTH') > -1) {
        return 'rhythmischeGymnastik';
      } else if (iconType.indexOf('AKRO') > -1) {
        return 'sportakrobatik';
      } else if (iconType.indexOf('TRAMP') > -1) {
        return 'trampolin';
      } else if (iconType.indexOf('VOLLEY') > -1) {
        return 'volley';
      }
      return 'turnen';
    },
  },
}
</script>
