<template>
  <profile-layout :title="$t('user.onboarding.createAccount.title')" :is-loading="isSubmitted">
    <template #menu>
      <ul class="nav flex-column profile-menu mb-5">
        <li class="nav-item">
          <router-link to="login" custom v-slot="{href}">
            <a :href="href" class="nav-link">
              {{ $t('user.forgotPassword.backToLogin') }}
            </a>
          </router-link>
        </li>
      </ul>
    </template>

    <template #content>
      <div class="row">
        <div class="col" v-if="!showSuccess">
          <p class="mb-4">{{ $t('user.onboarding.createAccount.text') }}</p>
          <div class="mb-4">
            <div class="form-floating has-validation">
              <input type="text" id="memberNumber" class="form-control"
                     :class="{'is-valid': memberNumberValid,  'is-invalid': (memberNumberOrEmail && !memberNumberValid)}"
                     :placeholder="$t('profile.memberNumber')"
                     required
                     v-model="memberNumberOrEmail"
                     :disabled="isSubmitted"
                     @keyup.enter="submitMemberNumber">
              <label for="memberNumber" class="form-label">{{ $t('profile.memberNumber') }}</label>
              <div class="invalid-feedback" v-if="!memberNumberValid">{{ $t('profile.validation.invalidMemberNumber') }}</div>
            </div>
          </div>
          <DefaultButton
            :label="$t('user.onboarding.send')"
            :disabled="isSubmitted"
            @click="submitMemberNumber"
            class="btn-dark"
          />
        </div>
        <div class="col" v-else>
          <div class="alert alert-success d-flex align-items-start" role="alert" style="white-space:pre-line;">
            <i class="bi bi-envelope me-2" style="font-size: 1.5rem; line-height: 1;"></i>
            <Translation keypath="user.onboarding.createAccount.success" tag="p"/>
          </div>
        </div>
        <div class="col-12">
          <FaqPanel class="mt-3"/>
        </div>
      </div>
      <div id="toast-wrapper" class="position-fixed bottom-0 end-0 p-3" style="z-index: 1000;"></div>
    </template>
  </profile-layout>
</template>

<script>
import {mapActions} from "pinia";
import {Toast} from "bootstrap";
import {useMemberStore} from "@/store/modules/member/member";
import ProfileLayout from "@/components/profile/ProfileLayout.vue";
import {Translation} from "vue-i18n";
import FaqPanel from "@/components/ui/FaqPanel.vue";

export default {
  data() {
    return {
      memberNumberOrEmail: null,
      showSuccess: false,
      isSubmitted: false,
    };
  },
  components: {
    FaqPanel,
    ProfileLayout,
    Translation
  },
  computed: {
    memberNumberValid() {
      if (this.isNumeric(this.memberNumberOrEmail)) {
        return this.memberNumberOrEmail.toString().length >= 4 && this.memberNumberOrEmail.toString().length <= 7;
      }
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
      return emailRegex.test(this.memberNumberOrEmail);
    },
  },
  methods: {
    async submitMemberNumber() {
      if (this.memberNumberValid) {
        this.isSubmitted = true;

        if (this.isNumeric(this.memberNumberOrEmail) && this.memberNumberOrEmail.length < 6) {
          this.memberNumberOrEmail = ('000000' + this.memberNumberOrEmail).slice(-6);
        }
        const accountData = {
          'stvMemberId': this.memberNumberOrEmail,
          'languageId': this.$i18n.locale,
        };
        this.sendOnboardingRequest(accountData).then(() => {
          const toastWrapper = document.getElementById('toast-wrapper');
          const toastMessage = document.createElement('div');
          toastMessage.classList.add('toast', 'align-items-start', 'text-white', 'bg-success');
          toastMessage.setAttribute('role', 'alert');
          toastMessage.setAttribute('aria-live', 'assertive');
          toastMessage.setAttribute('aria-atomic', 'true');
          const msg = this.$t('user.forgotPassword.success', {memberNumber: this.memberNumberOrEmail});
          toastMessage.innerHTML = `<div class="d-flex"><div class="toast-body">${msg}</div><button class="btn-close btn-close-white me-3 mt-3" data-bs-dismiss="toast" aria-label="Close"></button></div>`;
          toastWrapper.appendChild(toastMessage);
          const toast = new Toast(toastMessage);
          toast.show();
          this.showSuccess = true;
          this.isSubmitted = false;
        });
      } else {
        this.showFailure = true;
      }
    },
    isNumeric(str) {
      return !isNaN(str) && !isNaN(parseInt(str, 10));
    },
    ...mapActions(useMemberStore, ['sendOnboardingRequest'])
  }
}
</script>
