<template>
  <ul class="nav flex-column profile-menu mb-5">
    <li class="nav-item">
      <router-link to="profile" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === 'profile'}">
          {{ $t('breadcrumb.showProfile') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item">
      <router-link to="change-address" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === 'change-address'}">
          {{ $t('breadcrumb.changeProfile') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" v-if="isUser && !isEmulatingMember">
      <router-link to="change-sports" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === 'change-sports'}">
          {{ $t('breadcrumb.changeSports') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" v-if="isUser && !isEmulatingMember">
      <router-link to="change-password" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === 'change-password'}">
          {{ $t('breadcrumb.changePassword') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" v-if="isUser">
      <router-link to="change-email" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: isEmailActive}">
          {{ $t('breadcrumb.changeEmail') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" v-if="isUser">
      <router-link to="change-birthday" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === 'change-birthday'}">
          {{ $t('breadcrumb.changeBirthday') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" v-if="isUser">
      <router-link to="change-two-factor-settings" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: isSecurityActive}">
          {{ $t('breadcrumb.change2FA') }}
        </a>
      </router-link>
    </li>
  </ul>

</template>

<script>
import {mapState} from "pinia";
import {useLoginStore} from "@/store/modules/login/login.js";
import {useMemberStore} from "@/store/modules/member/member.js";

export default {
  computed: {
    isSecurityActive() {
      const securityRoutes = [
        'change-two-factor-settings',
        'add-device',
      ];
      const currentRoute = this.$router.currentRoute.value.name;
      return securityRoutes.indexOf(currentRoute) > -1;
    },
    isEmailActive() {
      const emailRoutes = [
        'change-email',
        'confirm-email',
      ];
      const currentRoute = this.$router.currentRoute.value.name;
      return emailRoutes.indexOf(currentRoute) > -1;
    },
    ...mapState(
      useLoginStore, ["isLoggedIn", "isUser"],
    ),
    ...mapState(
      useMemberStore, {memberData: "getMemberData", isEmulatingMember: "isEmulatingMember"}
    )
  },
};
</script>

