<template>
    <div class="termsAndConditions" v-if="$i18n.locale === 'de'">
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;text-align:justify;line-height:18.0pt;font-size:16px;font-family:"Arial",sans-serif;font-weight:bold;'>Nutzungsbedingungen f&uuml;r mySTV-FSG-Administratoren&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Diese Nutzungsbedingungen (die &quot;<strong>Nutzungsbedingungen</strong>&quot;) gelten in Erg&auml;nzung zu den allgemeinen Nutzungsbedingungen f&uuml;r mySTV-FSG f&uuml;r jede Nutzung der Mitgliederplattform mySTV-FSG des Schweizerischer Turnvereins (&quot;<strong>STV</strong>&quot;) als Administrator, inklusive der Nutzung aller Daten, Informationen, Werkzeuge und Dienstleistungen, die auf der Mitgliederplattform (nachfolgend die &quot;<strong>Plattform</strong>&quot;) f&uuml;r Administratoren verf&uuml;gbar sind.&nbsp;Gegenstand dieser Nutzungsbedingungen ist die Aus&uuml;bung von Administratoren-Rechten im Zusammenhang mit der Mitgliederplattform mySTV-FSG durch ausgew&auml;hlte Funktion&auml;re des jeweiligen (lokalen) Turnvereines oder (regionalen, kantonalen oder nationalen) Turnverbands (nachfolgend &bdquo;<strong>Administrator</strong>&ldquo;).</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'><strong>Sie d&uuml;rfen die Ihnen zugestandenen Administratoren-Rechte nur unter der Bedingung aus&uuml;ben, dass Sie die Nutzungsbedingungen verstehen und akzeptieren. Jede Aus&uuml;bung von Administratoren-Rechten bedeutet, dass Sie die Nutzungsbedingungen gelesen und verstanden haben und sich mit allen Teilen der Nutzungsbedingungen einverstanden erkl&auml;ren.</strong></p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">1. Ihre Rolle als Administrator</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:left;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Nachfolgend wird der Personenkreis mit jeweiligen Administratoren-Rechten per Funktion im jeweiligen Turnverein/-verband definiert:</p>
        <p style="margin-left: 20px;"><span style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">Lokale Vereine: Personen, bei welchen im STV-Admin die folgenden Funktionen hinterlegt sind:</span></p>
        <ul style="list-style-type: disc;">
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">001 Pr&auml;sident</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">002 Vize-Pr&auml;sident</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">003 Techn. Leiter</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">004 Leitung Jugend</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">005 Leitung Aktive</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">006 Leitung Frauen/M&auml;nner</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">007 Leitung Senioren/-innen</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">017 Leitung Ger&auml;teturnen</li>
            <li style="font-size: 13px; font-family: Arial, Helvetica, sans-serif;">028 Leitung M&auml;nner</li>
            <li style="font-size: 13px; font-family: Arial, Helvetica, sans-serif;">030 Leitung Frauen</li>
            <li style="font-size: 13px; font-family: Arial, Helvetica, sans-serif;">031 Leitung Senioren</li>
            <li style="font-size: 13px; font-family: Arial, Helvetica, sans-serif;">032 Leitung Seniorinnen</li>
            <li style="font-size: 13px; font-family: Arial, Helvetica, sans-serif;">043 Kassier/-in</li>
            <li style="font-size: 13px; font-family: Arial, Helvetica, sans-serif;">046 Aktuar</li>
            <li style="font-size: 13px; font-family: Arial, Helvetica, sans-serif;">049 Administration</li>
            <li style="font-size: 13px; font-family: Arial, Helvetica, sans-serif;">060-STV-Admin</li>
            <li style="font-size: 13px; font-family: Arial, Helvetica, sans-serif;">099 SPISPO LSPA Kontaktperson</li>
        </ul>
        <p><br></p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <p style="margin-left: 20px;">STV: Personen, bei welchen im STV-Admin die Funktion &quot;Admin MySTV-FSG&quot; hinterlegt ist.</p>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Die Administratoren-Rechte sind an die Bekleidung der vorgenannten Funktionen innerhalb der Turnvereine/-verb&auml;nde gebunden. Sobald ein Administrator die entsprechende Funktion nicht mehr aus&uuml;bt, erl&ouml;schen damit s&auml;mtliche Administratoren-Rechte. Jede weitere Nutzung der Plattform als Administrator ist untersagt.&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Die Administratoren-Rechte d&uuml;rfen nur so weit zur Aus&uuml;bung der jeweiligen Funktion erforderlich, ausge&uuml;bt werden. Namentlich sind Administratoren berechtigt, Daten zur Person, zu dessen Mitgliedschaften, und dessen Teilnahmen an Kursen, Wettk&auml;mpfen und Anl&auml;ssen sowie dessen Mitgliederkarte einzusehen. Ebenso sind sie berechtigt, diese auszudrucken, zu speichern und zu exportieren. Diese Berechtigungen sind f&uuml;r vereins- bzw. verbandsinternen Zwecke bestimmt, wie zum Beispiel die organisatorische und administrative Optimierung des Vereins- bzw Verbands-, Trainings- oder Wettkampfbetriebes.&nbsp;Abgesehen davon ist die Nutzung der Plattform als Administrator f&uuml;r andere Zwecken sowie eine Weitergabe von Daten an Dritte ist untersagt.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">2. Ihre Pflichten als Administrator</span></h1>
        </div>
        <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Sie verpflichten sich zur strikten Wahrung der Vertraulichkeit und Gew&auml;hrleistung der Integrit&auml;t der Daten auf den Zielsystemen sowie der Einhaltung des Datenschutzes und der einschl&auml;gigen Gesetze (Datenschutzgesetz, Fernmeldegesetz etc.), da durch die erweiterten Rechte innerhalb der Plattform auch der Zugriff auf Daten anderer Benutzer mit Zugang zur Plattform m&ouml;glich ist.</p>
        <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Das Administratorkonto ist personenbezogen und nicht &uuml;bertragbar.&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Die Weitergabe des Passwortes ist untersagt.&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Das &Ouml;ffnen von Dokumenten aller Art, die nicht Ihnen geh&ouml;ren, ist untersagt bzw. nur dann zul&auml;ssig, wenn die schriftliche Zustimmung des Eigent&uuml;mers vorliegt.&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Die erweiterten Rechte des Administratorkontos d&uuml;rfen nicht dazu benutzt werden, sich Zugriffsrechte auf andere Systeme und Bereiche zu verschaffen als auf diejenigen, die Ihnen zugewiesen sind. Die Umgehung oder Abschaltung sicherheitsrelevanter Dienste (z.B. Firewall, Virenscanner, Malwarescanner) ist nicht gestattet. Die L&ouml;schung von Systemprotokollen oder die &Auml;nderung der Systemprotokollierung ist ebenfalls untersagt.&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Administratorkonten beinhalten besondere Rechte auf den Zielsystemen. Bei nicht sachgerechter Nutzung dieser Rechte kann sehr grosser Schaden an den Zielsystemen entstehen. Sie verpflichten sich, Ihr Administratorkonto mit der gebotenen Sorgfalt zu nutzen.&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Sie sind verpflichtet, den Anweisungen des STV Folge zu leisten.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">3. Freistellungsanspruch</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Der Administrator stellt STV f&uuml;r den Fall der Inanspruchnahme wegen vermeintlicher oder tats&auml;chlicher Rechtsverletzungen und/oder Verletzungen von Rechten Dritter durch vom Administrator im Zusammenhang mit der Aus&uuml;bung von den zugestandenen Administratoren-Rechten &uuml;ber die Plattform vorgenommenen Handlung und/oder vom Administrator eingegebenen Inhalte von s&auml;mtlichen sich daraus ergebenden Anspr&uuml;chen Dritter frei.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Der Administrator verpflichtet sich, alle etwaigen Kosten, die dem STV durch die Inanspruchnahme Dritter entstehen, zu ersetzen. Zu den erstattungsf&auml;higen Kosten z&auml;hlen auch die Kosten einer angemessenen Rechtsverfolgung und Rechtsverteidigung, die der STV zur Abwehr von Anspr&uuml;chen Dritter entstehen sollten. STV informiert in diesem Fall den betroffenen Nutzer unverz&uuml;glich &uuml;ber vorzunehmende Massnahmen der Rechtsverteidigung.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">4. Verstoss gegen Nutzungsbedingungen</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:35.4pt;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Beim Verstoss eines Administrators gegen die vorliegenden Nutzungsbedingungen kann der STV die folgenden Sanktionen aussprechen:</p>
        <ul style="list-style-type: disc;margin-left:25.700000000000003px;">
            <li style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px;">Ermahnung zur Rechts- und vertragskonformen Aus&uuml;bung von Administratoren-Rechten,</span></li>
            <li style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px;">Entzug des Administratorkontos&nbsp;</span></li>
            <li style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px;">gegebenenfalls Schadenersatz sowie die Schadloshaltung von Anspr&uuml;chen Dritter verlangen.</span></li>
        </ul>
        <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">5. &Auml;nderungen</span></h1>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Wir behalten uns vor, jederzeit und ohne vorherige Ank&uuml;ndigung, Verbesserungen oder &Auml;nderungen an diesen Nutzungsbedingungen vorzunehmen. Diese Nutzungsbedingungen wurden zuletzt am 1.&nbsp;M&auml;rz 2023 ge&auml;ndert.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">6. Schlussbestimmungen</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Sollte eine Bestimmung dieser Nutzungsbedingungen f&uuml;r ung&uuml;ltig oder nicht durchsetzbar erkl&auml;rt werden, bleiben die &uuml;brigen Bestimmungen weiterhin in vollem Umfang g&uuml;ltig und wirksam.&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Unser Vers&auml;umnis, die strikte Einhaltung einer dieser Nutzungsbedingungen durchzusetzen, ist nicht als Verzicht auf bestimmte Rechte und/ oder Pflichten zu werten.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Im &Uuml;brigen gelten die Nutzungsbedingungen mySTV-FSG.&nbsp;</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">7. Anwendbares Recht und Gerichtsstand</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Diese Nutzungsbedingungen unterstehen dem materiellen Recht der Schweiz, sofern nicht das Recht eines anderen Landes zwingend Anwendung findet.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Gerichtsstand f&uuml;r alle Streitigkeiten aus und in Zusammenhang mit der Aus&uuml;bung von Administratoren-Rechten ist am Sitz des STV, sofern nicht ein anderer Gerichtsstand zwingend vorgeschrieben ist.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">8. Kontaktieren Sie uns</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Sollten Sie Kommentare oder Fragen zu zur Aus&uuml;bung Ihrer Administratoren-Rechte haben, kontaktieren Sie uns bitte per E-Mail unter <a href="mailto:mystv@stv-fsg.ch">mystv@stv-fsg.ch</a><span style="border:none windowtext 1.0pt;padding:0cm;">.</span></p>
    </div>
    <div class="termsAndConditions" v-else>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;text-align:justify;line-height:18.0pt;font-size:16px;font-family:"Arial",sans-serif;font-weight:bold;'>Conditions d&rsquo;utilisation pour les administrateurs mySTV-FSG&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Les pr&eacute;sentes conditions d&rsquo;utilisation (les &laquo; <strong>conditions d&rsquo;utilisation</strong> &raquo;) s&rsquo;appliquent en compl&eacute;ment des conditions g&eacute;n&eacute;rales d&apos;utilisation de mySTV-FSG, pour toute utilisation de la plateforme des membres mySTV-FSG de la F&eacute;d&eacute;ration suisse de gymnastique (&laquo; <strong>FSG</strong> &raquo;) en tant qu&apos;administrateur, y compris l&apos;utilisation de toutes les donn&eacute;es, informations, outils et services disponibles sur la plate-forme des membres (ci-apr&egrave;s la &laquo; <strong>plateforme</strong> &raquo;) disponibles pour les administrateurs. Les pr&eacute;sentes conditions d&apos;utilisation ont pour objet l&apos;exercice des droits d&apos;administrateur dans le cadre de la plate-forme des membres mySTV-FSG par des fonctionnaires s&eacute;lectionn&eacute;s de la soci&eacute;t&eacute; de gymnastique (locale) ou de la f&eacute;d&eacute;ration de gymnastique (r&eacute;gionale, cantonale ou nationale) concern&eacute;e (ci-apr&egrave;s &laquo; <strong>administrateur</strong> &raquo;).</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'><strong>Vous ne pouvez utiliser les droits d&rsquo;administrateur accord&eacute;s qu&rsquo;&agrave; condition de comprendre et d&rsquo;accepter les conditions d&rsquo;utilisation. Tout exercice des droits d&rsquo;administrateur signifie que vous avez lu et compris les conditions d&rsquo;utilisation et que vous acceptez toutes les parties des conditions d&apos;utilisation.</strong></p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">1. Votre r&ocirc;le d&rsquo;administrateur&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:left;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Le cercle des personnes disposant des droits d&rsquo;administrateur respectifs est d&eacute;fini ci-apr&egrave;s par fonction dans la soci&eacute;t&eacute;/l&rsquo;association de gymnastique concern&eacute;e :</p>
        <p style="margin-left: 20px;"><span style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">Soci&eacute;t&eacute;s locales : Les personnes pour lesquelles les fonctions suivantes sont enregistr&eacute;es dans Admin FSG :</span></p>
        <ul style="list-style-type: disc;">
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">001 Pr&eacute;sident</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">002 Vice-pr&eacute;sident</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">003 Directeur technique</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">004 Direction Jeunesse</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">005 Direction Actifs&nbsp;</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">006 Direction Femmes/Hommes</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">007 Direction Seniors/es&nbsp;</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">017 Direction Gymnastique aux agr&egrave;s&nbsp;</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">028 Direction Hommes</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">030 Direction Femmes</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">031 Direction Seniors&nbsp;</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">032 Direction Seniores</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">043 Caissier/&egrave;re&nbsp;</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">046 Secr&eacute;taire&nbsp;</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">049 Administration</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">060-Admin-FSG</li>
            <li style="font-family: Arial, Helvetica, sans-serif; font-size: 13px;">099 Personne de contact l&rsquo;attestation du sport d&rsquo;&eacute;lite</li>
        </ul>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:72.0pt;text-align:left;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>&nbsp;</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <p style="margin-left: 20px;">FSG : Les personnes pour lesquelles la fonction &laquo; Admin MySTV-FSG &raquo; est enregistr&eacute;e dans l&apos;Admin FSG.</p>
            <p style="margin-left: 20px;">Les droits d&apos;administrateur sont li&eacute;s &agrave; l&apos;occupation des fonctions susmentionn&eacute;es au sein des soci&eacute;t&eacute;s/associations de gymnastique. D&egrave;s qu&rsquo;un administrateur n&rsquo;exerce plus la fonction correspondante, tous les droits d&rsquo;administrateur sont supprim&eacute;s. Toute autre utilisation de la plateforme en tant qu&apos;administrateur est interdite.&nbsp;</p>
            <p style="margin-left: 20px;">Les droits d&rsquo;administrateur ne peuvent &ecirc;tre exerc&eacute;s que dans la mesure o&ugrave; ils sont n&eacute;cessaires &agrave; l&apos;exercice de la fonction concern&eacute;e. Les administrateurs sont notamment autoris&eacute;s &agrave; consulter les donn&eacute;es relatives aux personnes, leurs affiliations et leurs participations aux cours, aux concours et aux &eacute;v&eacute;nements, ainsi que leur carte de membre. Ils ont &eacute;galement le droit de les imprimer, de les enregistrer et de les exporter. Ces droits sont destin&eacute;s &agrave; des fins internes &agrave; la soci&eacute;t&eacute; ou &agrave; l&rsquo;association, comme l&rsquo;optimisation obligatoire et administrative du fonctionnement de la soci&eacute;t&eacute; ou de l&rsquo;association, des entra&icirc;nements ou des concours. En outre, il est interdit d&apos;utiliser la plateforme en tant qu&apos;administrateur &agrave; d&apos;autres fins ou de transmettre des donn&eacute;es &agrave; des tiers.</p>
        </div>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">2. Vos obligations en tant qu&rsquo;administrateur&nbsp;</span></h1>
        </div>
        <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Vous vous engagez &agrave; respecter strictement la confidentialit&eacute; et &agrave; garantir l&apos;int&eacute;grit&eacute; des donn&eacute;es sur les syst&egrave;mes cibles, ainsi qu&apos;&agrave; respecter la protection des donn&eacute;es et les lois applicables (loi sur la protection des donn&eacute;es, loi sur les t&eacute;l&eacute;communications, etc.), car les droits &eacute;tendus au sein de la plateforme permettent &eacute;galement d&apos;acc&eacute;der aux donn&eacute;es d&apos;autres utilisateurs ayant acc&egrave;s &agrave; la plate-forme.</p>
        <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Le compte d&rsquo;administrateur est personnel et ne peut pas &ecirc;tre transf&eacute;r&eacute;.&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Il est interdit de divulguer le mot de passe.&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Il est interdit d&rsquo;ouvrir des documents de tous genres qui ne vous appartiennent pas ou alors uniquement avec accord &eacute;crit du propri&eacute;taire.&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Les droits &eacute;tendus du compte administrateur ne doivent pas &ecirc;tre utilis&eacute;s pour obtenir des droits d&apos;acc&egrave;s &agrave; d&apos;autres syst&egrave;mes et domaines que ceux qui vous sont attribu&eacute;s. Il est interdit de contourner ou de d&eacute;sactiver les services li&eacute;s &agrave; la s&eacute;curit&eacute; (p. ex. pare-feu, antivirus, malwarescanner). Il est &eacute;galement interdit de supprimer les journaux du syst&egrave;me ou de modifier le syst&egrave;me d&rsquo;enregistrement.&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Les comptes d&rsquo;administrateur contiennent des droits sp&eacute;ciaux sur les syst&egrave;mes cibles. Une utilisation inappropri&eacute;e de ces droits peut entra&icirc;ner des dommages tr&egrave;s importants sur les syst&egrave;mes cibles. Vous vous engagez &agrave; utiliser votre compte d&apos;administrateur avec toute la minutie requise.&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Vous &ecirc;tes tenus de suivre les instructions de la FSG.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">3. Droit d&rsquo;exemption&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>L&rsquo;administrateur lib&egrave;re la FSG de toutes les pr&eacute;tentions de tiers r&eacute;sultant de pr&eacute;tendues ou r&eacute;elles violations de droits et/ou de violations de droits de tiers par des actions entreprises par l&apos;administrateur dans le cadre de l&apos;exercice des droits d&apos;administrateur accord&eacute;s via la plateforme et/ou de contenus saisis par l&apos;administrateur.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>L&apos;administrateur s&apos;engage &agrave; rembourser tous les frais &eacute;ventuels occasionn&eacute;s &agrave; la FSG par le recours &agrave; des tiers. Les frais remboursables comprennent &eacute;galement les frais de poursuite et de d&eacute;fense juridiques raisonnables que la FSG devrait engager pour se d&eacute;fendre contre les pr&eacute;tentions de tiers. Dans ce cas, la FSG informe imm&eacute;diatement l&rsquo;utilisateur concern&eacute; des mesures de d&eacute;fense juridique &agrave; prendre.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">4. Violation des conditions d&rsquo;utilisation&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:35.4pt;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>En cas de violation des pr&eacute;sentes conditions d&apos;utilisation par un administrateur, la FSG peut prononcer les sanctions suivantes :</p>
        <ul style="list-style-type: disc;margin-left:25.700000000000003px;">
            <li style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px;">Rappel &agrave; l&rsquo;ordre pour exercer les droits d&apos;administrateur dans le respect de la loi et des contrats,</span></li>
            <li style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px;">Retrait du compte d&rsquo;administrateur&nbsp;</span></li>
            <li style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px;">Exiger &agrave; la rigueur des dommages-int&eacute;r&ecirc;ts ainsi que l&rsquo;indemnisation des pr&eacute;tentions de tiers.</span></li>
        </ul>
        <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">5. Modifications&nbsp;</span></h1>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Nous nous r&eacute;servons le droit d&rsquo;am&eacute;liorer ou de modifier en tout temps et sans pr&eacute;avis les pr&eacute;sentes conditions d&rsquo;utilisation. Les pr&eacute;sentes conditions d&rsquo;utilisation ont &eacute;t&eacute; modifi&eacute;es pour derni&egrave;re fois le 1<sup>er</sup> mars 2023.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">6. Dispositions finales</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Si l&apos;une des dispositions des pr&eacute;sentes conditions d&apos;utilisation est d&eacute;clar&eacute;e invalide ou inapplicable, les autres dispositions demeurent pleinement valables et efficaces.&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>L&rsquo;omission de faire respecter strictement l&apos;une de ces conditions d&apos;utilisation ne doit pas &ecirc;tre consid&eacute;r&eacute;e comme une renonciation &agrave; certains droits et/ou obligations.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Les conditions d&rsquo;utilisation mySTV-FSG s&rsquo;appliquent par ailleurs.&nbsp;</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">7. Droit applicable et tribunal comp&eacute;tent&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Les pr&eacute;sentes conditions d&rsquo;utilisation sont soumises au droit mat&eacute;riel de la Suisse, dans la mesure o&ugrave; le droit d&rsquo;un autre pays ne s&rsquo;applique pas de mani&egrave;re contraignante.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Le tribunal comp&eacute;tent pour tous les litiges d&eacute;coulant de l&apos;exercice des droits d&apos;administrateur ou en rapport avec celui-ci est le si&egrave;ge de la FSG, &agrave; moins qu&apos;un autre tribunal ne soit obligatoire.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">8. Contactez-nous&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Si vous avez des commentaires ou des questions sur l&rsquo;exercice de vos droits d&rsquo;administrateur, veuillez nous contacter par courrier &eacute;lectronique &agrave; l&rsquo;adresse <a href="mailto:mystv@stv-fsg.ch">mystv@stv-fsg.ch</a><span style="border:none windowtext 1.0pt;padding:0cm;">.</span></p>
    </div>
</template>

<style scoped>


.termsAndConditions {
    border: 1px solid #000;
    padding: 1em;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 20em
}

</style>