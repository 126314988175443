<template>
  <profile-layout :title="$t('user.forgotPassword.forgot')" :is-loading="isSubmitted">
    <template #menu>
      <ul class="nav flex-column profile-menu mb-5">
        <li class="nav-item">
          <router-link to="login" custom v-slot="{href}">
            <a class="nav-link" :href="href">
              {{ $t('user.forgotPassword.backToLogin') }}
            </a>
          </router-link>
        </li>
      </ul>
    </template>
    <template #content>

      <div class="row">
        <div class="col" v-if="!showSuccess">
          <p class="mb-4">
            {{ $t('user.forgotPassword.forgot_text') }}
          </p>
          <div class="mb-4">
            <div class="form-floating has-validation">
              <input type="text" class="form-control"
                     :class="{'is-valid': memberNumberValid, 'is-invalid': !memberNumberValid || showFailure}"
                     required
                     id="memberNumber"
                     :placeholder="$t('profile.memberNumber')"
                     v-model="memberNumberOrEmail"
                     :disabled="isSubmitted"
                     @keyup="analyzeMemberNumber"
                     @change="analyzeMemberNumber"
                     @keyup.enter="submitMemberNumber">
              <label for="memberNumber" class="form-label font-weight-bold">{{ $t('profile.memberNumber') }}</label>
              <div class="invalid-feedback" v-if="!memberNumberValid">{{ $t('profile.validation.invalidMemberNumber') }}</div>
            </div>
          </div>
          <DefaultButton
            :label="$t('user.forgotPassword.send')"
            :disabled="isSubmitted"
            @click="submitMemberNumber"
            class="btn-dark"
          />

          <FaqPanel class="mt-3"/>
        </div>

        <div class="col" v-else>
          <div class="alert alert-success d-flex align-items-start" role="alert">
            <i class="bi bi-envelope me-3" style="font-size: 1.5rem;"></i>
            <span v-html="$t('user.forgotPassword.success', {memberNumber: memberNumberOrEmail})"></span>
          </div>
        </div>

      </div>


      <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 1000;">
        <div id="toastMessage" class="toast align-items-start bg-danger text-white" role="alert" aria-live="assertive" aria-atomic="true" v-if="showFailure">
          <div class="d-flex">
            <div class="toast-body">{{ $t('user.forgotPassword.failure') }}</div>
            <button class="btn-close btn-close-white me-3 mt-3" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
        </div>
      </div>
    </template>
  </profile-layout>
</template>

<script>
import {mapActions} from "pinia"
import ProfileLayout from "@/components/profile/ProfileLayout.vue";
import {Toast} from "bootstrap";
import {useUserStore} from "@/store/modules/user/user.js";
import FaqPanel from "@/components/ui/FaqPanel.vue";

export default {
  data() {
    return {
      memberNumberOrEmail: null,
      memberNumberValid: false,
      isSubmitted: false,
      showSuccess: false,
      showFailure: false
    }
  },
  methods: {
    async submitMemberNumber() {
      this.analyzeMemberNumber();
      if (this.memberNumberValid) {
        this.isSubmitted = true;

        if (this.isInt(this.memberNumberOrEmail) && this.memberNumberOrEmail < 6) {
          this.memberNumberOrEmail = ('000000' + this.memberNumberOrEmail).slice(-6);
        }
        const resetData = {
          'stvMemberId': this.memberNumberOrEmail,
          'languageId': this.$i18n.locale,
        };
        this.sendPasswordResetRequest(resetData).then(response => {
          if (response && response.status === 200) {
            this.showSuccess = true;
          } else {
            this.showFailure = true;
          }
        }).catch(err => {
          this.showFailure = true;
          console.log(err);
        }).finally(() => {
          const toastMessage = document.getElementById('toastMessage');
          if (toastMessage !== null) {
            const toast = new Toast(toastMessage);
            toast.show();
          }
          this.isSubmitted = false;
        });

      } else {
        this.showFailure = true;
      }
    },
    analyzeMemberNumber() {
      if (this.isInt(this.memberNumberOrEmail)) {
        this.memberNumberValid = (this.memberNumberOrEmail.toString().length >= 4 && this.memberNumberOrEmail.toString().length <= 7);
      } else {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
        this.memberNumberValid = emailRegex.test(this.memberNumberOrEmail);
      }
      this.showSuccess = false;
      this.showFailure = false;
    },
    isInt(str) {
      return !isNaN(str) && !isNaN(parseInt(str, 10));
    },
    ...mapActions(useUserStore, ["sendPasswordResetRequest"])
  },
  components: {
    FaqPanel,
    ProfileLayout
  },
}
</script>
