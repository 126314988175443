<template>
  <footer class="meta-footer">
    <div class="mystv-container">
      <div class="row">
        <div class="col">
          <ol class="breadcrumb py-2" style="--bs-breadcrumb-divider: ' ';">
            <li class="breadcrumb-item"><small>© {{ new Date().getFullYear() }} STV FSG</small></li>
            <li class="breadcrumb-item"><small><a :href="$t('footer.dataProtection.link')" target="_blank" class="text-decoration-none">{{ $t('footer.dataProtection.label') }}</a></small></li>
            <li class="breadcrumb-item"><small><a :href="$t('footer.impressum.link')" target="_blank" class="text-decoration-none">{{ $t('footer.impressum.label') }}</a></small></li>
            <li class="breadcrumb-item" v-if="false"><small><a :href="$t('footer.termsConditions.link')" target="_blank" class="text-decoration-none">{{ $t('footer.termsConditions.label') }}</a></small></li>
          </ol>
        </div>
      </div>
    </div>
  </footer>
</template>
