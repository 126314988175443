<template>
  <div class="modal fade" id="imageCropperModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('profile.picture') }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="profileImage && !avatarUploaded">
          <UserAvatar avatar-class="profile img-fluid mb-3" avatar-id="avatarImg">
            <div class="img-profile-overlay" @click="$refs.profileImgUpload.click()">
              <p>
                <span class="display-3"><i class="bi bi-camera"></i></span><br>
                {{ $t('profile.uploadPicture') }}
              </p>
            </div>
          </UserAvatar>
        </div>
        <ImageCropper v-if="avatarUploaded" :image="avatarImage" :is-uploading="isSubmitted" cropper-width="300px" cropper-height="300px" @crop="saveAvatar" @cancel="cancelCropping"/>
        <div class="modal-footer" v-if="!avatarUploaded">
          <label for="profileImg" class="btn btn-outline-secondary">
            <i class="bi bi-upload me-2"></i>
            {{ $t('profile.uploadNewPicture') }}
            <input type="file" accept=".jpg,.jpeg,.png,.bmp,.gif" name="profileImg" id="profileImg" hidden @change="onFileSelected" ref="profileImgUpload">
          </label>
          <DefaultButton class="btn-outline-danger" :label="$t('profile.deletePicture')" icon="bi bi-trash3" data-bs-toggle="modal" data-bs-target="#deleteModal" v-if="profileImage"/>
          <DefaultButton class="btn-outline-dark" :label="$t('cancel')" data-bs-dismiss="modal"/>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" tabindex="-1" id="deleteModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('profile.deletePicture') }}</h5>
        </div>
        <div class="modal-body">
          <p>{{ $t('profile.delete.alert') }}</p>
        </div>
        <div class="modal-footer">
          <DefaultButton class="btn-outline-danger" :label="$t('profile.security.device.delete.confirm')" @click="deleteAvatar" @keyup.enter="deleteAvatar" data-bs-dismiss="modal"/>
          <DefaultButton class="btn-outline-dark" :label="$t('cancel')" @click="cancelCropping" data-bs-dismiss="modal"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/personal-data/UserAvatar.vue";
import ImageCropper from "@/components/ui/ImageCropper.vue";
import {mapActions, mapState} from "pinia";
import {useProfileStore} from "@/store/modules/profile/profile";

import {Modal} from "bootstrap";
import DefaultButton from "@/components/ui/DefaultButton.vue";

export default {
  emits: [ 'toastShow' ],
  data() {
    return {
      avatarImage: null,
      avatarUploaded: false,
      isSubmitted: false,
      showSuccess: false,
      showFileSizeError: false,
      toast: null,
      modal: null,
      msg: '',
    };
  },
  props: {
    memberData: {
      type: Object,
      required: true,
    },
  },
  components: {DefaultButton, ImageCropper, UserAvatar},
  computed: {
    ...mapState(useProfileStore, {profileImage: "profileImage"})
  },
  methods: {
    onFileSelected(event) {
      this.showFileSizeError = false;
      this.avatarImage = '';
      this.avatarUploaded = false;
      const [ file ] = event.target.files;
      if (file) {
        if (file.size / (1024 * 1024) > 16) {
          this.showFileSizeError = true;
          this.$emit('toastShow', false, this.$t('profile.pictureSizeExceeded'));
        } else {
          this.avatarImage = URL.createObjectURL(file);
          this.avatarUploaded = true;
        }
      }
    },
    deleteAvatar() {
      this.deletePhoto(this.memberData.memberId).then(async response => {
        if (response.status === 200) {
          await this.getPhotoAsBase64({memberId: this.memberData.memberId});
          this.msg = this.$t('profile.save.success');
          this.showSuccess = true;
          this.showFileSizeError = false;
        }
      }).catch(error => {
        this.showFileSizeError = false;
        this.showSuccess = false;
        this.msg = this.$t('profile.save.failure');
        console.error(error);
      }).finally(() => {
        this.modal.hide();
        this.$emit('toastShow', this.showSuccess, this.msg);
      });
    },
    saveAvatar(img) {
      this.isSubmitted = true;
      this.savePhoto(img).then(async response => {
        if (response && response.status === 200) {
          await this.getPhotoAsBase64({memberId: this.memberData.memberId});
          window.scrollTo(0, 0);
          this.showSuccess = true;
          this.msg = this.$t('profile.save.success');
          this.showFileSizeError = false;
          this.avatarUploaded = false;
        }
      }).catch(error => {
        this.showSuccess = false;
        this.showFileSizeError = false;
        this.msg = this.$t('profile.save.failure');
        console.log('error saving image', error);
      }).finally(() => {
        this.isSubmitted = false;
        this.modal.hide();
        this.$emit('toastShow', this.showSuccess, this.msg);
      });
    },
    cancelCropping() {
      this.isSubmitted = false;
      this.avatarUploaded = false;
    },
    ...mapActions(useProfileStore, {savePhoto: "savePhoto", getPhotoAsBase64: "getPhotoAsBase64", deletePhoto: "deletePhoto"})
  },
  mounted() {
    this.modal = new Modal('#imageCropperModal');
    document.querySelector('#imageCropperModal').addEventListener('hide.bs.modal', () => {
      this.cancelCropping();
    });
  }
}
</script>

<style scoped lang="scss">
.modal-body {
  .img-profile {
    margin: 0 auto;
    max-width: 300px;
  }
}
</style>
