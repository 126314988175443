export default {
  "address": {
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays "])},
    "plz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPA"])},
    "postbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case postale "])},
    "row1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne d’adresse 1"])},
    "row2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne d’adresse 2 "])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue "])},
    "streetnr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "advertisement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annonce"])}
  },
  "appTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myFSG"])},
  "association": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Association"]), _normalize(["Associations"])])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "breadcrumb": {
    "change2FA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Sicherheit"])},
    "changeBirthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la date de naissance"])},
    "changeEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l’e-mail "])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mot de passe "])},
    "changeProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les données de contact"])},
    "changeSports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les sports"])},
    "showProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])}
  },
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interrompre "])},
  "club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société"])},
  "cropper": {
    "zoomIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrandir"])},
    "zoomOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduir"])}
  },
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Modifier ", _interpolate(_named("object"))])},
  "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
  "errorMessages": {
    "access_denied_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d’accès "])},
    "access_denied_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette fonction n’est malheureusement pas à votre disposition."])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur inattendue "])},
    "invalid_credentials_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de connexion "])},
    "invalid_credentials_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la connexion. Veuillez vérifier vos données d’accès."])},
    "invalid_session_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session expirée "])},
    "invalid_session_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre séance a expiré. Veuillez vous connecter à nouveau."])},
    "network_error_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actuellement l’application n’est pas disponible. Veuillez réessayer plus tard !"])}
  },
  "faq": {
    "infoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des questions sur myFSG? Consultez donc notre page d'information et notre F.A.Q."])}
  },
  "footer": {
    "dataProtection": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclaration sur la protection des données"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/declaration-sur-la-protection-des-donnees.html"])}
    },
    "goldPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenaires d'or"])},
    "impressum": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/impressum.html"])}
    },
    "platinPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenaires de platine"])},
    "termsConditions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes et conditions"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/termes-et-conditions.html"])}
    }
  },
  "functions": {
    "aktivmitglied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre actif "])},
    "ehrenmitglied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre d’honneur "])},
    "kassier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caissier "])},
    "kassierin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caissière "])},
    "mitgliedGeraeteturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre gymnastique aux agrès "])}
  },
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])},
  "links": {
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/membre-societe/myfsg-carte-de-membre-numerique.html"])},
    "partner": {
      "coop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.coop.ch/fr/entreprise/groupe-coop.html"])},
      "jako": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/a-propos-de-la-fsg/sponsors-partenaires/partner-1.html#c20941"])},
      "localCities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/a-propos-de-la-fsg/sponsors-partenaires/partner-1.html#c20939"])},
      "mobilezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/a-propos-de-la-fsg/sponsors-partenaires/partner-1.html#c20942"])},
      "ochsner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.ochsnersport.ch/fr/shop/"])},
      "swica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.swica.ch/fr/partner/sportfoerderung/stv/avantages-swica"])}
    },
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/declaration-sur-la-protection-des-donnees.html"])}
  },
  "login": {
    "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("detail"))])},
    "intro": {
      "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président central"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur myFSG, plateforme numérique des membres de la gymnastique suisse. Je vous remercie de votre affiliation à une société et de votre engagement en faveur de la gymnastique suisse !"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de votre engagement !"])}
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "passwordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe : Saisie obligatoire"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S’inscrire à myFSG "])},
    "totp": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification à deux facteurs : Saisie obligatoire"])},
      "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Avec cette étape supplémentaire, vous confirmez que vous souhaitez vous connecter à myFSG avec ", _interpolate(_list(0)), ".\nVeuillez saisir le code de confirmation à 6 chiffres de l'application Authenticator sur votre téléphone portable."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion avec authentification à deux facteurs"])}
    },
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail ou numéro de membre"])},
    "usernameError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d’utilisateur : Saisie obligatoire "])}
  },
  "membership": {
    "eintritt": {
      "panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée événements FSG"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Championnats suisses de la FSG"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée gratuite"])}
    },
    "leistungsSportLizenz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’attestation du sport de performance"])},
    "olympischeLizenz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence Mission olympique"])},
    "richterbrevet": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Brevet de juge"]), _normalize(["Brevets de juge"])])},
    "sportsInsurance": {
      "infoLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/assurance-cas.html"])},
      "infoLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos Caisse d’assurance du sport"])},
      "serviceLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/assurance/prestations.html"])},
      "serviceLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestations "])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Conformément au règlement, les membres actifs de la FSG sont assurés par la Caisse d'assurance de sport de la FSG pour les cas de responsabilité civile, les bris de lunettes et les accidents (en complément d'assurances tierces). ", _interpolate(_named("link"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caisse d’assurance du sport"])}
    },
    "voluntaryWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honneurs"])}
  },
  "menu": {
    "manageClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion de la société"])},
    "memberCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma carte"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu "])},
    "ownProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profil"])},
    "personalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données personnelles "])},
    "releasedMembers": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à ton compte"])},
      "memberCard": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Carte de membre de ", _interpolate(_named("releaseMember"))])},
      "profile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Profil de ", _interpolate(_named("releaseMember"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres de la famille"])}
    },
    "singleMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre individuel"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])}
  },
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant "])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "notFound": {
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vers la page d’accueil "])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’accès indiqué est introuvable."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introuvable "])}
  },
  "offers": {
    "clubs": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n’est pas toujours aisé de trouver des sponsors, que ce soit pour sa société ou pour une manifestation. La FSG a noué plusieurs partenariats intéressants dont vous aussi pouvez profiter en tant que société ou CO. Jetez un coup d’œil, ça en vaut la peine."])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres pour les sociétés"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tirer profit des partenariats de la FSG"])}
    },
    "members": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En tant que membre FSG, vous soutenez directement la gymnastique suisse et permettez à la FSG et à ses sociétés de gymnastique de proposer des offres variées. En guise de remerciement, tous les gymnastes actifs bénéficient d’offres et rabais spéciaux accordés par les partenaires de la FSG."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres pour les membres"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mille mercis pour votre confiance et votre soutien!"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres pour les membres"])}
  },
  "personalData": {
    "person": {
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance "])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom "])},
      "gender": {
        "d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
        "f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Féminin "])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
        "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculin"])}
      },
      "nameAddition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom supplémentaire "])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne"])},
      "squads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])}
    }
  },
  "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu"])},
  "print": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Imprimer ", _interpolate(_named("object"))])},
  "profile": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "addressInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour que nous puissions vous faire parvenir la revue de la fédération GYMLive ainsi que vos certificat."])},
    "birthday": {
      "changeBirthday": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("breadcrumb.changeBirthday", undefined, _type)])},
      "changeSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta date de naissance a été corrigée avec succès."])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible que ton club ait saisi une date de naissance erronée. Tu peux l'adapter ici."])},
      "saveNewBirthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer la date de naissance"])},
      "title": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("personalData.person.birthday", undefined, _type)])}
    },
    "changePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la photo du profil"])},
    "changeSports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les sports"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes informations de contact"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays "])},
    "delete": {
      "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veux-tu vraiment supprimer ta photo de profil?"])}
    },
    "deletePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la photo de profil "])},
    "editProfile": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("breadcrumb.changeProfile", undefined, _type)])},
    "email": {
      "changeEmail": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("breadcrumb.changeEmail", undefined, _type)])},
      "changeFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Votre adresse électronique n’a pas pu être modifiée."])},
      "changePending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton lien est en cours de vérification ..."])},
      "changeSubmitted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>E-mail modifié: Vérifiez votre boîte de réception pour l'adresse <strong>", _interpolate(_named("email")), "</strong> et confirmez l’adresse.</p><p>Jusque-là le changement n’est pas pris en compte.</p>"])},
      "changeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre e-mail a été modifié."])},
      "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la nouvelle adresse électronique "])},
      "currentEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail actuel"])},
      "emailAlreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette adresse e-mail est déjà utilisée. Veuillez saisir une nouvelle adresse e-mail valide."])},
      "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir le mot de passe "])},
      "invalidConfirmationPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe que tu as saisi n'est pas valide. Veuillez vérifier votre saisie"])},
      "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir le mot de passe pour modifier votre adresse électronique."])},
      "newEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel e-mail "])},
      "passwordInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir le mot de passe pour changer votre adresse électronique."])},
      "submitEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer nouvel e-mail  "])},
      "submitFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la modification de l'adresse électronique. Essayer à nouveau."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "tokenInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien indiqué n’est plus valable. Veuillez vérifier la barre d’adresse du navigateur ou réinitialiser votre adresse électronique."])},
      "wrongEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une adresse électronique valide."])}
    },
    "emailInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pour votre login et pour les mises à jour de la FSG ainsi que de votre société de gymnastique. Vous pouvez également vous inscrire aux concours et aux cours."])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom "])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom et nom "])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom "])},
    "mailBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case postale"])},
    "memberNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de membre ou e-mail "])},
    "password": {
      "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel "])},
      "edit": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("breadcrumb.changePassword", undefined, _type)])},
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe n’a pas pu être modifié. Vérifiez vos saisies et réessayer."])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 7 caractères "])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe correspond "])},
      "matchOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nouveau mot de passe ne doit pas être le même que le précédent  "])},
      "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe "])},
      "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passse rempli "])},
      "passwordConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe (confirmer)"])},
      "savePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer nouveau mot de passe"])},
      "specialChars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractères spéciaux présents "])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton mot de passe a été modifié avec succès."])},
      "upperLowerCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majuscules et minuscules présentes"])}
    },
    "phone": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour que tes entraîneurs*es puissent te joindre."])},
      "noPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu n'as pas encore indiqué de numéro de téléphone."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])}
    },
    "phoneInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour que tes entraîneurs*es puissent te joindre."])},
    "picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo de profil "])},
    "pictureSizeExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier sélectionné dépasse la taille autorisée de 16MB"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu"])},
    "save": {
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes données n’ont pas pu être enregistrées."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes données ont été enregistrées avec succès."])}
    },
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer les modifications "])},
    "saveProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer les données de contact"])},
    "security": {
      "authenticatorInfo": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Scanne den QR-Code mit einer Authenticator App (z.B. Google Authenticator, FreeOTP). Übertrage anschliessend den Code aus der App in das untenstehende Eingabefeld ein. Wähle danach eine Bezeichnung damit du das Gerät eindeutig identifizieren kannst. Um die Registration deines Geräts abzuschliessen, musst du danach noch den Button am Ende anklicken."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vorgehen"])}
      },
      "device": {
        "delete": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
          "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre appareil n'a pas pu être déconnecté."])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre appareil a été déconnecté avec succès."])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Souhaitez-vous vraiment déconnecter l'appareil sélectionné \"", _interpolate(_list(0)), "\"?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnecter l’appareil"])}
        }
      },
      "deviceName": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désignation de votre appareil"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désignation entre 3 et 100 caractères"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par ex. Nokia 3210"])}
      },
      "devices": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
        "noDevicesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore enregistré d'appareil."])},
        "register": {
          "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre appareil n'a pas pu être enregistré. Veuillez réessayer."])},
          "failureDeviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom de votre appareil ne contient pas le nombre de caractères requis (3-100)."])},
          "failureInitalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Votre code initial ne contient pas les 6 chiffres requis."])},
          "failureOneDeviceOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà enregistré un appareil. Malheureusement, un seul appareil peut être enregistré."])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre appareil a été enregistré avec succès."])}
        }
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification à deux facteurs"])},
      "explanation": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ajoutant votre appareil, l'authentification à deux facteurs est automatiquement activée. A partir de ce moment, un code de sécurité à six chiffres vous sera demandé lors de la connexion. Vous pourrez lire le code dans l'application Authenticator. Pour désactiver ce niveau de sécurité supplémentaire, il suffit de supprimer votre appareil de la liste ci-dessous."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer/désactiver l'authentification à deux facteurs"])}
      },
      "initialCode": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code initial de l'application Authenticator"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 chiffres"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par ex. 140792"])}
      },
      "qrCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre code QR pour configurer un nouvel appareil"])},
      "saveDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer l'appareil"])},
      "secret": {
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier la clé"])},
        "copyToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici pour copier la clé de configuration dans le presse-papiers"])},
        "keyHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarque : il s'agit d'une clé basée sur le temps"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé de configuration"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Au cas où vous ne pourriez pas scanner le code QR, vous pouvez également procéder à la configuration manuellement. Pour cela, veuillez copier la clé d'installation et la coller dans votre application Authenticator."])}
      }
    },
    "showProfile": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("breadcrumb.showProfile", undefined, _type)])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue"])},
    "uploadNewPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger une nouvelle photo de profil "])},
    "uploadPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger une photo de profil "])},
    "validation": {
      "invalidCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un pays valable"])},
      "invalidFirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un prénom"])},
      "invalidLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un nom"])},
      "invalidMailBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un case postale valable "])},
      "invalidMemberNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un numéro de membre FSG valable ou un e-mail "])},
      "invalidName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un nom complet "])},
      "invalidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un numéro de téléphone valable"])},
      "invalidPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un lieu valable"])},
      "invalidStreet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer une rue valable "])},
      "invalidZip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un NPA valable "])}
    },
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPA"])}
  },
  "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarques "])},
  "save": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enregistrer ", _interpolate(_named("object"))])},
  "section": {
    "clubMembers": {
      "filter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recherche par ", _interpolate(_named("filter"))])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres"])},
      "noMembersFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun membre actif trouvé"])},
      "pagination": {
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("from")), " à ", _interpolate(_named("to")), " sur ", _interpolate(_named("total"))])},
        "page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("page")), " de ", _interpolate(_named("totalPages"))])}
      },
      "printSingleCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer des cartes individuelles"])},
      "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Pas de carte selectionnée"]), _normalize(["Une carte selectionnée"]), _normalize([_interpolate(_named("count")), " cartes selectionnées"])])},
      "status": {
        "EMAIL_DUPLICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double adresse e-mail"])},
        "EMAIL_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail invalide"])},
        "EMAIL_MISSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d’e-mail"])},
        "INITIATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "OFFBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En préparation"])},
        "ONBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])},
        "OPT_OUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne souhaite pas de compte myFSG"])},
        "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
        "QUARANTINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur technique"])},
        "REMINDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut myFSG"])}
      }
    },
    "education": {
      "brevets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes brevets et mes formations"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
      "noBrevetEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de brevets ni de formations"])},
      "noEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de cours ni d’inscriptions "])},
      "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport"])},
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes formations "])},
      "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validité"])}
    },
    "function": {
      "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonction"])},
      "noEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de fonctions "])},
      "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
      "stvMember": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Employé/e de la FSG"]), _normalize(["Employée de la FSG"]), _normalize(["Employé de la FSG"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes fonctions"])}
    },
    "member": {
      "honor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honneur"])},
      "infobox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>En cas de données et de contenus erronés concernant l'affiliation à ta sociéte, adresse-toi à l'administrateur FSG-Admin de ta sociéte. En cas de données erronées au niveau de la fédération, veuillez vous adresser à votre association cantonale ou à la FSG.</p><p>Lorsque des modifications de données et de contenus sont effectuées dans myFSG ou dans STV-Admin, il peut s'écouler jusqu'à 24 heures avant qu'elles ne soient visibles dans l'autre plateforme.</p>"])},
      "memberNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No de membre "])},
      "titleClubMember": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("section.member.titleMembercard", undefined, _type)])},
      "titleFunctionMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma carte de fonction"])},
      "titleMembercard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte de membre"])},
      "titleSingleMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma carte de membre "])}
    },
    "membership": {
      "association": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("association", undefined, _type)])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie de membres "])},
      "entryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d’entrée "])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'historique des cours de J+S et d'esa peut être consulté sur les sites Internet de Jeunesse+Sport et d'esa."])},
      "memberNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No de membre"])},
      "memberOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre de"])},
      "membershipType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d’affiliation "])},
      "mutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée/Mutations"])},
      "noClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune affiliation active valable à une société de gymnastique"])},
      "noEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore d’affiliation "])},
      "squad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes affiliations"])}
    },
    "section": {
      "clubMembers": {
        "printCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer une feuille de collecte"])}
      }
    },
    "start": {
      "adminMessage": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tu es admin à la société"]), _normalize(["Tu es admin aux sociétés"])])},
      "emulatingMember": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tu es actuellement sur le compte de ", _interpolate(_named("releaseMember"))])},
      "familyMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu peux gérer les membres de la famille suivants"])},
      "greeting": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Salut ", _interpolate(_named("user")), "."])},
      "greetingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est bien que tu sois là."])},
      "headerImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headerbild"])},
      "showClubMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les membres de ma société"])},
      "showMemberCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher ma carte de membre"])},
      "showReleaseMemberCard": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Afficher la carte de membre de ", _interpolate(_named("releaseMember"))])}
    }
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
  "sport": {
    "aerobic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aérobic"])},
    "akrobatikturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gymnastique acrobatique"])},
    "fachteste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test de Branche"])},
    "faustball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balle au poing"])},
    "fit-fun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fit+Fun"])},
    "fitness_gesundheitssport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitness/Sport santé"])},
    "geraeteturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gymnastique aux agrès"])},
    "gymnastik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gymnastique et danse"])},
    "handball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handball"])},
    "indiaca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiaca"])},
    "kinderturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gymnastique enfantine"])},
    "korbball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balle à la corbeille "])},
    "kunstturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gymnastique artistique"])},
    "leichtathletik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Athlétisme"])},
    "nationalturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeux nationaux"])},
    "netzball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzball"])},
    "parkour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkour"])},
    "rhoenrad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rhönrad"])},
    "rhythmischeGymnastik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gymnastique rythmique"])},
    "ropeskipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rope skipping"])},
    "schnurball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnurball"])},
    "sportakrobatik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport acrobatique"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes branches sportives"])},
    "trampolin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trampoline"])},
    "turnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gymnastique"])},
    "unihockey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unihockey"])},
    "volley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volleyball"])}
  },
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure"])},
  "user": {
    "forgotPassword": {
      "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour vers le login"])},
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une erreur est survenue lors de la demande. Vérifiez vos données et réessayiez plus tard."])},
      "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié "])},
      "forgot_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Saisissez votre numéro de membre myFSG ou l'adresse électronique associée à votre compte. Nous vous enverrons un lien pour créer un nouveau mot de passe."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander le lien "])},
      "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le lien demandé pour réinitialiser le mot de passe a été envoyé avec succès. Si une adresse e-mail existe pour ", _interpolate(_named("memberNumber")), ", le lien sera envoyé à cette adresse. Prüfe auch deinen Spam-Ordner."])},
      "toLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vers le login "])}
    },
    "onboarding": {
      "createAccount": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vos données ont pu être attribuées à un compte de membre, vous avez reçu un message de notre part pour activer votre compte. Veuillez également vérifier votre dossier de spam.\nSi vous n’avez pas reçu de message, veuillez contacter votre société."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre numéro de membre FSG ou l'adresse e-mail que votre société de gymnastique a enregistrée dans la base de données des membres FSG. Si vos données répondent aux conditions d'accès à myFSG, nous vous enverrons ensuite un lien pour créer un mot de passe."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer votre compte myFSG"])}
      },
      "enterPassword": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("profile.password.newPassword", undefined, _type)])},
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez définir ici votre mot de passe pour votre accès à myFSG."])},
      "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau chez myFSG?"])},
      "passwordConfirm": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("profile.password.passwordConfirm", undefined, _type)])},
      "privacyCheck": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["J'ai lu et compris la ", _interpolate(_list(0)), "."])},
      "privacyLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["déclaration de confidentialité"])},
      "savePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer le compte"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander le lien"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a été créé avec succès."])},
      "termsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d'utilisation"])},
      "termsAndConditionsCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai lu et accepté les conditions d'utilisation."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un mot de passe"])},
      "tokenInvalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Le lien indiqué n’est plus valable. Vous pouvez demander ", _interpolate(_list(0)), " un nouveau lien d'activation."])}
    },
    "resetPassword": {
      "newPassword": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("profile.password.newPassword", undefined, _type)])},
      "passwordConfirm": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("profile.password.passwordConfirm", undefined, _type)])},
      "reset_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant définir un nouveau mot de passe."])},
      "savePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer nouveau mot de passe "])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a été réinitialisé avec succès."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe "])},
      "tokenInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien indiqué n’est plus valable. Veuillez vérifier la ligne d’adresse du navigateur ou demander à nouveau le lien pour réinitialiser le mot de passe."])}
    }
  },
  "wizard": {
    "button": {
      "postpone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repousser"])},
      "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
      "saveSports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer les disciplines sportives"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])}
    }
  },
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])}
}