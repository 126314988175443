<template>
  <profile-layout :title="memberData.firstName + ' ' + memberData.surname + ' / ' + $t('profile.changeSports')" :is-loading="isSubmitted">
    <template #content>
      <div class="row">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h1 class="fs-5 fs-md-3 mb-5">{{ $i18n.locale !== 'de' ? wizard.titleFr : wizard.titleDe }}</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-3">
                <sports-wizard @updateValues="updateSportWizardValues"></sports-wizard>
            </div>
          </div>
          <div class="row">
            <div class="col-12 actions">
              <DefaultButton @click="submitHandler" class="btn-outline-danger" :label="$t('wizard.button.submit')"/>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div id="toastWrapper" class="position-fixed bottom-0 end-0 p-3" style="z-index:1000;"></div>
  </profile-layout>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {useMemberStore} from "@/store/modules/member/member.js";
import ProfileLayout from "@/components/profile/ProfileLayoutWide";
import {removeEmpty} from "@/helpers/functions"
import SportsWizard from '@/views/wizard/SportsWizard';
import DefaultButton from '@/components/ui/DefaultButton';

const submittedData = {
  wizardId: null,
  status: null,
  data: null,
}

export default {
  data() {
    return {
      showFailure: false,
      showSuccess: false,
      isSubmitted: false,
      wizardComponents: null,
      formData: {
        valuesAmateur: [],
        valuesCompetitive: []
      }
    };
  },
  computed: {
    ...mapState(useMemberStore, {memberData: "getMemberData", wizard: "getWizardData"})
  },
  beforeMount() {
    this.wizardComponents = this.$i18n.locale !== 'de' ? removeEmpty(this.wizard.componentsFr) : removeEmpty(this.wizard.componentsDe)
    submittedData.wizardId = this.wizard.id
  },
  methods: {
    async updateSportWizardValues(formData) {
      console.log(formData)
      submittedData.data = formData
    },
    async submitHandler() {
      this.isSubmitted = true;
      submittedData.status = "DONE";
      await this.responseHandler(await this.postWizardData(submittedData));
    },
    async responseHandler(response) {
      if (response && response.status === 200) {
        this.showSuccess = true;
      } else {
        this.showFailure = true;
      }

      window.scrollTo(0, 0);
      const type = this.showSuccess ? 'bg-success' : 'bg-danger';
      const msg = this.showSuccess ? this.$t('profile.save.success') : this.$t('profile.save.failure');

      await this.loadMemberData(true, false).then(() => {
        this.$router.push({
          name: 'profile',
          params: {showToast: true, type: type, message: msg},
        });
      });

    },
    createToastMessage(type, message) {
      const toastElement = document.getElementById('toastWrapper');

      const toastMessage = document.createElement('div');
      toastMessage.setAttribute('id', 'toastMessage');
      toastMessage.setAttribute('role', 'alert');
      toastMessage.setAttribute('aria-live', 'assertive');
      toastMessage.setAttribute('aria-atomic', 'true');
      toastMessage.classList.add('toast', 'align-items-center', 'text-white', type);
      toastMessage.innerHTML = `<div class="d-flex"><div class="toast-body">${message}</div><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div>`;

      toastElement.appendChild(toastMessage);
    },

    ...mapActions(useMemberStore, ["postWizardData", "loadMemberData" ])
  },
  watch: {
    '$i18n.locale': {
      async handler() {
        if (this.wizardComponents !== null) {
          this.wizardComponents = this.$i18n.locale !== 'de' ? removeEmpty(this.wizard.componentsFr) : removeEmpty(this.wizard.componentsDe)
          this.amateurComponents = this.wizardComponents[0]
          this.competitiveComponents = this.wizardComponents[1]
        }
      },
      immediate: true,
      deep: true,
    }
  },
  components: {
    ProfileLayout,
    DefaultButton,
    SportsWizard
  }
}
</script>

<style scoped lang="scss">
.alert {
  margin-top: .5em;
  padding: .5em 1em;
}

.alert.alert-success {
  color: #4E824E;
  background-color: #E3FFE3;
  border-color: #99D199;
}

.bi {
  width: 1em;
  height: 1em;
  fill: currentcolor;
  margin-right: .5em
}

.btn-outline-danger {
  color: #D8232A;
  border-color: #D8232A;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #D8232A;
}

.actions {
    display: flex;
    justify-content: right
  }

.actions > .btn {
  align-self: baseline;
  margin-left: .5em
}

</style>

