<template>
    <div class="alert alert-danger" v-if="isShowLocalErrorMessages">
      <div v-for="error in getLocalErrorMessages" :key="error.clientId">
        <strong>{{error.summaryText}}</strong>: {{error.detailText}}
      </div>
    </div>
</template>

<script>
//import {mapGetters} from "vuex";
import { mapState } from 'pinia';
import { useMessagesStore } from "@/store/modules/messages/messages.js";

export default {
  data() {
    return {
      showError: false,
    
    };
  },
  
  computed: {
    ...mapState(useMessagesStore, ["getLocalErrorMessages", "isShowLocalErrorMessages"]),
  },
  components: {

  },
};
</script>

