<template>
  <profile-layout :title="memberData.firstName + ' ' + memberData.surname + ' / ' + $t('profile.security.edit')" :is-loading="isSubmitting">
    <template #content>
      <div class="row mb-4 align-items-end">
        <div class="col-12 col-lg-8 flex align-self-baseline">
          <label for="qrCode" class="form-label">{{ $t('profile.security.qrCode') }}</label>
          <img id="qrCode" :src="'data:image/png;base64,' + totpRequest.qrCode" alt="QR-Code" v-if="totpRequest !== null">
        </div>
        <div class="col-12 col-lg-4 align-self-baseline text-hyphens">
          <p class="form-label">{{ $t('profile.security.authenticatorInfo.title') }}</p>
          <p>{{ $t('profile.security.authenticatorInfo.text') }}</p>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12 col-lg-8 flex align-self-baseline">
          <div class="form-floating has-validation position-relative">
            <input id="secret" type="text" disabled="true" class="form-control" :value="totpRequest.secret" v-if="totpRequest !== null"/>
            <label for="secret" class="form-label">{{ $t('profile.security.secret.label') }}</label>
            <span class="btn btn-copy"
                  @click="copySecret"
                  @keyup.enter="copySecret"
                  data-toggle="tooltip"
                  :data-original-title="$t('profile.security.secret.copyToolTip')"
                  :title="$t('profile.security.secret.copyToolTip')"
                  v-if="totpRequest !== null">
              <i class="bi m-0" :class="{'bi-clipboard': !isCopied, 'bi-clipboard-check': isCopied}"></i>
            </span>
          </div>
        </div>
        <div class="col-12 col-lg-4 text-hyphens pt-3 pt-lg-0">
          <p>{{ $t('profile.security.secret.text') }}</p>
          <p>{{ $t('profile.security.secret.keyHint') }}</p>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12 col-lg-8">
          <div class="form-floating has-validation">
            <input id="initialCode" type="text" class="form-control" :class="{'is-invalid': initialCode !== null && !ruleInitialCodeLength}" v-model="initialCode" @keyup="validateInitialCode" @change="validateInitialCode" :placeholder="$t('profile.security.initialCode.placeholder')">
            <label for="initialCode" class="form-label">{{ $t('profile.security.initialCode.label') }}</label>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <ul class="list-unstyled">
            <li :class="ruleInitialCodeLength ? 'text-success' : initialCode === null ? '' : 'text-danger'">{{ $t('profile.security.initialCode.length') }}</li>
          </ul>
        </div>
      </div>
      <div class="row mb-4 align-items-end">
        <div class="col-12 col-lg-8">
          <div class="form-floating has-validation">
            <input id="deviceName" type="text" class="form-control" :class="{'is-invalid': deviceName !== null && !ruleDeviceNameLength}" v-model="deviceName" @keyup="validateDeviceName" @change="validateDeviceName" :placeholder="$t('profile.security.deviceName.placeholder')">
            <label for="deviceName" class="form-label">{{ $t('profile.security.deviceName.label') }} ({{ $t('profile.security.deviceName.placeholder') }})</label>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <ul class="list-unstyled">
            <li :class="ruleDeviceNameLength ? 'text-success' : deviceName === null ? '' : 'text-danger'">{{ $t('profile.security.deviceName.length') }}</li>
          </ul>
        </div>
      </div>

      <div class="col-12 col-md-5 offset-md-3">
        <DefaultButton
          :label="$t('profile.security.saveDevice')"
          @click="submitDevice"
          @keyup.enter="submitDevice"
          class="btn-outline-dark"
          :disabled="!ruleInitialCodeLength">
        </DefaultButton>
      </div>

      <div id="toastWrapper" class="position-fixed bottom-0 end-0 p-3" style="z-index: 1000;"></div>
    </template>
  </profile-layout>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {useMemberStore} from "@/store/modules/member/member.js";
import {useLoginStore} from "@/store/modules/login/login.js";
import ProfileLayout from "@/components/profile/ProfileLayout";
import DefaultButton from '@/components/ui/DefaultButton.vue';
import {Toast, Tooltip} from "bootstrap";

const regexDigit = new RegExp('^[0-9]{6}$');

export default {
  data() {
    return {
      hasDevice: false,
      isLoading: false,
      totpRequest: null,
      initialCode: null,
      ruleInitialCodeLength: false,
      ruleDeviceNameLength: false,
      isSubmitting: false,
      deviceName: null,
      isCopied: false,
    };
  },
  computed: {
    ...mapState(useMemberStore, {memberData: "getMemberData"})
  },
  methods: {
    validateInitialCode() {
      this.ruleInitialCodeLength = true;
      if (this.initialCode && this.initialCode !== null) {
        if (!regexDigit.test(this.initialCode)) {
          this.ruleInitialCodeLength = false;
        }

      } else {
        this.ruleInitialCodeLength = false;
      }
    },
    validateDeviceName() {
      this.ruleDeviceNameLength = true;
      if (this.deviceName && this.deviceName !== null) {
        if (this.deviceName.length > 100 || this.deviceName.length < 3) {
          this.ruleDeviceNameLength = false;
        }

      } else {
        this.ruleDeviceNameLength = false;
      }
    },
    copySecret() {
      navigator.clipboard.writeText(this.totpRequest.secret).then(() => {
        this.isCopied = true;
      });
    },
    async submitDevice() {
      this.isSubmitting = true;

      this.submitNewDevice({
        "secret": this.totpRequest.secret,
        "deviceName": this.deviceName,
        "initialCode": this.initialCode
      }).then(async response => {
        if (response && response.status === 200) {
          this.$router.push({
            name: 'change-two-factor-settings',
            params: {showToast: true, type: 'bg-success', message: this.$t('profile.security.devices.register.success')},
          });
        } else {
          if (response.data) {
            console.log(response.data);
            this.createToastMessage('bg-danger', this.$t('profile.security.devices.register.' + response.data + ''));
            const toastMessage = document.getElementById('toastMessage');
            const toast = new Toast(toastMessage);
            toast.show();
          } else {

            this.$router.push({
              name: 'change-two-factor-settings',
              params: {showToast: true, type: 'bg-danger', message: this.$t('profile.security.devices.register.failure')},
            });
          }
        }
      }).catch(() => {
        this.$router.push({
          name: 'change-two-factor-settings',
          params: {showToast: true, type: 'bg-danger', message: this.$t('profile.security.devices.register.failure')},
        });
      }).finally(() => {
        this.isSubmitting = false;
        window.scrollTo(0, 0);

      });
    },
    createToastMessage(type, message) {
      const toastWrapper = document.getElementById('toastWrapper');
      const toastMessage = document.createElement('div');
      toastMessage.setAttribute('id', 'toastMessage');
      toastMessage.setAttribute('role', 'alert');
      toastMessage.setAttribute('aria-live', 'assertive');
      toastMessage.setAttribute('aria-atomic', 'true');
      toastMessage.classList.add('toast', 'align-items-center', 'text-white', type);
      toastMessage.innerHTML = `<div class="d-flex"><div class="toast-body">${message}</div><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div>`;
      toastWrapper.replaceChildren(toastMessage);
    },
    ...mapActions(useLoginStore, ["changePassword"]),
    ...mapActions(useMemberStore, ["requestNewDevice", "submitNewDevice"]),
  },
  async beforeMount() {
    this.totpRequest = await this.requestNewDevice();
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-toggle='tooltip']",
      trigger: "hover"
    })
  },
  components: {
    ProfileLayout,
    DefaultButton
  }
}
</script>

<style scoped lang="scss">
.alert {
  margin-top: .5em;
  padding: .5em 1em;
}

.alert.alert-success {
  color: #4E824E;
  background-color: #E3FFE3;
  border-color: #99D199;
}

.bi {
  width: 1em;
  height: 1em;
  fill: currentcolor;
  margin-right: .5em
}
</style>

