<template>
  <p class="legend">{{ getSportsAmateurProps().label }}</p>
  <p class="help mb-3">{{ getSportsAmateurProps().help }}</p>
  <div class="form-check" v-for="checkbox in getSportsAmateur()" :key="checkbox.id">
    <input class="form-check-input" type="checkbox" :id="checkbox.id" :value="checkbox.value" :disabled="checkbox.disabled" :checked="checkbox.checked" v-model="formData.valuesAmateur" @change="handleChange">
    <label class="form-check-label" :for="checkbox.id">
      {{ checkbox.label }}
    </label>
  </div>

  <p class="legend mt-4">{{ getSportsCompetitiveProps().label }}</p>
  <p class="help mb-3">{{ getSportsCompetitiveProps().help }}</p>
  <div class="form-check" v-for="checkbox in getSportsCompetitive()" :key="checkbox.id">
    <input class="form-check-input" type="checkbox" :id="checkbox.id" :value="checkbox.value" :disabled="checkbox.disabled" :checked="checkbox.checked" v-model="formData.valuesCompetitive" @change="handleCompetitiveChange">
    <label class="form-check-label" :for="checkbox.id">
      {{ checkbox.label }}
    </label>
  </div>
</template>

<script>

import {useMemberStore} from "@/store/modules/member/member.js";
import {useI18n} from "vue-i18n";
import {removeEmpty} from "@/helpers/functions"
import {mapState} from 'pinia';

export default {
  emits: ["updateValues"],
  data() {
    return {
      data: null,
      formData: {
        valuesAmateur: [],
        valuesCompetitive: []
      }
    };
  },
  computed: {
    ...mapState(useMemberStore, ["wizardData"])
  },
  methods: {
    handleChange(change) {
      let competitiveId = change.target.id.replace("sa-", "#sc-");
      if(this.$el.parentElement.querySelector(competitiveId) !== null) {
        if (change.target.checked) {
          this.$el.parentElement.querySelector(competitiveId).disabled = false
        } else {
          this.$el.parentElement.querySelector(competitiveId).disabled = true
          this.$el.parentElement.querySelector(competitiveId).checked = false

          let index = this.formData.valuesCompetitive.indexOf(change.target.value);
          if (index >= 0) {
            this.formData.valuesCompetitive.splice(index, 1);
          }
        }
      }

      this.formData.valuesAmateur.sort();
      this.formData.valuesCompetitive.sort();

      this.$emit("updateValues", this.formData);
    },
    handleCompetitiveChange() {
      this.$emit("updateValues", this.formData);
    },
    getWizardTitle() {
      return useI18n().locale.value !== 'de' ? removeEmpty(this.data.componentsFr) : removeEmpty(this.data.componentsDe)
    },
    getWizardComponents() {
      return useI18n().locale.value !== 'de' ? removeEmpty(this.data.componentsFr) : removeEmpty(this.data.componentsDe)
    },
    getSportsAmateurProps() {
      return this.getWizardComponents()[0].props;
    },
    getSportsAmateur() {
      if (this.getWizardComponents()[0].props.type === "checkbox") {
        return this.getWizardComponents()[0].props.options;
      }

      return [];
    },
    getSportsCompetitiveProps() {
      return this.getWizardComponents()[1].props
    },
    getSportsCompetitive() {
      if (this.getWizardComponents()[0].props.type === "checkbox") {
        return this.getWizardComponents()[1].props.options;
      }

      return [];
    },
    initValues() {
      this.formData.valuesAmateur = this.getSportsAmateurProps().value;
      this.formData.valuesCompetitive = this.getSportsCompetitiveProps().value;
    }
  },
  components: {},
  beforeMount() {
    this.data = this.wizardData;
    this.initValues()
  }
};
</script>

<style scoped>
.legend {
  margin-bottom: .5rem;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
}

.help {
  color: rgb(108, 117, 125);
  font-size: 0.875em
}
</style>
