import { uuid } from "vue-uuid";

export default class Message {
    constructor(id, type, showToast, showMessage, summaryText, detailText, global) {
        this.id = id ? id : uuid.v4();
        this.type = type;
        this.showToast = showToast;
        this.showMessage = showMessage;
        this.summaryText = summaryText;
        this.detailText = detailText;
        this.global = global;
    }
}
