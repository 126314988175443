<template>
  <a id="language-navigation"
     class="header-item"
     :class="{active: locale === selectedLocale}"
     v-for="locale in locales"
     :key="locale"
     @click="changeLocale(locale)">
      <span class="d-flex justify-content-center align-self-center">
        <small>{{ locale }}</small>
      </span>
  </a>
</template>

<script>

export default {
  methods: {
    changeLocale(locale) {
      if (this.selectedLocale !== locale) {
        localStorage.setItem('lang', locale);
        this.$i18n.locale = locale;
        this.$router.go();
      }
    },
  },
  computed: {
    browserLocale() {
      return window.navigator.language.substring(0, 2);
    },
    selectedLocale() {
      if (!this.$i18n.locale) {
        return this.browserLocale;
      }
      return this.$i18n.locale;
    },
    locales() {
      return window.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
    },
  },
};
</script>

