<template>
  <membership-table
    :title="$t('section.function.title')"
    :is-empty="(memberData.functions === null || !memberData.functions.length) && !memberData.memberOfStv"
    :empty-message="$t('section.function.noEntries')"
    table-name="function">
    <template #membership-table>
      <table class="table">
        <thead>
        <tr>
          <th>{{ $t('section.function.function') }}</th>
          <th>{{ $t('section.function.organisation') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="memberData.memberOfStv">
          <td>{{ $t('section.function.stvMember', (memberData.sex === 'M' ? 2 : memberData.sex === 'W' ? 1 : 0)) }}</td>
          <td></td>
        </tr>
        <tr v-for="func in memberData.functions" :key="func.id">
          <td>{{ getFunctionDescription(func) }}</td>
          <td>{{ func.organ }}</td>
        </tr>
        </tbody>
      </table>
    </template>
  </membership-table>
</template>

<script>
import MembershipTable from "@/components/personal-data/MembershipTable";
import {useI18n} from "vue-i18n";

export default {
  props: {
    memberData: {
      type: Object,
      required: true,
    }
  },
  components: {
    MembershipTable
  },
  methods: {
    getFunctionDescription(func) {
      return (useI18n().locale.value === 'fr' && func.descriptionFr) ? func.descriptionFr :
        func.description ? func.description : '–';
    }
  },
}
</script>
