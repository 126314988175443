<template>
  <membership-table
      :title="$t('section.membership.title')"
      :is-empty="sortedMemberships.length === 0"
      :empty-message="$t('section.membership.noEntries')"
      table-name="membership">
    <template #membership-table>
      <table class="table">
        <thead>
        <tr>
          <th scope="col">{{ $t('section.membership.category') }}</th>
          <th scope="col">{{ $t('section.membership.entryDate') }}</th>
          <th scope="col">{{ $t('section.membership.memberOf') }}</th>
          <th scope="col">{{ $t('section.membership.squad') }}</th>
          <th scope="col">{{ $t('section.membership.association') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="membership in sortedMemberships" :key="membership.id">
          <td>{{ getMembershipCategoryDescription(membership) }} {{ getMembershipAdditionDescription(membership) }}</td>
          <td>{{ formatDate(membership.entryDate) }}</td>
          <td>{{ membership.verein === null ? '-' : membership.verein.description === null ? membership.verein.code : membership.verein.description }}</td>
          <td>{{ getMembershipSquadDescription(membership) }}</td>
          <td>{{ membership.verband === null ? '–' : membership.verband.description === null ? membership.verband.code : membership.verband.description }}</td>
        </tr>
        </tbody>
      </table>
    </template>
  </membership-table>
</template>

<script>
import MembershipTable from "@/components/personal-data/MembershipTable";
import {useI18n} from "vue-i18n";

export default {
  props: {
    memberData: {
      type: Object,
      required: true,
    }
  },
  components: {
    MembershipTable
  },
  computed: {
    sortedMemberships() {
      const memberships = this.memberData.memberships;
      if (memberships && memberships.length) {
        return memberships.sort(this.sortMethods('Date', 'entryDate', -1));
      }
      return [];
    },
  },
  methods: {
    getMembershipCategoryDescription(membership) {
      return (useI18n().locale.value === 'fr' && membership.category.descriptionFr) ?
          membership.category.descriptionFr :
          (membership.category.description) ? membership.category.description : '–';
    },
    getMembershipAdditionDescription(membership) {
      return (useI18n().locale.value === 'fr' && membership.addition.descriptionFr) ?
          '(' + membership.addition.descriptionFr + ')' :
          (membership.addition.description) ? '(' + membership.addition.description + ')' : ''
    },
    getMembershipSquadDescription(membership) {
      return (useI18n().locale.value === 'fr' && (membership.squad && membership.squad.descriptionFr)) ?
          membership.squad.descriptionFr :
          (membership.squad && membership.squad.description) ? membership.squad.description : '–';
    },
  }
}
</script>

