<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="fs-5 fs-md-3 mb-5">{{ title }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-3" v-if="showMenu">
        <ProfileMenu v-if="isLoggedIn"/>
        <slot name="menu"/>
      </div>
      <div class="col-12" :class="{
        'col-md-5': $slots.aside,
        'col-md-9': !$slots.aside && showMenu
      }" v-if="$slots.content">
        <slot name="content"/>
      </div>
      <div class="col-12 col-md-4" v-if="$slots.aside">
        <slot name="aside"/>
      </div>
    </div>
    <div class="row" v-if="$slots.default">
      <slot/>
    </div>
  </div>
  <LoaderOverlay v-if="isLoading"/>
</template>

<script>
import {mapState} from "pinia";
import ProfileMenu from "@/components/navigation/ProfileMenu";
import {useLoginStore} from "@/store/modules/login/login.js";
import LoaderOverlay from "@/components/ui/LoaderOverlay.vue";

export default {
  components: {
    LoaderOverlay,
    ProfileMenu,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    showMenu: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  computed: {
    ...mapState(useLoginStore, [ "isUser", "isLoggedIn" ])
  },
  beforeMount() {
    document.body.classList.add('bg-white');
  },
  beforeUnmount() {
    document.body.classList.remove('bg-white');
  }
};
</script>
