<template>
  <div id="loader">
    <div class="ld-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
