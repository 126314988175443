import {defineStore} from "pinia";
import ApiClient from "@/api/ApiClient";

const AB_BASE_PATH = '/api/adbutler';

export const useAdButlerStore = defineStore('adButler', {
  actions: {
    async getAbPlacements(payload) {
      let response = await ApiClient.getRequestWithoutAuthorization(`${AB_BASE_PATH}/adserve?setId=${payload.setId}&language=${payload.language}&format=${payload.format}&referrer=${payload.referrer}&userAgent=${payload.userAgent}&screenWidth=${payload.screenWidth}&screenHeight=${payload.screenHeight}&ip=${payload.ip}&kw=${payload.kw ? payload.kw : ''}`);
      if (response) {
        return response;
      }
      return null;
    },
  },
});
