import {createRouter, createWebHistory} from "vue-router";
import urls from "./urls";
import logger from "@/logger/index.js";
import LoginView from "@/views/LoginView.vue";
import StartView from "@/views/StartView.vue";
import MembercardView from "@/views/membercard/MembercardView.vue";
import ShowProfileView from "@/views/profile/ShowProfileView";
import ChangeProfileView from "@/views/profile/ChangeProfileView";
import ChangePasswordView from "@/views/profile/ChangePasswordView";
import ChangeEmailView from "@/views/profile/ChangeEmailView";
import ConfirmEmailView from "@/views/profile/ConfirmEmailView";
import ChangeBirthdayView from "@/views/profile/ChangeBirthdayView";
import ForgotPasswordView from "@/views/password/ForgotPasswordView.vue";
import PasswordResetView from "@/views/password/PasswordResetView.vue";
import OnboardingView from "@/views/OnboardingView";
import CreateAccountView from "@/views/profile/CreateAccountView.vue";
import ClubMembersView from "@/views/ClubMembersView";
import SingleMemberView from "@/views/membercard/SingleMemberView.vue";
import FormWizardView from "@/views/FormWizardView";
import ChangeSportsView from "@/views/profile/ChangeSportsView";
import Change2FAView from "@/views/profile/Change2FAView";
import AddTwoFactorDeviceView from "@/views/profile/AddTwoFactorDeviceView";
import MemberOffersView from "@/views/offers/MemberOffersView.vue";

import NotFound from "@/views/NotFound";

import {useLoginStore} from "@/store/modules/login/login.js";
import {useMemberStore} from "@/store/modules/member/member.js";
import {useProfileStore} from "@/store/modules/profile/profile.js";


const routes = [
  {
    path: urls.ROOT_PAGE,
    name: "rootPage",
    component: StartView,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  },
  {
    path: urls.LOGIN_PAGE,
    name: "login",
    component: LoginView,
  },
  {
    path: urls.FORGOT_PASSWORD,
    name: 'forgot-password',
    component: ForgotPasswordView,
  },
  {
    path: urls.PASSWORD_RESET,
    name: 'password-reset',
    component: PasswordResetView,
  },
  {
    path: urls.ONBOARDING,
    name: 'onboarding',
    component: OnboardingView,
  },
  {
    path: urls.CREATE_ACCOUNT,
    name: 'create-account',
    component: CreateAccountView,
  },
  {
    path: urls.FORM_WIZARD,
    name: "wizard",
    component: FormWizardView,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  },
  {
    path: urls.START_PAGE,
    name: "start",
    component: StartView,
    meta: {
      requiresAuth: true,
      keepAlive: false
    },
  },
  {
    path: urls.MEMBERCARD_PAGE,
    name: "membercard",
    component: MembercardView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: urls.CLUB_MEMBERS_PAGE,
    name: 'clubMembers',
    component: ClubMembersView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.SINGLE_MEMBER_PAGE,
    name: 'singleMember',
    component: SingleMemberView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: urls.USER_PROFILE,
    name: 'profile',
    component: ShowProfileView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.USER_PROFILE_CHANGE_ADDRESS,
    name: 'change-address',
    component: ChangeProfileView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.USER_PROFILE_CHANGE_PASSWORD,
    name: 'change-password',
    component: ChangePasswordView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.USER_PROFILE_CHANGE_EMAIL,
    name: 'change-email',
    component: ChangeEmailView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.USER_PROFILE_CONFIRM_EMAIL,
    name: 'confirm-email',
    component: ConfirmEmailView,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: urls.USER_PROFILE_CHANGE_BIRTHDAY,
    name: 'change-birthday',
    component: ChangeBirthdayView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.USER_PROFILE_CHANGE_SPORTS,
    name: 'change-sports',
    component: ChangeSportsView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.USER_PROFILE_CHANGE_2FA,
    name: 'change-two-factor-settings',
    component: Change2FAView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.ADD_2FA_DEVICE,
    name: 'add-device',
    component: AddTwoFactorDeviceView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.MEMBER_OFFERS,
    name: 'member-offers',
    component: MemberOffersView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

router.beforeEach(async (to, _, next) => {
  const profileStore = useProfileStore();
  const memberStore = useMemberStore();
  const loginStore = useLoginStore();
  await loginStore.init().then(async () => {

    // if navigating to login, check if logged in, if yes, redirect
    if (to.matched.some((record) => record.path === urls.LOGIN_PAGE)) {
      if (loginStore.isLoggedIn) {
        _.fullPath = '/';
        next({path: urls.START_PAGE});
        return;
      }
    }

    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!loginStore.isLoggedIn) {
        next({path: urls.LOGIN_PAGE});
        return;
      } else {
        if (memberStore.getMemberData === undefined || memberStore.getMemberData === null) {
          await memberStore.loadMemberData(true, false);
        }
        if (profileStore.getProfileImage === undefined || profileStore.getProfileImage === null) {
          await profileStore.getPhotoAsBase64(memberStore.getMemberData);
        }

        if (to.path === '/wizard' && to.query && to.query.id !== undefined) {
          await memberStore.loadWizardData(to.query.id);
        } else {
          const wizardIds = await memberStore.loadActiveWizards(memberStore.isShowWizardsAfterLogin);
          if (wizardIds && wizardIds.length > 0) {
            let wizardId = null;
            for (let id in wizardIds) {
              if (!memberStore.wizardsDone.includes(wizardIds[id])) {
                wizardId = wizardIds[id];
                break
              }
            }
            if (wizardId !== null) {
              next({name: 'wizard', query: {id: wizardId}});
              return;
            } else {
              await memberStore.setShowWizardsAfterLogin(false);
            }
          } else if (to.fullPath === '/wizard') {
            _.fullPath = '/';
            next({path: urls.START_PAGE});
            return;
          }
        }

        if (to.fullPath === '/change-sports') {
          await memberStore.loadWizardDataByName('WIZARD_NAME_MEMBER_SPORTS')
        }

        next();
        return;
      }
    } else if (to.matched.some((record) => !record.meta.requiresAuth)) {
      if ((to.path === '/password-initial' || to.path === '/password-reset') && loginStore.isLoggedIn) {
        await loginStore.logout()
      }

      if (loginStore.isLoggedIn) {
        if (memberStore.memberData == null || memberStore.memberData.memberId === undefined) {
          await memberStore.loadMemberData(true, false)
          await profileStore.getPhotoAsBase64(memberStore.memberData.memberId)
        }
      }
    }
    next();
  }).catch((e) => {
    logger.error("Error during routing: " + e)
  });

  // scroll to top
  window.scrollTo(0, 0);

});

export default router;
