<template>
  <div class="card" :class="cardClass" v-if="$slots.header || $slots.content || $slots.footer">
    <div class="card-header" v-if="$slots.header">
      <slot name="header"></slot>
    </div>
    <div class="card-body" v-if="$slots.content">
      <slot name="content"></slot>
    </div>
    <div class="card-footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardClass: String
  },
}
</script>
