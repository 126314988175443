export const ROOT_PAGE = "/";
export const LOGIN_PAGE = "/login";
export const START_PAGE = "/start";
export const MEMBERCARD_PAGE = "/membercard";
export const USER_PROFILE = '/profile';
export const USER_PROFILE_CHANGE_ADDRESS = '/change-address';
export const USER_PROFILE_CHANGE_PASSWORD = '/change-password';
export const USER_PROFILE_CHANGE_EMAIL = '/change-email';
export const USER_PROFILE_CONFIRM_EMAIL = '/confirm-email';
export const USER_PROFILE_CHANGE_BIRTHDAY = '/change-birthday';
export const USER_PROFILE_CHANGE_SPORTS = '/change-sports';
export const USER_PROFILE_CHANGE_2FA = '/change-two-factor-settings';
export const FORGOT_PASSWORD = '/forgot-password';
export const PASSWORD_RESET = "/password-reset";
export const ONBOARDING = '/password-initial';
export const CREATE_ACCOUNT = '/create-account';
export const CLUB_MEMBERS_PAGE = '/club_members';
export const SINGLE_MEMBER_PAGE = '/member';
export const FORM_WIZARD = '/wizard';
export const TOTP = '/totp';
export const ADD_2FA_DEVICE = '/add-device'
export const MEMBER_OFFERS = '/member-offers';

export default {
  ROOT_PAGE,
  LOGIN_PAGE,
  START_PAGE,
  MEMBERCARD_PAGE,
  USER_PROFILE,
  USER_PROFILE_CHANGE_ADDRESS,
  USER_PROFILE_CHANGE_PASSWORD,
  USER_PROFILE_CHANGE_EMAIL,
  USER_PROFILE_CONFIRM_EMAIL,
  USER_PROFILE_CHANGE_BIRTHDAY,
  USER_PROFILE_CHANGE_SPORTS,
  USER_PROFILE_CHANGE_2FA,
  FORGOT_PASSWORD,
  PASSWORD_RESET,
  ONBOARDING,
  CREATE_ACCOUNT,
  CLUB_MEMBERS_PAGE,
  SINGLE_MEMBER_PAGE,
  FORM_WIZARD,
  TOTP,
  ADD_2FA_DEVICE,
  MEMBER_OFFERS,
};
