<template>
  <div id="welcome-container" class="container">

    <div id="intro-portrait">
      <img src="@/assets/img/Fabio_Corti_Portrait.jpg" alt="Fabio Corti">
      <p>
        <strong>Fabio Corti</strong><br>
        {{ $t('login.intro.caption') }}
      </p>
    </div>

    <div id="intro-text">
      <h1>{{ $t('login.intro.title') }}</h1>
      <p>
        {{ $t('login.intro.text') }}
      </p>
    </div>

    <section-container id="login-section">
      <div class="header-img">
        <img src="@/assets/img/SMV_Burgdorf_2018_Foto_Stephan_Boegli_DSC_1683.jpg" alt="mySTV-FSG">
      </div>
      <div id="login-form">
        <GlobalMessage/>
        <div id="username-row" class="form-outline mb-4">
          <div class="form-floating position-relative">
            <input type="text"
                   class="form-control"
                   id="username"
                   :placeholder="$t('login.username')"
                   v-model="username"
                   autofocus
                   ref="usernameInput"
                   @change="show2Fa = false; submittedUsername = null; isSubmitted = false; show2FaError = false"
                   @keyup.enter="doLogin">
            <label class="form-label" for="username">{{ $t('login.username') }}</label>
          </div>
          <div class="alert alert-danger d-flex align-items-center" role="alert" v-if="showUsernameError">
            <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-exclamation-triangle-fil me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg>
            <div>
              {{ $t('login.usernameError') }}
            </div>
          </div>
        </div>
        <div id="password-row" class="form-outline mb-4">
          <div class="form-floating position-relative">
            <input type="password"
                   id="password"
                   class="form-control"
                   :placeholder="$t('login.password')"
                   v-model="password"
                   @keyup.enter="doLogin"
                   :feedback="false">
            <label for="password" class="form-label">{{ $t('login.password') }}</label>
            <button class="password-icon"
                    tabindex="0"
                    @click.prevent="togglePasswordVisibility"
                    @keydown.enter.prevent="doLogin"
                    @keyup.space.prevent="togglePasswordVisibility">
              <i class="bi" :class="{'bi-eye': !pwVisible, 'bi-eye-slash': pwVisible}"></i>
            </button>
          </div>
          <div class="alert alert-danger d-flex align-items-center" role="alert" v-if="showPasswordError">
            <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-exclamation-triangle-fil me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg>
            <div>
              {{ $t('login.passwordError') }}
            </div>
          </div>
        </div>
        <div id="totp-form" class="form-outline mb-4" v-if="show2Fa">
          <label for="totp" class="form-label">{{ $t('login.totp.label') }}</label>
          <Translation keypath="login.totp.info" tag="p" class="form-text" style="white-space:pre-line;">
            <strong>{{ submittedUsername }}</strong>
          </Translation>
          <input type="number"
                 id="totp"
                 class="form-control"
                 placeholder="Code"
                 v-model="totp"
                 ref="totpInput"
                 @keyup.enter.prevent="doLogin">
          <div class="alert alert-danger d-flex align-items-center" role="alert" v-if="show2FaError">
            <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-exclamation-triangle-fil me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg>
            <div>
              {{ $t('login.totp.error') }}
            </div>
          </div>
        </div>
        <div class="form-outline mb-4">
          <LoginError/>
        </div>

        <div class="form-outline d-grid gap-2">
          <DefaultButton
            :label="$t('login.login')"
            @click="doLogin"
            @keyup.enter="doLogin"
            :disabled="isLoading"
            class="btn btn-dark btn-lg font-size-base rounded-2"
          />
          <router-link to="forgot-password" class="btn btn-link btn-lg font-size-base rounded-2 forgot-password">
            {{ $t('user.forgotPassword.forgot') }}?
          </router-link>

          <div class="new-user">
            <h6>{{ $t('user.onboarding.newUser') }}</h6>
          </div>
          <router-link to="create-account" class="btn btn-outline-grey btn-lg btn-block font-size-base rounded-2">
            {{ $t('user.onboarding.createAccount.title') }}
          </router-link>

          <FaqPanel class="py-3 mb-0 mt-3"/>
        </div>
      </div>
    </section-container>

  </div>
  <LoaderOverlay v-if="isLoading"/>
</template>

<script>

import {getActivePinia, mapActions, mapState} from "pinia"
import {FORM_WIZARD} from "@/router/urls.js";
import {useLoginStore} from "@/store/modules/login/login.js";
import {useMessagesStore} from "@/store/modules/messages/messages.js";
import SectionContainer from "@/components/ui/SectionContainer"
import LoginError from "@/components/navigation/LoginError"
import GlobalMessage from "@/components/navigation/GlobalMessage"
import FaqPanel from "@/components/ui/FaqPanel.vue";
import LoaderOverlay from "@/components/ui/LoaderOverlay.vue";
import {Translation} from "vue-i18n";
import {nextTick} from "vue";

export default {
  components: {
    LoaderOverlay,
    SectionContainer,
    LoginError,
    GlobalMessage,
    FaqPanel,
    Translation,
  },
  data() {
    return {
      username: null,
      password: null,
      totp: null,
      showError: false,
      showUsernameError: false,
      showPasswordError: false,
      show2FaError: false,
      usernameError: null,
      passwordError: null,
      pwVisible: false,
      isLoading: false,
      isSubmitted: false,
      submittedUsername: null,
      show2Fa: false,
    };
  },
  computed: {
    selectedLocale() {
      return this.$i18n.locale;
    },
    locales() {
      return window.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');
    },
    ...mapState(useLoginStore, ["isLoggedIn", "has2fa"])
  },
  methods: {
    async doLogin() {
      this.showError = false;
      this.showUsernameError = false;
      this.showPasswordError = false;
      this.clearMessages();

      if (this.username === null || this.username.length < 3) {
        this.showUsernameError = true;
      } else if (this.isNumeric(this.username) && this.username.length < 6) {
        this.username = ('000000' + this.username).slice(-6);
      }

      if (this.password === null || this.password.length < 3) {
        this.showPasswordError = true;
      }

      if (this.showPasswordError || this.showUsernameError) {
        return;
      }

      this.isLoading = true;

      let credentials = {
        username: this.username,
        password: this.password,
      };

      if (!this.totp && !this.isSubmitted) {
        this.isSubmitted = true;
        this.show2Fa = await this.check2fa({memberId: credentials.username});
        if (this.show2Fa) {
          this.isLoading = false;
          this.submittedUsername = credentials.username;
          nextTick().then(() => {
            this.$refs.totpInput.focus();
          });
          return;
        }
      } else if (this.show2Fa) {
        if (this.totp === null || this.totp.length < 0) {
          this.show2FaError = true;
          this.isLoading = false;
          return;
        }
        credentials.totp = this.totp;
      }

      await this.login(credentials);
      if (this.isLoggedIn) {
        window.scrollTo(0, 0);
        await this.$router.replace(FORM_WIZARD);
      } else {
        this.isLoading = false;
        this.totp = null;
        this.showError = true;
        this.show2FaError = false;
        nextTick().then(() => {
          this.$refs.usernameInput.focus();
        });
      }
    },
    togglePasswordVisibility() {
      const pw = document.getElementById('password');
      if (pw.type === 'password') {
        pw.type = 'text';
        this.pwVisible = true;
      } else {
        pw.type = 'password';
        this.pwVisible = false;
      }
    },
    changeLocale(locale) {
      if (this.selectedLocale !== locale) {
        localStorage.setItem('lang', locale);
        this.$i18n.locale = locale;
      }
    },
    isNumeric(str) {
      return !isNaN(str) && !isNaN(parseInt(str, 10));
    },
    ...mapActions(
      useLoginStore, ["login", "clearSession", "check2fa"]
    ),
    ...mapActions(
      useMessagesStore, ["resetMessages", "clearMessages"]
    )
  },
  created() {
    if (!this.isLoggedIn) {
      this.clearSession();
      getActivePinia()._s.forEach(store => store.$reset());
    }
  },
  beforeMount() {
    document.body.classList.add('welcome-page', 'bg-white');
  },
  beforeUnmount() {
    document.body.classList.remove('welcome-page', 'bg-white');
  }
};
</script>

<style scoped lang="scss">
.alert {
  margin-top: .5em;
  padding: .5em 1em;
}

.bi {
  width: 1em;
  height: 1em;
  fill: currentcolor;
}

#password-row {
  position: relative;

  .password-icon {
    @include floating-button;
  }
}
</style>
