<template>
  <section-container id="404" class="p-4">
    <h1 class="mb-4">{{ $t('notFound.title') }}</h1>
    <p>{{ $t('notFound.text') }}</p>
    <p>
      <router-link to="/">{{ $t('notFound.start') }}</router-link>
    </p>
  </section-container>

  <section-container id="faq-info" class="p-0 mt-4">
    <FaqPanel class="m-0 border-0"/>
  </section-container>
</template>

<script>
import SectionContainer from "@/components/ui/SectionContainer";
import FaqPanel from "@/components/ui/FaqPanel.vue";

export default {
  components: {
    SectionContainer,
    FaqPanel,
  }
}
</script>
