<template>
  <HeaderMenu/>
  <main id="main-content" class="flex-shrink-0">
    <section-container id="app-section" v-if="isShowGlobalError && isLoggedIn">
      <GlobalMessage/>
    </section-container>
    <router-view/>
  </main>
  <MainFooter/>
  <MetaFooter/>
</template>

<script>
import {mapState} from "pinia";

import {useLoginStore} from "@/store/modules/login/login.js";
import {useMessagesStore} from "@/store/modules/messages/messages.js";


import HeaderMenu from "@/components/navigation/HeaderMenu.vue";
import MainFooter from "@/components/navigation/MainFooter";
import GlobalMessage from "@/components/navigation/GlobalMessage";
import SectionContainer from "@/components/ui/SectionContainer";
import MetaFooter from "@/components/navigation/MetaFooter.vue";

export default {
  name: "App",
  data() {
    return {
      toasts: [],
      errors: []
    }
  },
  computed: {
    ...mapState(useLoginStore, ["isLoggedIn", "isClientInitialized"]),
    ...mapState(useMessagesStore, ["isShowGlobalError", "isShowToasts", "getToasts"]),

  },
  components: {
    MetaFooter,
    HeaderMenu,
    MainFooter,
    GlobalMessage,
    SectionContainer
  },
  created() {
    document.title = this.$t('appTitle');
    document.documentElement.setAttribute('lang', this.$i18n.locale);
  }
};
</script>
