<template>
<div class="toast-container position-fixed bottom-0 end-0 p-3" v-for="(message, index) in toasts" :key="message.id">
  <div :id="'toast-'+index" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="10000">
    <div class="toast-header">
      <strong class="me-auto">{{message.summaryText}}</strong>
      <small>{{message.smallText}}</small>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      {{message.detailText}}
    </div>
  </div>
</div>
</template>

<script>

import {Toast} from 'bootstrap';
import { mapActions } from "pinia";
import { useMessagesStore } from "@/store/modules/messages/messages.js";

export default {
  props: {
    toasts: null
  },
  mounted() {
    for(let i=0; i<this.toasts.length; i++) {
      let id = "toast-" + i;
      const toastEL = document.getElementById(id);
      toastEL.addEventListener("hidden.bs.toast", () => {
        this.removeMessage(this.toasts[i]);
      });
      const toastToShow = new Toast(toastEL);
      toastToShow.show();
    }
  },
  methods: {
    ...mapActions(useMessagesStore, ["removeMessage"])
  }
}
</script>