<template>
  <div class="accordion mb-3 membership-card" :class="cardType" :id="cardType">
    <div class="accordion-item rounded-1 d-flex">
      <div class="icon p-2 align-self-stretch"><i class="bi" :class="icon"></i></div>
      <div class="accordion-inner w-100 ps-1">
        <h6 class="accordion-header collapsed"
            data-bs-toggle="collapse"
            :data-bs-target="'#' + cardType + 'Body'"
            aria-expanded="false"
            :aria-controls="cardType + 'Body'"
            :id="cardType + 'Title'">
          {{ cardTitle }}
          <i class="bi bi-plus-square float-end" v-if="!isOpen"></i>
          <i class="bi bi-dash-square float-end" v-if="isOpen"></i>
        </h6>
        <div :id="cardType + 'Body'" class="accordion-collapse collapse" :aria-labelledby="cardType + 'Title'">
          <div class="accordion-body p-2 pb-0">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isOpen: false,
    }
  },
  props: {
    cardType: String,
    icon: {
      type: String,
      required: false,
      default: 'bi-trophy'
    },
    cardTitle: {
      type: String,
      required: true,
    }
  },
  mounted() {
    const accordion = document.getElementById(this.cardType);
    accordion.addEventListener('show.bs.collapse', () => {
      this.isOpen = true;
    });
    accordion.addEventListener('hide.bs.collapse', () => {
      this.isOpen = false;
    });
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  &-item {
    > .icon {
      background-color: $secondary;
      color: $white;
    }
  }
}

.honorary {
  .accordion {
    &-item {
      > .icon {
        background: $background-gold;
        color: $white;
      }
    }
  }
}

.olympic {
  .accordion {
    &-item {
      > .icon {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

.brevet {
  .accordion {
    &-item {
      > .icon {
        background-color: $gray-dark;
        color: $white;
      }
    }
  }
}

.entrance {
  .accordion {
    &-item {
      > .icon {
        background-color: $gray;
      }
    }
  }
}

.insurance {
  .accordion {
    &-item {
      > .icon {
        background-color: rgba(36, 50, 53, 1);
      }
    }
  }
}
</style>
