<template>
  <profile-layout :title="memberData.firstName + ' ' + memberData.surname + ' / ' + $t('profile.password.edit')" :is-loading="isSubmitted">
    <template #content>
      <!-- altes Passwort -->
      <div class="row mb-4 align-items-end">
        <div class="col-12 col-lg-8">
          <div class="mb-2 form-floating">
            <input type="password" class="form-control" :class="{'is-invalid': showFailure}" id="password-old" aria-errormessage="password-old-error" autocomplete="new-password" :placeholder="$t('profile.password.currentPassword')" v-model="oldPassword" @keyup="analyzePassword" @keyup.enter="submitPassword" :feedback="false">
            <label for="password-current" class="form-label">{{ $t('profile.password.currentPassword') }}</label>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <ul class="list-unstyled">
            <li :class="{'text-success': oldPassword && oldPassword.length > 4}">{{ $t('profile.password.oldPassword') }}</li>
          </ul>
        </div>
      </div>
      <div class="row align-items-end">
        <div class="col-12 col-lg-8">
          <div class="mb-3 form-floating">
            <input type="password" class="form-control" :class="{'is-invalid': showFailure}" id="password-new" aria-errormessage="password-new-error" autocomplete="new-password" :placeholder="$t('profile.password.newPassword')" v-model="password" @keyup="analyzePassword" @keyup.enter="submitPassword" :feedback="false">
            <label for="password-new" class="form-label">{{ $t('profile.password.newPassword') }}</label>
          </div>
          <div class="mb-3 form-floating">
            <input type="password" class="form-control" :class="{'is-invalid': showFailure}" id="password-new-confirm" aria-errormessage="password-new-confirm-error" autocomplete="new-password" :placeholder="$t('profile.password.passwordConfirm')" v-model="passwordConfirm" @keyup="analyzePassword" @keyup.enter="submitPassword" :feedback="false">
            <label for="password-new-confirm" class="form-label">{{ $t('profile.password.passwordConfirm') }}</label>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <ul class="list-unstyled">
            <li :class="rulePasswordLength ? 'text-success' : password === null ? '' : 'text-danger'">{{ $t('profile.password.length') }}</li>
            <li :class="rulePasswordUpperLowerCase ? 'text-success' : password === null ? '' : 'text-danger'">{{ $t('profile.password.upperLowerCase') }}</li>
            <li :class="rulePasswordSpecialChars ? 'text-success' : password === null ? '' : 'text-danger'">{{ $t('profile.password.specialChars') }}</li>
            <li :class="rulePasswordMatch ? 'text-success' : password === null ? '' : 'text-danger'">{{ $t('profile.password.match') }}</li>
            <li :class="rulePreviousPasswordMatch ? 'text-success' : password === null ? '' : 'text-danger'">{{ $t('profile.password.matchOld') }}</li>
          </ul>
        </div>
      </div>


      <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 1000;">
        <div class="toast align-items-center text-white" :class="{'bg-success': showSuccess, 'bg-danger': showFailure}" id="toastMessage" role="alert" aria-live="assertive" aria-atomic="true">
          <div class="d-flex">
            <div class="toast-body" v-if="showSuccess">
              <i class="bi bi-hand-thumbs-up-fill me-2"></i>
              {{ $t('profile.password.success') }}
            </div>
            <div class="toast-body" v-if="showFailure">
              <i class="bi bi-hand-thumbs-down-fill me-2"></i>
              {{ $t('profile.password.failure') }}
            </div>
            <button class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
        </div>
      </div>
    </template>

    <div class="col-12 col-md-5 offset-md-3" v-if="!showSuccess">
      <DefaultButton
        :label="$t('profile.password.savePassword')"
        @click="submitPassword"
        @keyup.enter="submitPassword"
        class="btn-outline-dark"
        :disabled="!allRulesPassed || isSubmitted"
      />
    </div>
  </profile-layout>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {useMemberStore} from "@/store/modules/member/member.js";
import {useLoginStore} from "@/store/modules/login/login.js";
import ProfileLayout from "@/components/profile/ProfileLayout";
import {Toast} from "bootstrap";

export default {
  data() {
    return {
      oldPassword: null,
      password: null,
      passwordConfirm: null,
      ruleOldPassword: false,
      rulePasswordLength: false,
      rulePasswordMatch: false,
      rulePasswordSpecialChars: false,
      rulePasswordUpperLowerCase: false,
      rulePreviousPasswordMatch: false,
      showSuccess: false,
      showFailure: false,
      isSubmitted: false,
    };
  },
  computed: {
    allRulesPassed() {
      return (this.rulePasswordLength && this.rulePasswordSpecialChars && this.rulePasswordUpperLowerCase && this.rulePasswordMatch && this.rulePreviousPasswordMatch);
    },
    ...mapState(useMemberStore, {memberData: "getMemberData"})
  },
  methods: {
    async analyzePassword() {
      this.showFailure = false;
      this.showSuccess = false;

      this.ruleOldPassword = this.oldPassword !== null;

      if (this.password !== null) {
        this.rulePasswordLength = this.isPasswordLengthValid(this.password.trim(), 7);
        this.rulePasswordSpecialChars = this.containsSpecialChars(this.password.trim());
        this.rulePasswordUpperLowerCase = this.containsUpperAndLowerCase(this.password.trim());
        this.rulePasswordMatch = this.isMatchingPassword(this.password.trim(), this.passwordConfirm);
        this.rulePreviousPasswordMatch = this.oldPassword !== null && this.password.trim() !== this.oldPassword.trim();
      } else {
        this.rulePasswordLength = false;
      }
    },
    submitPassword() {
      this.analyzePassword();
      if (this.allRulesPassed) {
        this.isSubmitted = true;
        this.changePassword({
          "oldPassword": this.oldPassword,
          "newPassword": this.password,
          "newPasswordRepetition": this.passwordConfirm,
          "stvMemberId": this.memberData.memberId
        }).then(response => {
          if (response && response.status === 200) {
            this.showSuccess = true;
          } else {
            this.showFailure = true;
          }
        }).catch(error => {
          console.log(error);
          this.showFailure = true;
        }).finally(() => {
          const toastMessage = document.getElementById('toastMessage');
          const toast = new Toast(toastMessage);
          toast.show();
          this.isSubmitted = false;
        });
      }
    },
    isPasswordLengthValid(password, length) {
      return password.trim().length >= length;
    },
    isMatchingPassword(password, passwordConfirm) {
      return !(passwordConfirm === null || password !== passwordConfirm.trim());

    },
    containsSpecialChars(str) {
      const specialChars = /[^\w\s]|[_]/gi;
      return specialChars.test(str);

    },
    containsUpperAndLowerCase(str) {
      let containsUpperCase, containsLowerCase = false;

      for (var i = 0; i < str.length; i++) {
        if (str.charAt(i) === str.charAt(i).toUpperCase() && str.charAt(i) != str.charAt(i).toLowerCase() && !containsUpperCase) {
          containsUpperCase = true;
        } else if (str.charAt(i) === str.charAt(i).toLowerCase() && str.charAt(i) != str.charAt(i).toUpperCase() && !containsLowerCase) {
          containsLowerCase = true;
        }
      }
      return containsUpperCase && containsLowerCase;
    },
    ...mapActions(useLoginStore, ["changePassword"])
  },
  components: {
    ProfileLayout
  }
}
</script>

<style scoped lang="scss">
.alert {
  margin-top: .5em;
  padding: .5em 1em;
}

.alert.alert-success {
  color: #4E824E;
  background-color: #E3FFE3;
  border-color: #99D199;
}

.bi {
  width: 1em;
  height: 1em;
  fill: currentcolor;
  margin-right: .5em
}
</style>

