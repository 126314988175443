<template>
  <div class="square-button" @click="$emit('click', $event)" v-bind="$attrs">
    <slot/>
  </div>
</template>

<style lang="scss" scoped>
.square-button {
  background: rgba($black, .4);
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: background 500ms;

  &:hover {
    background: $black;
  }
}
</style>
