<template>
  <div class="row py-4" :class="additionalClasses" :id="tableName">
    <div class="col">
      <h5>{{ title }}</h5>
      <div class="alert alert-info d-flex align-items start mt-3" v-if="showInfo">
        <i class="bi bi-info-circle me-3" style="font-size: 1.5rem; line-height: 1;"></i>
        <p>{{ infoText }}</p>
      </div>
      <div class="alert alert-info mt-3 mb-0" v-if="isEmpty">{{ emptyMessage }}</div>
      <div class="table-responsive text-nowrap" v-if="!isEmpty">
        <slot name="membership-table"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableName: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    emptyMessage: {
      type: String,
      required: true
    },
    isEmpty: {
      type: Boolean,
      required: true
    },
    additionalClasses: {
      type: String,
      required: false,
    },
    showInfo: {
      type: Boolean,
      required: false,
      default: false
    },
    infoText: {
      type: String,
      required: false,
      defaults: '',
    }
  }
}
</script>
