<template>
  <button class="btn"><i class="me-2" :class="icon" v-if="icon"></i>{{ label }}</button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: false,
    },
    label: String,
  },
}
</script>
