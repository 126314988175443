<template>
  <ul :id="listId" :class="listClasses">
    <li class="list-inline-item">
      <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75">
        <use xlink:href="@/assets/img/stv-logos-grey.svg#coop"/>
      </svg>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    listClasses: {
      type: String,
      required: false,
      default: 'list-inline',
    },
    listId: {
      type: String,
      required: false,
    }
  }
}
</script>
