<template>
  <profile-layout :title="memberData !== undefined ? memberData.firstName + ' ' + memberData.surname + ' / ' + $t('profile.email.confirmEmail') : $t('profile.email.confirmEmail')" :is-loading="isLoading">
    <template #content>
      <div class="row" v-if="!tokenValid">
        <div class="col">
          <div class="alert alert-danger d-flex align-items-start" role="alert">
            <i class="bi bi-envelope me-2"></i>
            {{ $t('profile.email.tokenInvalid') }}
          </div>
        </div>
      </div>
      <div class="row" v-else-if="showSuccess">
        <div class="col">
          <div class="alert alert-success d-flex align-items-start" role="alert">
            <i class="bi bi-envelope me-2"></i>
            {{ $t('profile.email.changeSuccess') }}
          </div>
        </div>
      </div>
      <div class="row" v-else-if="showFailure">
        <div class="col">
          <div class="alert alert-danger d-flex align-items-start" role="alert">
            <i class="bi bi-exclamation-circle-fill me-3" style="font-size: 1.5rem; line-height: 1;"></i>
            {{ $t('profile.email.changeFailure') }}
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col">
          <div class="alert alert-dark d-flex align-items-start" role="alert">
            <i class="bi bi-watch me-3" style="font-size: 1.5rem; line-height: 1;"></i>
            {{ $t('profile.email.changePending') }}
          </div>
        </div>
      </div>
      <div class="alert alert-dark mt-3">
        <i class="bi bi-info-circle me-3" style="font-size: 1.5rem; line-height: 1;"></i>
        <a :href="$t('links.faq')" target="_blank">{{ $t('faq.infoText') }}</a>
      </div>
    </template>
  </profile-layout>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {useRoute} from "vue-router";

import ProfileLayout from "@/components/profile/ProfileLayout";
import {useUserStore} from "@/store/modules/user/user";

export default {
  data() {
    return {
      tokenValid: false,
      showSuccess: false,
      showFailure: false,
      isLoading: true,
    }
  },
  methods: {
    ...mapActions(useUserStore, ["validateEmailChange", "setNewEmail"])
  },
  computed: {
    ...mapState(useUserStore, {memberData: "getMemberData"})
  },
  components: {
    ProfileLayout
  },
  mounted() {
    const route = useRoute();
    this.validateEmailChange(route.query).then(response => {
      this.tokenValid = !!(response && response.status === 200);
    }).catch(error => {
      this.showFailure = true;
      console.log(error);
    }).finally(() => {
      if (this.tokenValid) {
        this.setNewEmail({
          token: route.query.token,
        }).then(response => {
          if (response && response.status === 200) {
            this.showSuccess = true;
          } else {
            this.showFailure = true;
          }
        }).catch(error => {
          this.showFailure = true;
          console.log(error);
        }).finally(() => {
          this.isLoading = false;
        });
      }
    });
  },
}
</script>
