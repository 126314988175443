<template>
  <meta-navigation></meta-navigation>
  <MemberSection :member-data="currentMemberData" is-single-member card-title="Mitgliederkarte" :is-emulating-member="false" :is-club-member="false"/>
  <LoaderOverlay v-if="isLoading"/>
</template>

<script>
import {mapActions} from "pinia";
import {useMemberStore} from "@/store/modules/member/member.js";


import MetaNavigation from "@/components/navigation/MetaNavigation.vue";
import MemberSection from "@/components/personal-data/MemberSection.vue";
import LoaderOverlay from "@/components/ui/LoaderOverlay.vue";

export default {
  components: {
    LoaderOverlay,
    MetaNavigation,
    MemberSection,
  },
  data() {
    return {
      currentMemberData: {},
    };
  },
  computed: {
    isLoading() {
      return Object.keys(this.currentMemberData).length === 0;
    },
  },
  methods: {
    ...mapActions(
      useMemberStore, ["loadSingleMemberData", "resetSingleMemberData"]
    )
  },
  beforeMount() {
    this.resetSingleMemberData().then(async () => {
      await this.loadSingleMemberData(this.$route.query.memberId).then(r => {
        this.currentMemberData = r.stvMember;
      });
    });
  },
};
</script>

