<template>
    <div class="termsAndConditions" v-if="$i18n.locale === 'de'">
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;text-align:justify;line-height:18.0pt;font-size:16px;font-family:"Arial",sans-serif;font-weight:bold;'>Nutzungsbedingungen f&uuml;r mySTV-FSG&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Diese Nutzungsbedingungen (die &quot;<strong>Nutzungsbedingungen</strong>&quot;) zusammen mit unserer Datenschutzerkl&auml;rung (die &quot;<strong>Datenschutzerkl&auml;rung</strong>&quot;), gelten f&uuml;r jede Nutzung der Mitgliederplattform mySTV-FSG&nbsp;des Schweizerischer Turnvereins (&quot;<strong>STV</strong>&quot;) inklusive aller Daten, Informationen, Werkzeuge und Dienstleistungen, die auf Mitgliederplattform (nachfolgend &quot;<strong>Plattform</strong>&quot;) verf&uuml;gbar sind.&nbsp;Gegenstand dieser Nutzungsbedingungen ist die Nutzung und der Zugang zur Mitgliederplattform mySTV-FSG als registrierte Nutzerin oder als registrierter Nutzer (nachfolgend &bdquo;<strong>Nutzer</strong>&ldquo;).</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'><strong>Sie d&uuml;rfen die Plattform nur unter der Bedingung nutzen, dass Sie die Nutzungsbedingungen und die Datenschutzerkl&auml;rung verstehen und akzeptieren. Jede weitere Nutzung der Plattform oder eines Teils davon bedeutet, dass Sie die Nutzungsbedingungen und die Datenschutzerkl&auml;rung gelesen und verstanden haben und sich mit allen Teilen der Nutzungsbedingungen einverstanden erkl&auml;ren.</strong></p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">1. Anwendung dieser Nutzungsbedingungen</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Diese Nutzungsbedingungen gelten f&uuml;r Ihre Nutzung der Plattform oder Teilen davon.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Die auf der der Plattform ver&ouml;ffentlichten Informationen stellen weder eine Empfehlung noch ein Angebot oder eine Aufforderung zum Abschluss eines Rechtsgesch&auml;ftes dar.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">2. Haftung</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Die Inhalte der Plattform wurden mit Sorgfalt erstellt, jedoch kann der STV nicht f&uuml;r die Vollst&auml;ndigkeit und/oder Korrektheit garantieren. Die Plattform stellt Informationen zum STV und den angebotenen Dienstleistungen, die jederzeit und ohne vorherige Ank&uuml;ndigung ge&auml;ndert werden k&ouml;nnen, zur Verf&uuml;gung.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Alle Zusicherungen und Gew&auml;hrleistungen sind ausgeschlossen, einschliesslich der Gew&auml;hrleistung hinsichtlich der Eignung f&uuml;r einen bestimmten Zweck.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'><strong>Weder der STV noch seine Mitgliederverb&auml;nde, lokale Turnvereine, Partner, Auftragnehmer oder Lizenzgeber haften f&uuml;r Verluste, Verletzungen oder Sch&auml;den</strong> (einschliesslich, aber nicht beschr&auml;nkt auf direkte, indirekte, strafbare oder Folgesch&auml;den, Verlust von Einkommen, Gewinn, Goodwill, Daten, Vertr&auml;ge, Geldverwendung oder Verluste oder Sch&auml;den, die sich aus einer Gesch&auml;ftsunterbrechung ergeben oder in irgendeiner Weise damit verbunden sind). Dies ist unabh&auml;ngig davon, ob diese Sch&auml;den vorhersehbar, bekannt oder anderweitig erkennbar waren oder auf die M&ouml;glichkeit solcher Sch&auml;den hingewiesen wurde. Der STV haftet nicht aus unerlaubter Handlung (einschliesslich f&uuml;r und ohne Einschr&auml;nkung der Fahrl&auml;ssigkeit), Vertrag oder anderweitig. Ferner haftet der STV nicht und ohne Einschr&auml;nkung f&uuml;r Eventualit&auml;ten, die ausserhalb ihrer Kontrolle bei der Beschaffung, Zusammenstellung, Interpretation, Berichterstattung oder Lieferung von Daten liegen, und f&uuml;r Schlussfolgerungen, Entscheidungen oder Handlungen, die aus der Nutzung der Plattform oder eines Teils davon abgeleitet, getroffen oder unternommen wurden.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Nichts in diesen Nutzungsbedingungen schliesst oder beschr&auml;nkt die Haftung von STV (i) f&uuml;r Betrug oder grobe Fahrl&auml;ssigkeit, (ii) f&uuml;r Tod oder K&ouml;rperverletzung, die von STV verursacht wurden, oder (iii) die nach geltendem Recht nicht ausgeschlossen oder beschr&auml;nkt werden k&ouml;nnen.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'><strong>Sie stimmen zu, dass Sie den STV und seine Mitgliederverb&auml;nde, lokale Turnvereine, Partner, Auftragnehmer oder Lizenzgeber sowie die jeweiligen Gesch&auml;ftsleitungsmitglieder, leitenden Angestellten, Mitarbeiter, Vertreter und unabh&auml;ngigen Auftragnehmer verteidigen, entsch&auml;digen und schadlos halten von und gegen alle Sch&auml;den, Anspr&uuml;che und Ausgaben, einschliesslich angemessener Anwaltskosten, die aus oder in Verbindung mit Ihrem Missbrauch der Plattform entstehen, d.h. einer Nutzung der Plattform, die gegen diese Nutzungsbedingungen oder geltendes Recht oder andere Vorschriften verst&ouml;sst.</strong></p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">3. Freistellungsanspruch</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Der Nutzer stellt STV und dessen Mitarbeiter bzw. Beauftragten f&uuml;r den Fall der Inanspruchnahme wegen vermeintlicher oder tats&auml;chlicher Rechtsverletzungen und/oder Verletzungen von Rechten Dritter durch vom Nutzer im Zusammenhang mit der Nutzung der Website oder der Plattform vorgenommenen Handlung und/oder die vom Nutzer eingegebenen Inhalte von s&auml;mtlichen sich daraus ergebenden Anspr&uuml;chen Dritter frei.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Der Nutzer verpflichtet sich, alle etwaigen Kosten, die dem STV durch die Inanspruchnahme Dritter entstehen, zu ersetzen. Zu den erstattungsf&auml;higen Kosten z&auml;hlen auch die Kosten einer angemessenen Rechtsverfolgung und Rechtsverteidigung, die der STV zur Abwehr von Anspr&uuml;chen Dritter entstehen sollten. STV informiert in diesem Fall den betroffenen Nutzer unverz&uuml;glich &uuml;ber vorzunehmende Massnahmen der Rechtsverteidigung.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">4. Webseiten Dritter (Links)</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Bestimmte Links auf der Plattform f&uuml;hren zu Webseiten und/oder Diensten Dritter. Der STV hat keinen Einfluss auf diese Webseiten und &uuml;bernimmt keine Haftung f&uuml;r die Richtigkeit, Vollst&auml;ndigkeit und Rechtm&auml;ssigkeit der Inhalte dieser Webseiten sowie f&uuml;r die dort angebotenen Produkte und Dienstleistungen.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">5. Immaterialg&uuml;terrechte, vertrauliche Informationen und Gesch&auml;ftsgeheimnisse</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Sie anerkennen und akzeptieren, dass die die Plattform und alle Teile davon urheberrechtlich oder durch andere Bestimmungen zum Schutz des geistigen Eigentums oder gegen unlauteren Wettbewerb gesch&uuml;tzt sein k&ouml;nnen und Eigentum des STV und/oder ihren Lizenzgebern sind und bleiben. Solche Inhalte d&uuml;rfen nur in &Uuml;bereinstimmung mit diesen Nutzungsbedingungen, oder gem&auml;ss einer abweichenden Vereinbarung zwischen Ihnen und STV, sofern anwendbar, und im Einklang mit allen anwendbaren Gesetzen und Vorschriften verwendet werden. Jede unbefugte Nutzung ist strengstens untersagt.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Alle Markennamen, Warenzeichen, Dienstleistungsmarken sowie andere Produkt- und Dienstleistungsnamen und Logos, ob registriert oder nicht registriert, auf der Website und der Plattform sind Eigentum ihrer jeweiligen Inhaber und durch das anwendbare Recht gesch&uuml;tzt.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Das vollst&auml;ndige oder teilweise Vervielf&auml;ltigen, Verbreiten, Modifizieren, Verkn&uuml;pfen oder Benutzen f&uuml;r kommerzielle oder &ouml;ffentliche Zwecke und jede Art der Verwertung ausserhalb der Grenzen des Urheberrechtsgesetzes bedarf der vorherigen schriftlichen Zustimmung von STV. Durch diese Plattform wird keine Lizenz zur Nutzung des geistigen Eigentums Dritter erteilt. Alle nicht ausdr&uuml;cklich in diesen Bedingungen gew&auml;hrten Rechte bleiben vorbehalten.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Es ist gestattet, die Information &uuml;ber das Internet abzurufen sowie zum pers&ouml;nlichen Gebrauch Kopien auf Papier oder auf anderen Datentr&auml;gern anzufertigen.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Es ist gestattet, unver&auml;nderte Kopien der Information auf einem Proxy-HTTP-Server zu halten, wenn daf&uuml;r gesorgt ist, dass &Auml;nderungen und L&ouml;schungen der Originaldaten auf dem stv-fsg.ch-Server in angemessener Zeit nachvollzogen werden.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Es ist gestattet, &uuml;ber den Inhalt der Informationsseiten Verzeichnisse in Datenbanken anzulegen, soweit der Zweck einer solchen Datensammlung das vereinfachte Aufsuchen der Sachinformation ist (Suchmaschinen, Verzeichnisse usw.).</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">6. Vertraulichkeit und Datenschutz</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Soweit auf der Website oder der Plattform personenbezogene Daten verarbeitet werden, ist betreffend den Umgang mit diesen Daten auf die Datenschutzerkl&auml;rung zu verweisen.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">7. Spezifische Bestimmungen zur Nutzung der Plattform</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Mit der Plattform mySTV-FSG stellt der STV den&nbsp;STV-Mitgliedern, Mitglieder der Ehrengarde des STV (Ehrenmitglieder, Ehrenauszeichnung) und Funktion&auml;ren des STV sowie seiner Mitglieder-/Kantonalverb&auml;nde&nbsp;eine digitale Mitglieder- und Kundenplattform zur Verf&uuml;gung. &Uuml;ber die Plattform wird den Nutzern die Mitglieder- und Funktion&auml;rskarte zuganglich gemacht. Zudem k&ouml;nnen registrierte Nutzer in diesem Zusammenhang auch gewisse pers&ouml;nliche Daten (z.B. Adresse, Name, Geburtsdatum, E-Mail-Adresse, Telefonnummer, Art der betriebenen Sportart) selbst mutieren. Die Nutzung der Plattform ist vorgenannter Mitgliedskategorien zugeh&ouml;rigen Personen vorbehalten.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Mit der Registrierung und Nutzung der Plattform erkl&auml;rt Sie mit den vorliegenden Nutzungsbedingungen in der jeweilig aktuellen Fassung einverstanden.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">7.1 Rechte und Pflichten des STV</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Wir bem&uuml;hen uns, die Plattform kontinuierlich zu verbessern und Ihren Bed&uuml;rfnissen und Erfordernissen anzupassen. Wir behalten uns jedoch das Recht vor, Funktionen der Plattform jederzeit und ohne vorherige Ank&uuml;ndigung zu &auml;ndern oder einzustellen. Wir werden versuchen, Sie von jeder &Auml;nderung oder Einstellung von Dienstleistungen durch Aufschalten einer entsprechenden Mitteilung auf der Plattform zu benachrichtigen.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Die Plattform einschliesslich aller darauf enthaltenen Inhalte, werden ohne M&auml;ngelgew&auml;hr zur Verf&uuml;gung gestellt. Wir schliessen hiermit alle Zusicherungen und Gew&auml;hrleistungen (insbesondere betreffend Sach- und Rechtsm&auml;ngel) aus. Wir sichern weder zu noch gew&auml;hrleisten wir, dass die auf bzw. &uuml;ber die Plattform (von uns oder den Nutzern) zur Verf&uuml;gung gestellten Informationen und Inhalte vollst&auml;ndig oder fehlerfrei sind. Nutzer erkennen an, dass das Herunterladen oder Einholen von Informationen und Inhalten von der Plattform nach eigenem Ermessen und auf eigenes Risiko erfolgt.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Der STV ist bestrebt, die Plattform stets verf&uuml;gbar zu halten. Die Plattform oder Teile unserer Dienstleistungen k&ouml;nnen jedoch zeitweilig aus verschiedenen Gr&uuml;nden nicht verf&uuml;gbar sein.&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Wartungsarbeiten mit Einfluss auf den Betrieb der Plattform werden soweit m&ouml;glich zu Randzeiten ausgef&uuml;hrt und nach M&ouml;glichkeit per E-Mail, &uuml;ber eine Statusseite oder auf andere geeignete Weise angek&uuml;ndigt.&nbsp;</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">7.2 Account-Er&ouml;ffnung und Schliessung</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Voraussetzung f&uuml;r die Nutzung der Plattform ist die Er&ouml;ffnung eines mySTV-FSG-Accounts. Die M&ouml;glichkeit einen Account zu er&ouml;ffnen, bedingt, dass der lokale Turnverein in der STV-Mitgliederdatenbank f&uuml;r die jeweilige Person eine E-Mail-Adresse hinterlegt hat. Alle berechtigten Personen erhalten eine E-Mail zugeschickt mit der M&ouml;glichkeit den pers&ouml;nlichen mySTV-FSG-Account zu aktivieren und ein Passwort zu hinterlegen. Berechtigte Personen haben zudem die M&ouml;glichkeit durch Angabe der E-Mail-Adresse oder Mitglieder-Nummer direkt auf der Plattform eine automatisierte E-Mail, mit einem Link zur Aktivierung eines mySTV-FSG-Accounts, zu generieren.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Nutzer k&ouml;nnen ihren mySTV-FSG-Account jederzeit abmelden bzw. l&ouml;schen lassen. Dazu kann ein Antrag auf Schliessung des mySTV-FSG-Account an <a href="mailto:mystv@stv-fsg.ch">mystv@stv-fsg.ch</a> gerichtet werden. Der Antrag wird innert angemessener Frist durch STV bearbeitet.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Nach erfolgter Schliessung hat der Nutzer weder Zugang zur Mitgliederplattform noch zu Daten, Informationen, Werkzeugen und Dienstleistungen, die auf Mitgliederplattform verf&uuml;gbar sind.&nbsp;</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">7.3 Rechte und Pflichten von Nutzern</span></h1>
        </div>
        <h1 style='margin-top:6.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="font-size:13px;line-height:115%;">Vollst&auml;ndige und aktuelle Angaben</span></h1>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Der Nutzer verpflichtet sich, alle Angaben zu seiner Person aktuell, genau und vollst&auml;ndig zu machen.</p>
        <p style='margin-top:0cm;margin-right:35.4pt;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Zudem verpflichtet er sich zur unverz&uuml;glichen Aktualisierung aller f&uuml;r die Nutzung der Plattform massgeblichen Angaben.</p>
        <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="font-size:13px;line-height:115%;">Verhaltensnormen</span></h1>
        <p style='margin-top:0cm;margin-right:35.4pt;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Sie sichern zu und gew&auml;hrleisten, dass Sie nicht gegen das anwendbare Schweizer Recht verstossen und insbesondere keine Nutzerinhalte irgendwelcher Art auf der Plattform und verf&uuml;gbar machen, die Rechte Dritter verletzen oder rechtswidriges Material enthalten. Insbesondere verpflichten Sie sich, die Plattform nicht f&uuml;r die folgenden Aktivit&auml;ten zu nutzen:</p>
        <ul style="list-style-type: disc;margin-left:25.700000000000003px;">
            <li style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px;">Ableiten oder Sammeln von E-Mail-Adressen oder sonstigen Kontaktinformationen anderer Personen von der Plattform durch elektronische oder andere Mittel zwecks Versendung unaufgeforderter Emails oder sonstiger unaufgeforderter Kommunikationen;</span></li>
            <li style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px;">Verwenden automatisierter Scripts zum Sammeln von Informationen von der Plattform oder der sonstigen Interaktion mit der Plattform;</span></li>
            <li style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px;">das Heraufladen, Aufschalten, &Uuml;bertragen, Zurverf&uuml;gungstellung oder Speichern von</span></li>
        </ul>
        <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
            <ul style="margin-bottom:0cm;margin-top:0cm;" type="circle">
                <li style='margin-top:0cm;margin-right:31.1pt;margin-bottom:6.0pt;margin-left:0cm;text-align:left;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Inhalten, die wir nach unserem Ermessen f&uuml;r sch&auml;digend, bedrohlich, rechtsverletzend, missbr&auml;uchlich, bel&auml;stigend, obsz&ouml;n, betr&uuml;gerisch, in die Datenschutz- oder Pers&ouml;nlichkeitsrechte eingreifend oder f&uuml;r rassistisch oder anderweitig anst&ouml;ssig oder rechtswidrig halten,</li>
                <li style='margin-top:0cm;margin-right:31.1pt;margin-bottom:6.0pt;margin-left:0cm;text-align:left;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>unaufgeforderter oder nicht autorisierter Werbung, Spam und dergleichen,</li>
                <li style='margin-top:0cm;margin-right:31.1pt;margin-bottom:6.0pt;margin-left:0cm;text-align:left;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Inhalten, die einen Straftatbestand begr&uuml;nden w&uuml;rden, andere Personen zur Begehung einer Straftat auffordern oder solchen Personen Anweisungen zur Begehung einer Straftat erteilen, bzw. Inhalten, die Rechte Dritter verletzen oder die anderweitig eine Haftung begr&uuml;nden oder gegen nationales oder internationales Recht verstossen w&uuml;rden;</li>
            </ul>
        </ul>
        <ul style="list-style-type: disc;margin-left:25.700000000000003px;">
            <li style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px;">Aufschalten von Inhalten f&uuml;r eine andere Person als f&uuml;r sich selbst oder das Sich Ausgeben f&uuml;r eine andere nat&uuml;rliche oder juristische Person oder die falsche Darstellung Ihrer Person, die falsche Angabe Ihres Alters oder Ihrer Zugeh&ouml;rigkeit zu einer anderen nat&uuml;rlichen oder juristischen Person;</span></li>
            <li style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px;">Verlangen von Ausk&uuml;nften &uuml;ber Kennworte oder personenbezogene Angaben f&uuml;r kommerzielle oder rechtswidrige Zwecke.</span></li>
        </ul>
        <p style='margin-top:0cm;margin-right:35.4pt;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Sie sind verpflichtet, mit Ihrem Nutzerkonto auf der Plattform sorgf&auml;ltig umzugehen und insbesondere die Verwendung durch Unbefugte zu verhindern.&nbsp;Insbesondere haben Sie ein sicheres Passwort zu w&auml;hlen und dieses vertraulich zu behandeln. Im Verh&auml;ltnis zum STV gelten alle Handlungen, die unter Verwendung eines Nutzerkontos erfolgen, als Handlungen des entsprechenden registrierten Nutzers.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">7.4 Verstoss gegen Nutzungsbedingungen</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:35.4pt;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Beim Verstoss des Nutzers gegen die vorliegenden Nutzungsbedingungen kann der STV die folgenden Sanktionen gegen die betroffenen Nutzer aussprechen:</p>
        <ul style="list-style-type: disc;margin-left:25.700000000000003px;">
            <li style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px;">Ermahnung zur Rechts- und vertragskonformen Nutzung,</span></li>
            <li style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px;">L&ouml;schung oder Ab&auml;nderung von Inhalten, die der Nutzer eingestellt hat,</span></li>
            <li style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px;">Zugang zur Plattform ohne Vorank&uuml;ndigung entsch&auml;digungslos &auml;ndern, einschr&auml;nken oder sperren</span></li>
            <li style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px;">gegebenenfalls Schadenersatz sowie die Schadloshaltung von Anspr&uuml;chen Dritter verlangen.</span></li>
        </ul>
        <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">8. &Auml;nderungen</span></h1>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Wir behalten uns vor, jederzeit und ohne vorherige Ank&uuml;ndigung, Verbesserungen oder &Auml;nderungen an diesen Nutzungsbedingungen und dem Inhalt dieser Plattform vorzunehmen. Diese Nutzungsbedingungen wurden zuletzt am 28. Februar 2023 ge&auml;ndert.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:10.0pt;">9. Schlussbestimmungen</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Sollte eine Bestimmung dieser Nutzungsbedingungen f&uuml;r ung&uuml;ltig oder nicht durchsetzbar erkl&auml;rt werden, bleiben die &uuml;brigen Bestimmungen weiterhin in vollem Umfang g&uuml;ltig und wirksam. Wenn Sie glauben, dass Sie andere oder zus&auml;tzliche Rechte als die in diesen Nutzungsbedingungen genannten nach zwingend anwendbarem Recht haben, bitten wir Sie uns unverz&uuml;glich zu informieren, damit wir eine alternative L&ouml;sung bereitstellen k&ouml;nnen.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Unser Vers&auml;umnis, die strikte Einhaltung einer dieser Nutzungsbedingungen durchzusetzen, ist nicht als Verzicht auf bestimmte Rechte und/ oder Pflichten zu werten.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">10. Anwendbares Recht und Gerichtsstand</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Diese Nutzungsbedingungen unterstehen dem materiellen Recht der Schweiz, sofern nicht das Recht eines anderen Landes zwingend Anwendung findet.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Gerichtsstand f&uuml;r alle Streitigkeiten aus und in Zusammenhang mit dieser Plattform ist am Sitz des STV, sofern nicht ein anderer Gerichtsstand zwingend vorgeschrieben ist.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">11. Kontaktieren Sie uns</span></h1>
        </div><p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Sollten Sie Kommentare oder Fragen zu diesen Nutzungsbedingungen oder unserer Plattform haben, kontaktieren Sie uns bitte per E-Mail unter <a href="mailto:mystv@stv-fsg.ch">mystv@stv-fsg.ch</a>.</p>
    </div>
    <div class="termsAndConditions" v-else>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;text-align:justify;line-height:18.0pt;font-size:16px;font-family:"Arial",sans-serif;font-weight:bold;'>Conditions d&rsquo;utilisation pour mySTV-FSG&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Les pr&eacute;sentes conditions d&rsquo;utilisation (les &laquo; <strong>conditions d&rsquo;utilisation</strong> &raquo;) ainsi que notre d&eacute;claration de protection des donn&eacute;es (la &laquo; <strong>d&eacute;claration de protection des donn&eacute;es</strong> &raquo;), s&rsquo;appliquent &agrave; toute utilisation de la plateforme des membres mySTV-FSG de la F&eacute;d&eacute;ration suisse de gymnastique (&laquo; <strong>FSG</strong> &raquo;) y compris de toutes les donn&eacute;es, toutes les informations, tous les outils et tous les services disponibles sur la plateforme des membres (ci-apr&egrave;s &laquo; <strong>plateforme</strong> &raquo;). Le but de ces conditions d&apos;utilisation est l&apos;utilisation et l&apos;acc&egrave;s &agrave; la plateforme des membres mySTV-FSG en tant qu&apos;utilisatrice ou utilisateur enregistr&eacute; (ci-apr&egrave;s &laquo; <strong>utilisateur</strong> &raquo;).</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'><strong>Vous ne pouvez utiliser la plateforme qu&rsquo;&agrave; condition de comprendre et d&rsquo;accepter les conditions d&rsquo;utilisation et la d&eacute;claration de protection des donn&eacute;es. Toute autre utilisation de la plateforme ou d&rsquo;une partie de celle-ci signifie que vous avez lu et compris les conditions d&rsquo;utilisation et la d&eacute;claration de protection des donn&eacute;es et que vous acceptez toutes les parties des conditions d&apos;utilisation.</strong></p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">1. Application des pr&eacute;sentes conditions d&rsquo;utilisation&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Les pr&eacute;sentes conditions d&rsquo;utilisation s&rsquo;appliquent &agrave; votre utilisation de la plateforme ou des parties de celle-ci.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Les informations publi&eacute;es sur la plateforme ne constituent ni une recommandation, ni une offre ou une invitation &agrave; conclure un acte juridique.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">2. Responsabilit&eacute;&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Les contenus de la plateforme ont &eacute;t&eacute; soigneusement &eacute;labor&eacute;s, la FSG ne peut toutefois pas en garantir l&rsquo;exhaustivit&eacute; et/ou l&rsquo;exactitude. La plateforme met &agrave; disposition des informations sur la FSG et les services propos&eacute;s qui peuvent &ecirc;tre modifi&eacute;s en tout temps et sans pr&eacute;avis.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Toutes les assurances et garanties sont exclues, y compris la s&eacute;curit&eacute; concernant l&rsquo;aptitude &agrave; une fin particuli&egrave;re.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'><strong>Ni la FSG, ni ses associations membres, soci&eacute;t&eacute;s de gymnastique locales, partenaires, contractants ou donneurs de licence ne sont responsables des pertes, blessures ou dommages&nbsp;</strong>(y compris mais sans s&rsquo;y limiter, les dommages directs, indirects, punitifs ou cons&eacute;cutifs, les pertes de revenus, de b&eacute;n&eacute;fices, de goodwill, de donn&eacute;es, de contrats, d&apos;utilisation d&apos;argent ou les pertes ou dommages r&eacute;sultant ou li&eacute;s de quelque mani&egrave;re que ce soit &agrave; une interruption d&apos;activit&eacute;). Ceci est ind&eacute;pendant du fait que ces dommages aient &eacute;t&eacute; pr&eacute;visibles, connus ou reconnaissables d&apos;une autre mani&egrave;re ou que la possibilit&eacute; de tels dommages ait &eacute;t&eacute; signal&eacute;e. La FSG n&apos;est pas responsable sur la base d&apos;un acte illicite (y compris pour et sans restriction de la n&eacute;gligence), d&apos;un contrat ou autre. En outre, la FSG n&apos;est pas responsable, sans restriction d&rsquo;&eacute;ventualit&eacute;s &eacute;chappant &agrave; son contr&ocirc;le lors de l&rsquo;acquisition, de la compilation, de l&apos;interpr&eacute;tation, du rapport ou de la livraison de donn&eacute;es, ni des conclusions, d&eacute;cisions ou actions, prises ou entreprises suite &agrave; l&apos;utilisation de la plateforme ou d&apos;une partie de celle-ci.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Rien dans les pr&eacute;sentes conditions d&apos;utilisation n&apos;exclut ou ne limite la responsabilit&eacute; de FSG (i) en cas de fraude ou de n&eacute;gligence grave, (ii) en cas de d&eacute;c&egrave;s ou de dommages corporels caus&eacute;s par la FSG, ou (iii) qui ne peuvent &ecirc;tre exclus ou limit&eacute;s en vertu de la loi applicable.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'><strong>Vous acceptez de d&eacute;fendre, d&rsquo;indemniser et de d&eacute;dommager la FSG et ses associations membres, soci&eacute;t&eacute;s de gymnastique locales, partenaires, contractants ou conc&eacute;dants de licence ainsi que les membres de la direction respective, les cadres, les collaborateurs, les repr&eacute;sentants et les entrepreneurs ind&eacute;pendants de et contre tous les dommages, r&eacute;clamations et d&eacute;penses y compris les frais d&apos;avocat raisonnables, d&eacute;coulant de ou en rapport avec votre utilisation abusive de la plateforme, c&apos;est-&agrave;-dire une utilisation de la plateforme qui enfreint les pr&eacute;sentes conditions d&apos;utilisation ou la loi applicable ou d&rsquo;autres prescriptions.</strong></p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">3. Droit d&rsquo;exemption&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>L&rsquo;utilisateur lib&egrave;re la FSG et ses collaborateurs ou responsables de toutes les pr&eacute;tentions de tiers en cas de recours pour violation pr&eacute;sum&eacute;e ou r&eacute;elle de la loi et/ou des droits de tiers par des actions entreprises par l&apos;utilisateur dans le cadre de l&apos;utilisation du site web ou de la plateforme et/ou des contenus saisis par l&apos;utilisateur.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>L&rsquo;utilisateur s&rsquo;engage &agrave; rembourser tous les &eacute;ventuels frais occasionn&eacute;s pour la FSG par les pr&eacute;tentions de tiers. Les frais remboursables comprennent &eacute;galement les frais de poursuite et de d&eacute;fense juridiques raisonnables que la FSG devrait engager pour se d&eacute;fendre contre les pr&eacute;tentions de tiers. Dans ce cas, la FSG informe imm&eacute;diatement l&apos;utilisateur concern&eacute; des mesures de d&eacute;fense juridique &agrave; prendre.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">4. Sites web de tiers (liens)</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Certains liens sur la plateforme conduisent vers des sites web et/ou services de tiers. La FSG n&rsquo;a aucune influence sur ces sites web et n&rsquo;assume aucune responsabilit&eacute; quant &agrave; l&apos;exactitude, l&apos;exhaustivit&eacute; et la l&eacute;galit&eacute; des contenus de ces pages web, ni quant aux produits et services qui y sont propos&eacute;s.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">5. Droits immat&eacute;riels, informations confidentielles et secrets commerciaux&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Vous reconnaissez et acceptez que la plateforme et toutes les parties de celle-ci peuvent &ecirc;tre prot&eacute;g&eacute;es par des droits d&apos;auteur ou par d&apos;autres dispositions relatives &agrave; la protection de la propri&eacute;t&eacute; intellectuelle ou &agrave; la concurrence d&eacute;loyale et qu&apos;elles sont et restent la propri&eacute;t&eacute; de la FSG et/ou de ses conc&eacute;dants de licence. De tels contenus ne peuvent &ecirc;tre utilis&eacute;s qu&rsquo;en conformit&eacute; avec les pr&eacute;sentes conditions d&rsquo;utilisation, ou conform&eacute;ment &agrave; une convention diff&eacute;rente entre vous et la FSG, dans la mesure o&ugrave; elle est applicable et conforme &agrave; toutes les lois et prescriptions applicables. Toute utilisation non autoris&eacute;e est strictement interdite.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Tous les noms de marque, marques commerciales, marques de services ainsi que d&rsquo;autres noms de produits et services et logos, qu&apos;ils soient enregistr&eacute;s ou non, figurant sur le site web et la plateforme sont la propri&eacute;t&eacute; de leurs d&eacute;tenteurs respectifs et sont prot&eacute;g&eacute;s par le droit en vigueur.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>La reproduction, diffusion, modification, connexion ou utilisation &agrave; des fins commerciales ou publiques et tout type d&apos;utilisation en dehors des limites de la loi sur les droits d&apos;auteur requi&egrave;rent l&apos;accord &eacute;crit pr&eacute;alable de la FSG. Aucune licence d&rsquo;utilisation de la propri&eacute;t&eacute; intellectuelle de tiers n&rsquo;est accord&eacute;e via cette plateforme.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Il est permis de consulter les informations sur Internet et d&apos;en faire des copies sur papier ou sur d&apos;autres supports de donn&eacute;es &agrave; des fins personnelles.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Il est permis de conserver des copies non modifi&eacute;es des informations sur un serveur proxy HTTP, &agrave; condition de veiller &agrave; ce que les modifications et les suppressions des donn&eacute;es originales sur le serveur stv-fsg.ch soient saisies dans un d&eacute;lai raisonnable.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Il est permis de cr&eacute;er des r&eacute;pertoires dans des bases de donn&eacute;es sur le contenu des pages d&apos;information, dans la mesure o&ugrave; le but d&apos;une telle collecte de donn&eacute;es est de faciliter la recherche d&apos;informations factuelles (moteurs de recherche, r&eacute;pertoires, etc.).</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">6. Confidentialit&eacute; et protection des donn&eacute;es&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Dans la mesure o&ugrave; des donn&eacute;es personnelles sont trait&eacute;es sur le site web ou la plateforme, il convient de se r&eacute;f&eacute;rer &agrave; la d&eacute;claration de protection des donn&eacute;es pour le traitement de ces donn&eacute;es.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">7. Dispositions sp&eacute;cifiques pour l&rsquo;utilisation de la plateforme&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Avec la plateforme mySTV-FSG, la FSG met &agrave; disposition des membres FSG, membres de la garde d&rsquo;honneur de la FSG (membres d&rsquo;honneur, distinctions honorifiques) et fonctionnaires de la FSG ainsi que des associations membres/cantonales une plateforme num&eacute;rique des membres et des clients. La carte de membre et de fonctionnaire est accessible via la plateforme. De plus les utilisateurs enregistr&eacute;s peuvent modifier eux-m&ecirc;mes certaines donn&eacute;es personnelles (par ex. adresse, nom, date de naissance, adresse &eacute;lectronique, num&eacute;ro de t&eacute;l&eacute;phone, type de sport pratiqu&eacute;). L&rsquo;utilisation de la plateforme est r&eacute;serv&eacute;e aux personnes appartenant aux cat&eacute;gories de membres susmentionn&eacute;es.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Avec l&rsquo;enregistrement et l&rsquo;utilisation de la plateforme, vous acceptez les pr&eacute;sentes conditions d&rsquo;utilisations dans leur version actuelle.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">7.1 Droits et obligations de la FSG&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Nous nous effor&ccedil;ons d&rsquo;am&eacute;liorer continuellement la plateforme et de l&rsquo;adapter &agrave; vos besoins et exigences. Nous nous r&eacute;servons toutefois le droit de modifier ou d&rsquo;arr&ecirc;ter en tout temps et sans pr&eacute;avis des fonctions de la plateforme. Nous nous efforcerons de vous informer de toute modification ou de tout arr&ecirc;t des services en publiant une communication correspondante sur la plateforme.&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>La plateforme, y compris tous les contenus qu&apos;elle contient, est mise &agrave; disposition sans garantie de d&eacute;faut. Nous excluons ainsi toutes les assurances et garanties (notamment en ce qui concerne les vices mat&eacute;riels et juridiques). Nous n&rsquo;assurons ni ne garantissons que les informations et les contenus mis &agrave; disposition sur ou via la plateforme (par nous-m&ecirc;mes ou par les utilisateurs), soient compl&egrave;tes ou sans faute. Les utilisateurs reconnaissent que le t&eacute;l&eacute;chargement ou l&apos;obtention d&apos;informations et de contenus de la plateforme se fait &agrave; leur propre discr&eacute;tion et &agrave; leurs propres risques.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>La FSG s&rsquo;efforce &agrave; maintenir la plateforme disponible en tout temps. La plateforme ou des parties de nos services peuvent toutefois pour diff&eacute;rentes raisons &ecirc;tre indisponibles par moment.&nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Les travaux de maintenance ayant une influence sur le fonctionnement de la plateforme seront effectu&eacute;s, dans la mesure du possible, pendant les heures creuses et seront annonc&eacute;s, dans la mesure du possible, par courriel via une page d&apos;&eacute;tat ou par tout autre moyen appropri&eacute;.&nbsp;</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">7.2 Ouverture et fermeture d&rsquo;un compte&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>La condition pour utiliser la plateforme est l&rsquo;ouverture d&rsquo;un compte mySTV-FSG. Pour ouvrir un compte, il faut que la soci&eacute;t&eacute; de gymnastique locale ait enregistr&eacute; pour la personne concern&eacute;e une adresse &eacute;lectronique dans la base de donn&eacute;es des membres FSG. Toutes les personnes autoris&eacute;es re&ccedil;oivent un courriel avec la possibilit&eacute; d&apos;activer leur compte personnel mySTV-FSG et de d&eacute;finir un mot de passe.&nbsp;Les personnes autoris&eacute;es ont en outre la possibilit&eacute;, en indiquant leur adresse &eacute;lectronique ou leur num&eacute;ro de membre directement sur la plateforme, de g&eacute;n&eacute;rer un courriel automatis&eacute; contenant un lien pour activer un compte mySTV-FSG.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Les utilisateurs peuvent en tout temps se d&eacute;sinscrire ou supprimer leur compte mySTV-FSG. Pour cela il suffit d&rsquo;adresser une demande de fermeture de compte mySTV-FSG &agrave; <a href="mailto:mystv@stv-fsg.ch">mystv@stv-fsg.ch</a>.&nbsp;La FSG traitera la demande dans un d&eacute;lai raisonnable.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Apr&egrave;s la fermeture, l&rsquo;utilisateur n&rsquo;aura acc&egrave;s ni &agrave; la plateforme des membres ni aux donn&eacute;es, informations, outils et services disponibles sur la plateforme de membres.&nbsp;</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">7.3 Droits et obligations des utilisateurs&nbsp;</span></h1>
        </div>
        <h1 style='margin-top:6.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="font-size:13px;line-height:115%;">Donn&eacute;es compl&egrave;tes et actuelles&nbsp;</span></h1>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>L&rsquo;utilisateur s&rsquo;engage &agrave; fournir de mani&egrave;re actuelle, pr&eacute;cise et compl&egrave;te toutes les informations le concernant.</p>
        <p style='margin-top:0cm;margin-right:35.4pt;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Il s&rsquo;engage &eacute;galement &agrave; mettre &agrave; jour imm&eacute;diatement toutes les donn&eacute;es d&eacute;cisives pour l&rsquo;utilisation de la plateforme.</p>
        <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="font-size:13px;line-height:115%;">Normes de comportement&nbsp;</span></h1>
        <p style='margin-top:0cm;margin-right:35.4pt;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Vous assurez et garantissez que vous n&apos;enfreignez pas le droit suisse applicable et, en particulier, que vous ne mettez pas &agrave; disposition sur la plateforme des contenus d&apos;utilisateur de quelque nature que ce soit qui violent les droits de tiers ou qui contiennent du mat&eacute;riel ill&eacute;gal. Vous vous engagez notamment &agrave; ne pas utiliser la plateforme pour les activit&eacute;s suivantes :</p>
        <ul style="list-style-type: disc;margin-left:25.700000000000003px;">
            <li><span style="font-size:13px;">D&eacute;vier ou collecter des adresses &eacute;lectroniques ou d&apos;autres informations de contact d&apos;autres personnes &agrave; partir de la plateforme, par des moyens &eacute;lectroniques ou autres, afin d&apos;envoyer des courriels spontan&eacute;s ou d&apos;autres communications spontan&eacute;es ;</span></li>
            <li><span style="font-size:13px;">Utiliser des scripts automatis&eacute;s pour collecter des informations de la plateforme ou de toute autre interaction avec la plateforme ;</span></li>
            <li><span style="font-size:13px;">T&eacute;l&eacute;charger, mettre en ligne, transmettre, mettre &agrave; disposition ou stocker des&nbsp;</span></li>
        </ul>
        <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
            <ul style="margin-bottom:0cm;margin-top:0cm;" type="circle">
                <li style='margin-top:0cm;margin-right:31.1pt;margin-bottom:6.0pt;margin-left:0cm;text-align:left;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Contenus qui &agrave; notre avis sont pr&eacute;judiciables, mena&ccedil;ants, contrevenants, abusifs, harcelants, obsc&egrave;nes, frauduleux, portant atteinte aux droits de protection des donn&eacute;es ou de la personne, ou &eacute;tant racistes ou autrement r&eacute;pr&eacute;hensibles ou ill&eacute;gaux,</li>
                <li style='margin-top:0cm;margin-right:31.1pt;margin-bottom:6.0pt;margin-left:0cm;text-align:left;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Publicit&eacute; spontan&eacute;e ou non-autoris&eacute;e, spam ou autres,</li>
                <li style='margin-top:0cm;margin-right:31.1pt;margin-bottom:6.0pt;margin-left:0cm;text-align:left;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Contenus qui seraient &agrave; l&rsquo;origine d&rsquo;une infraction p&eacute;nale, inciterait d&apos;autres personnes &agrave; commettre une infraction p&eacute;nale ou donneraient des instructions &agrave; de telles personnes pour qu&apos;elles commettent une infraction p&eacute;nale, ou un contenu qui porterait atteinte aux droits d&apos;un tiers ou engagerait d&apos;une autre mani&egrave;re une responsabilit&eacute; ou violerait le droit national ou<br>&nbsp;international ;</li>
            </ul>
        </ul>
        <ul style="list-style-type: disc;margin-left:25.700000000000003px;">
            <li><span style="font-size:13px;">Mettre en ligne des contenus pour une autre personne que soi-m&ecirc;me ou se faire passer pour une autre personne physique ou morale, ou faire une fausse d&eacute;claration concernant votre identit&eacute;, votre &acirc;ge ou votre affiliation &agrave; une autre personne physique ou morale ;&nbsp;</span></li>
            <li><span style="font-size:13px;">Demander des informations sur les mots de passe ou les donn&eacute;es personnelles &agrave; des fins commerciales ou ill&eacute;gales.</span></li>
        </ul>
        <p style='margin-top:0cm;margin-right:35.4pt;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Vous &ecirc;tes tenus de g&eacute;rer votre compte utilisateur sur la plateforme avec soin et d&apos;emp&ecirc;cher en particulier son utilisation par des personnes non autoris&eacute;es.&nbsp;Vous devez en particulier choisir un mot de passe s&ucirc;r et le traiter de mani&egrave;re confidentielle. En relation avec la FSG, toutes les actions, qui se font en utilisant un compte d&rsquo;utilisateur, sont consid&eacute;r&eacute;es comme actions de l&rsquo;utilisateur enregistr&eacute; correspondant.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">7.4 Violation des conditions d&rsquo;utilisation&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:35.4pt;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>En cas de violation par l&rsquo;utilisateur des conditions d&rsquo;utilisation, la FSG peut prononcer les sanctions suivantes :</p>
        <ul style="list-style-type: disc;margin-left:25.700000000000003px;">
            <li><span style="font-size:13px;">Rappel &agrave; l&apos;ordre pour une utilisation conforme &agrave; la loi et au contrat,</span></li>
            <li><span style="font-size:13px;">Suppression ou modification de contenus publi&eacute;s par l&rsquo;utilisateur,</span></li>
            <li><span style="font-size:13px;">Modifier, limiter ou bloquer l&rsquo;acc&egrave;s &agrave; la plateforme sans pr&eacute;avis et sans indemnisation&nbsp;</span></li>
            <li><span style="font-size:13px;">Exiger, le cas &eacute;ch&eacute;ant, des dommages et int&eacute;r&ecirc;ts ainsi que l&apos;indemnisation des pr&eacute;tentions de tiers.</span></li>
        </ul>
        <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">8. Modifications</span></h1>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Nous nous r&eacute;servons le droit d&rsquo;am&eacute;liorer ou de modifier en tout temps et sans pr&eacute;avis, les pr&eacute;sentes conditions d&rsquo;utilisation et le contenu de cette plateforme. Les pr&eacute;sentes conditions d&rsquo;utilisation ont &eacute;t&eacute; modifi&eacute;es pour derni&egrave;re fois le 28 f&eacute;vrier 2023.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">9. Dispositions finales&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Si l&apos;une des dispositions des pr&eacute;sentes conditions d&apos;utilisation est d&eacute;clar&eacute;e invalide ou inapplicable, les autres dispositions demeurent pleinement valables et efficaces. Si vous estimez que selon le droit imp&eacute;rativement applicable vous avez d&rsquo;autres droits ou des droits suppl&eacute;mentaires que ceux cit&eacute;s dans les pr&eacute;sentes conditions d&rsquo;utilisation, nous vous prions de nous en informer imm&eacute;diatement pour que nous puissions pr&eacute;parer une solution alternative &nbsp;</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>L&rsquo;omission de faire respecter strictement l&apos;une de ces conditions d&apos;utilisation ne doit pas &ecirc;tre consid&eacute;r&eacute;e comme une renonciation &agrave; certains droits et/ou obligations.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Les conditions d&rsquo;utilisation mySTV-FSG s&rsquo;appliquent par ailleurs.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">10. Droit applicable et tribunal comp&eacute;tent&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Les pr&eacute;sentes conditions d&rsquo;utilisation sont soumises au droit mat&eacute;riel de la Suisse, dans la mesure o&ugrave; le droit d&rsquo;un autre pays ne s&rsquo;applique pas de mani&egrave;re contraignante.</p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Le tribunal comp&eacute;tent pour tous les litiges d&eacute;coulant ou en rapport avec cette plateforme est le si&egrave;ge de la FSG, &agrave; moins qu&apos;un autre tribunal ne soit obligatoire.</p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>
            <h1 style='margin-top:24.0pt;margin-right:0cm;margin-bottom:9.0pt;margin-left:42.55pt;text-align:justify;text-indent:-42.55pt;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'><span style="line-height:115%;font-size:13px;">11. Contactez-nous&nbsp;</span></h1>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;line-height:14.5pt;font-size:13px;font-family:"Arial",sans-serif;'>Si vous avez des commentaires ou des questions sur ces conditions d&rsquo;utilisation ou sur notre plateforme, veuillez nous contacter par courrier &eacute;lectronique &agrave; l&rsquo;adresse <a href="mailto:mystv@stv-fsg.ch">mystv@stv-fsg.ch</a>.</p>
    </div>
</template>

<style scoped>


.termsAndConditions {
    border: 1px solid #000;
    padding: 1em;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 20em
}

</style>