import ApiClient from "@/api/ApiClient";
import { defineStore } from "pinia";
import { useMemberStore } from "@/store/modules/member/member.js";

const USER_BASE_PATH = '/api/user';

export const useUserStore = defineStore("user", {
  state: () => {
    return {
        memberData: null,
    };
  },

  actions: {
    async changePassword(payload) {
      let response = await ApiClient.postRequestWithAuthorization(USER_BASE_PATH + '/changePassword', payload);
      if (response) {
        return response;
      }
      return null;
    },
    async sendPasswordResetRequest(payload) {
      const response = await ApiClient.postRequestWithoutAuthorization(USER_BASE_PATH + '/sendResetLink', payload);
      if (response) {
        return response;
      }
      return null;
    },
    async validateResetToken(payload) {
      const response = await ApiClient.getRequestWithoutAuthorization(USER_BASE_PATH + '/validateResetToken?token=' + payload.token);
      if (response) {
        return response;
      }
      return null;
    },
    async resetPassword(payload) {
      const response = await ApiClient.executePutRequest(USER_BASE_PATH + '/resetPassword', payload);
      if (response) {
        return response;
      }
      return null;
    },
    async changeEmail(payload) {
      const response = await ApiClient.executePutRequest(USER_BASE_PATH + '/changeEmail', payload);
      if (response) {
        return response;
      }
      return null;
    },
    async validateEmailChange(payload) {
      const response = await ApiClient.getRequestWithoutAuthorization(USER_BASE_PATH + '/validateEmailChange?token=' + payload.token);
      if (response) {
        return response;
      }
      return null;
    },
    async setNewEmail(payload) {
      const response = await ApiClient.postRequestWithoutAuthorization(USER_BASE_PATH + '/setNewEmail', payload);
      if(useMemberStore().getMemberData !== null && useMemberStore().getMemberData !== undefined) {
        useMemberStore().updateEmail({newEmail: response.data.newEmail})
      }
      if (response) {
        return response;
      }
      return null;
    },


  }
})
