import moment from "moment/moment";
import ApiClient from "@/api/ApiClient";
import i18n from "@/i18n";
import {getSquadByCode, getSquadByKey} from "@/helpers/functions";
import generateQrCode from "@/helpers/print/qrcode";

const {t} = i18n.global;
const stvLogos = require('@/assets/img/stv-logos.svg');
const sportLogos = require('@/assets/img/sport-icons.svg');
const stvLogosGrey = require('@/assets/img/stv-logos-grey.svg');

function openWindow(url, name, props) {
  let windowRef = window.open(url, name, props);
  if (!windowRef.opener) {
    windowRef.opener = self;
  }
  windowRef.focus();
  return windowRef;
}


function addStyles(win, styles) {
  styles.forEach(style => {
    let link = win.document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', style);
    win.document.getElementsByTagName('head')[0].appendChild(link);
  });
}

async function createMemberCards(members, club, association) {
  // create base element for cards
  const cardsContainer = document.createElement('div');
  cardsContainer.classList.add('mystv-container');

  for (let i = 0; i < members.length; i++) {
    if (i % 2 === 0) {
      const cardRow = document.createElement('div');
      cardRow.classList.add('membercard-container');
      const memberCol1 = await createMemberCardElement(members[i], club, association);
      cardRow.appendChild(memberCol1);
      const emptyCol = document.createElement('div');
      emptyCol.classList.add('membercard');
      const memberCol2 = members[i + 1] ? await createMemberCardElement(members[i + 1], club, association) : emptyCol;
      cardRow.appendChild(memberCol2);
      cardsContainer.appendChild(cardRow);
    }
  }

  return cardsContainer;
}

async function createMemberCardElement(memberData, club, association) {
  const formattedBirthday = formatDate(memberData.birthday);
  const qrCodeData = await generateQrCode(memberData);

  // member card wrapper
  const memberCardEl = document.createElement('div');
  memberCardEl.classList.add('membercard');
  // START left part of member card
  const cardLeft = document.createElement('div');
  cardLeft.classList.add('membercard-left');
  cardLeft.innerHTML = `<h1>${memberData.firstName} ${memberData.surname}</h1><p class="margin-down">${formattedBirthday}</p><p>${club}<br><em>${association}</em></p>`;
  // END left part of member card
  // START right part of member card
  const cardRight = document.createElement('div');
  cardRight.classList.add('membercard-right');
  // qr code
  const qrCodeEl = document.createElement('div');
  qrCodeEl.classList.add('qr');
  qrCodeEl.innerHTML = `<img src="data:image/png;base64,${qrCodeData}" alt="QR-Code">`;
  // descriptions
  const memberDesc = document.createElement('p');
  memberDesc.innerHTML = `<small>${t('section.member.memberNr')}</small><strong class="d-block">${memberData.memberId}</strong>`;
  cardRight.appendChild(qrCodeEl);
  cardRight.appendChild(memberDesc);
  // END right part of member card
  // START card footer
  const footerEl = document.createElement('footer');
  // left part of footer
  const footerLeft = document.createElement('div');
  footerLeft.classList.add('footer-left');
  footerLeft.innerHTML = `<svg width="45" height="45"><use xlink:href="${stvLogos}#stv"/></svg>`;
  // right part of footer
  const footerRight = document.createElement('div');
  footerRight.classList.add('footer-right');
  footerRight.innerHTML = `<svg width="45" height="45"><use xlink:href="${stvLogos}#coop"/></svg>`;
  footerEl.appendChild(footerLeft);
  footerEl.appendChild(footerRight);
  // END card footer
  // putting all together
  memberCardEl.appendChild(cardLeft);
  memberCardEl.appendChild(cardRight);
  memberCardEl.appendChild(footerEl);
  return memberCardEl;
}

function formatDate(value) {
  const dateVal = moment(String(value));
  if (dateVal && dateVal.isAfter('1900-01-01')) {
    return dateVal.format('DD.MM.YYYY');
  }
  return '-';
}

async function openPrintWindow(members, club, association, localOptions) {
  let {
    name = '_blank',
    specs = ['fullscreen=no', 'titlebar=no', 'scrollbars=no', 'menubar=no', 'toolbar=no', 'status=no', 'width=840', 'height=1188'],
    styles = [ '/css/print-multi-card.css' ]
  } = localOptions;

  if (localOptions) {
    if (localOptions.name) name = localOptions.name;
    if (localOptions.specs) specs = localOptions.specs;
    if (localOptions.styles) styles = localOptions.styles;
  }
  specs = specs.length ? specs.join(',') : '';


  const url = '';
  const win = openWindow(url, name, specs);
  const element = await createMemberCards(members, club, association);
  win.document.write(`
  <html lang="de">
    <head><title>${window.document.title}</title></head>
    ${element.innerHTML}
  </html>
  `);

  addStyles(win, styles);

  setTimeout(() => {
    win.document.close();
    win.focus();
    win.print();
    setTimeout(function () {
      win.window.close();
    });
  }, 1000);

  return true;
}

async function openSingleCardsPrintWindow(members, club, association, localOptions) {
  let {
    name = '_blank',
    specs = ['fullscreen=no', 'titlebar=no', 'scrollbars=no', 'menubar=no', 'toolbar=no', 'status=no', 'width=840', 'height=1188'],
    styles = [ '/css/print-single-card.css' ]
  } = localOptions;

  if (localOptions) {
    if (localOptions.name) name = localOptions.name;
    if (localOptions.specs) specs = localOptions.specs;
    if (localOptions.styles) styles = localOptions.styles;
  }
  specs = specs.length ? specs.join(',') : '';

  const element = await createSingleCards(members, club, association);
  const url = '';
  const win = openWindow(url, name, specs);
  win.document.write(`
  <html lang="de">
    <head><title>${window.document.title}</title></head>
    <body>${element.innerHTML}</body>
  </html>
  `);

  addStyles(win, styles);

  setTimeout(() => {
    win.document.close();
    win.focus();
    win.print();
    setTimeout(function () {
      win.window.close();
    });
  }, 1000);

  return true;
}

async function createSingleCards(members, club, association) {
  const singleCardsWrapper = document.createElement('div');
  singleCardsWrapper.classList.add('single-card-wrapper');
  // create base container
  for (let i = 0; i < members.length; i++) {
    const singleCardsContainer = document.createElement('div');
    singleCardsContainer.classList.add('single-card-container');

    const birthday = formatDate(members[i].birthday);
    const qrCodeData = await generateQrCode(members[i]);
    let memberInfoHTML = `
<p><strong>${t('personalData.person.birthday')}</strong><br>${birthday}</p>
<p><strong>${t('club', 1)}</strong><br>${club}</p>
<p><strong>${t('association', 1)}</strong><br>${association}</p>`;

    let memberSportsHTML = ``;
    const sports = await getSportsForMember(members[i].memberId);
    if (sports.length > 0) {
      let sportIconsHTML = ``;
      sports.forEach(s => {
        const sportDescription = t('sport.' + s)
        sportIconsHTML += `<div class="icon"><svg width="60" height="60"><use xlink:href="${sportLogos}#${s}"/></svg><small>${sportDescription}</small></div>`;
      });
      memberSportsHTML = `
<p><strong>${t('sport.title', sports.length)}</strong></p>
<div class="sport-icons">
  ${sportIconsHTML}
</div>`;
    }
    const memberCardHTML = `
<header>
  <div class="header-left">${t('section.member.titleMembercard')}</div>
  <div class="header-right"><svg width="50" height="50"><use xlink:href="${stvLogos}#stv" /></svg>${t('appTitle')}</div>
</header>
<div class="card-grid">
  <div class="card">
    <h1>${members[i].firstName} ${members[i].surname}</h1>
    <div class="qr-code"><img src="data:image/png;base64,${qrCodeData}" alt="QR-Code"></div>
    <p>${t('section.member.memberNr')} <strong>${members[i].memberId}</strong></p>
    <p>${t('personalData.person.birthday')} <span>${birthday}</span></p>
  </div>
  <div class="member-info">
    ${memberInfoHTML}
  </div>
  <div class="sports">
    ${memberSportsHTML}
  </div>
</div>
<footer><svg width="100%" height="50"><use xlink:href="${stvLogosGrey}#coop"/></svg></footer>
<div class="page-break"></div>
`;
    singleCardsContainer.innerHTML += memberCardHTML;
    singleCardsWrapper.appendChild(singleCardsContainer);
  }
  return singleCardsWrapper;
}

async function getSportsForMember(memberId) {
  const response = await ApiClient.getRequest('/api/member/byid?id=' + memberId);
  if (response) {
    const member = response.data;
    let sports = new Set([]);
    const currentYear = new Date().getFullYear();
    if (member.competitiveSports && member.competitiveSports.kindOfSport && member.competitiveSports.paidAt.length > 0 && member.competitiveSports.year >= currentYear) {
      if (getSquadByKey(member.competitiveSports.kindOfSport)) sports.add(getSquadByKey(member.competitiveSports.kindOfSport));
    }
    if (member.memberships) {
      member.memberships.forEach(m => {
        if ((m.category && m.squad) && (m.category.code === '1' || m.category.code === '4')) {
          if (getSquadByCode(m.squad.id)) {
            sports.add(getSquadByCode(m.squad.id));
          }
        }
      });
    }
    return [...sports].sort();
  }
  return [];
}

export {openPrintWindow, openSingleCardsPrintWindow};
