<template>
  <p class="mb-3">
    <terms-and-conditions></terms-and-conditions>
  </p>
  <div class="form-check" :key="getCheckbox().id">
    <input class="form-check-input" type="checkbox" :id="getCheckbox().id" :value="getCheckbox().value" @change="handleChange">
    <label class="form-check-label" :for="getCheckbox().id">
      {{ getCheckbox().label }}
    </label>
  </div>

</template>


<script>
import {useMemberStore} from "@/store/modules/member/member.js";
import {useI18n} from "vue-i18n";
import {removeEmpty} from "@/helpers/functions"
import {mapState} from 'pinia';
import TermsAndConditions from "@/components/profile/TermsAndConditionsClubAdmin";

export default {
  emits: ["updateValues"],
  data() {
    return {
      data: null,
    };
  },
  computed: {
    ...mapState(useMemberStore, ["wizardData"])
  },
  methods: {
    handleChange(change) {
      this.$emit("updateValues", change.target.checked);
    },
    getWizardTitle() {
      return useI18n().locale.value !== 'de' ? removeEmpty(this.data.componentsFr) : removeEmpty(this.data.componentsDe)
    },
    getWizardComponents() {
      return useI18n().locale.value !== 'de' ? removeEmpty(this.data.componentsFr) : removeEmpty(this.data.componentsDe)
    },
    getCheckbox() {
      if (this.getWizardComponents()[0].props.type === "checkbox") {
        return this.getWizardComponents()[0].props;
      }

      return [];
    },
  },
  components: {
    TermsAndConditions
  },
  beforeMount() {
    this.data = this.wizardData;
  }

};

</script>

<style scoped>

</style>
