<template>
  <section-container id="wizard-section" additional-class="p-5 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="mb-3">{{ i18nLocale.locale.value !== 'de' ? wizard.titleFr : wizard.titleDe }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="mb-3">{{ i18nLocale.locale.value !== 'de' ? wizard.textFr : wizard.textDe }}</p>
        </div>
      </div>
      <div class="row" v-if="wizard.wizardName === 'WIZARD_NAME_CLUB_ADMIN'">
        <div class="col-12 mb-3">
          <VereinAdminWizard @updateValues="updateVereinAdminWizardValue"></VereinAdminWizard>
        </div>
      </div>
      <div class="row" v-if="wizard.wizardName === 'WIZARD_NAME_MEMBER_SPORTS'">
        <div class="col-12 mb-3">
          <SportsWizard @updateValues="updateSportWizardValues"></SportsWizard>
        </div>
      </div>
      <div class="row">
        <div class="col-12 actions">
          <DefaultButton @click="submitHandler" class="btn-outline-danger" :label="$t('wizard.button.submit')" :disabled="!readyToSubmit"></DefaultButton>
          <DefaultButton @click="postponeHandler" v-if="wizard.postponable" class="btn-outline-dark" :label="$t('wizard.button.postpone')"></DefaultButton>
          <DefaultButton @click="rejectHandler" v-if="wizard.rejectable" class="btn-outline-dark" :label="$t('wizard.button.reject')"></DefaultButton>

        </div>
      </div>
    </div>
  </section-container>
  <LoaderOverlay v-if="isLoading"/>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import SectionContainer from "@/components/ui/SectionContainer";
import {useI18n} from "vue-i18n";
import {onBeforeRouteUpdate, useRouter} from 'vue-router';
import {useMemberStore} from "@/store/modules/member/member.js";
import {useProfileStore} from "@/store/modules/profile/profile.js";
import {useLoginStore} from "@/store/modules/login/login.js";
import {removeEmpty} from "@/helpers/functions"
import SportsWizard from '@/views/wizard/SportsWizard';
import VereinAdminWizard from '@/views/wizard/VereinAdminWizard';
import DefaultButton from '@/components/ui/DefaultButton';
import LoaderOverlay from "@/components/ui/LoaderOverlay.vue";

const i18nLocale = useI18n();
const submitted = ref(false)
const readyToSubmit = ref(false)
const router = useRouter();
const memberStore = useMemberStore()
const isLoading = ref(false)

let wizard = ref(memberStore.wizardData)
let wizardComponents = ref(i18nLocale.locale.value !== 'de' ? removeEmpty(wizard.value.componentsFr) : removeEmpty(wizard.value.componentsDe));
let submittedData = {
  wizardId: null,
  status: null,
  data: null
}

const updateSportWizardValues = async (formData) => {
  submittedData.data = formData
}

const updateVereinAdminWizardValue = async (checked) => {
  readyToSubmit.value = checked;
}

const rejectHandler = async () => {
  isLoading.value = true
  submitted.value = true
  submittedData.wizardId = wizard.value.id
  submittedData.status = "REJECTED"
  submittedData.data = null;
  responseHandler(await memberStore.postWizardData(submittedData))
}

const postponeHandler = async () => {
  isLoading.value = true
  submitted.value = true;
  submittedData.wizardId = wizard.value.id
  submittedData.status = "POSTPONED";
  submittedData.data = null;
  responseHandler(await memberStore.postWizardData(submittedData))
}

const submitHandler = async () => {
  isLoading.value = true
  submitted.value = true;
  submittedData.wizardId = wizard.value.id
  submittedData.status = "DONE";
  responseHandler(await memberStore.postWizardData(submittedData))
}

const responseHandler = async (response) => {
  if (response && response.status === 200) {
    await useLoginStore().forceRefresh()
    await memberStore.setWizardDone(wizard.value.id)
    await memberStore.loadMemberData(true, false)
    await useProfileStore().getPhotoAsBase64(memberStore.getMemberData.memberId)
    await memberStore.clearWizardData()
    router.push({name: 'rootPage'})
  }
  isLoading.value = false
}

onBeforeRouteUpdate((to, from, next) => {

  wizard.value = memberStore.wizardData
  wizardComponents.value = i18nLocale.locale.value !== 'de' ? removeEmpty(wizard.value.componentsFr) : removeEmpty(wizard.value.componentsDe)

  if (wizard.value.wizardName === 'WIZARD_NAME_MEMBER_SPORTS') {
    readyToSubmit.value = true
  } else if (wizard.value.wizardName === 'WIZARD_NAME_CLUB_ADMIN') {
    readyToSubmit.value = false
  }
  next()
})

onMounted(() => {
  if (wizard.value.wizardName === 'WIZARD_NAME_MEMBER_SPORTS') {
    readyToSubmit.value = true
  }
})

watch(i18nLocale.locale, () => {
  if (wizardComponents.value !== null) {
    wizardComponents.value = i18nLocale.locale.value !== 'de' ? removeEmpty(wizard.value.componentsFr) : removeEmpty(wizard.value.componentsDe)
  }
})
</script>

<style scoped>
.btn-outline-danger {
  color: #D8232A;
  border-color: #D8232A;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #D8232A;
}

.actions {
  display: flex;
  justify-content: right
}

.actions > .btn {
  align-self: baseline;
  margin-left: .5em
}
</style>

