<template>
  <MetaNavigation/>
  <MemberSection :member-data="currentMemberData" :card-title="$t('section.member.titleSingleMember')" v-if="isUser" :is-emulating-member="isEmulatingMember"/>
</template>

<script>
import {mapState} from "pinia";
import MetaNavigation from "@/components/navigation/MetaNavigation.vue";
import MemberSection from "@/components/personal-data/MemberSection.vue";
import {useMemberStore} from "@/store/modules/member/member.js";
import {useLoginStore} from "@/store/modules/login/login.js";


export default {
  components: {
    MetaNavigation,
    MemberSection,
  },
  data() {
    return {
    };
  },
  computed: {
    member() {
      return this.memberData
    },
    currentMemberData() {
      if (this.isEmulatingMember && this.releaseMemberData) {
        return this.releaseMemberData;
      }
      return this.memberData;
    },
    ...mapState(
      useMemberStore, { memberData: "getMemberData", releaseMemberData: "getReleaseMemberData", isEmulatingMember: "isEmulatingMember", qrCodeSvg: "getQrCodeSvg"},
    ),
    ...mapState(
      useLoginStore, [ "isUser" ]
    )
  },
};
</script>

