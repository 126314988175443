import axios from "axios";
import logger from "@/logger/index.js";

import { useLoginStore } from "@/store/modules/login/login";

const headers = {
  "Accept": "application/JSON",
  "Content-Type": "application/json",
};

const config = {
  withCredentials: false,
  headers: headers,
  data: null,
  params: null
}

axios.defaults.baseURL = window.VUE_APP_QRCODE_BASE_URL;
const client = axios.create(config);

export default {
  async postRequest(path, data) {
    const loginStore = useLoginStore()
    config.headers['Authorization'] = 'Bearer ' + loginStore.getAccessToken;
    config.data = data;
    return client.post(path, config).then(response => {
      if (response.data) {
        return response.data;
      }
      return null;
    }).catch(error => {
      logger.error(error);
      return null;
    });
  },
  async getRequest(path, params) {
    const loginStore = useLoginStore()
    config.headers['Authorization'] = 'Bearer ' + loginStore.getAccessToken;
    config.params = params;
    return client.get(path, config).then(response => {
      if (response.data) {
        return response.data;
      }
      return null;
    }).catch(error => {
      logger.error(error);
      return null;
    });
  },
};
