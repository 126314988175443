<template>
  <section-container id="member-section" additional-class="membercard-container py-4">
    <h1 class="fs-6 section-title text-center text-uppercase py-2">{{ cardTitle }}</h1>
    <div class="row mt-4 mb-md-4 gap-0 flex-md-nowrap">
      <!-- Membercard-->
      <div id="member-card" class="col-12 col-md-5 flex-shrink-0 pe-md-0">
        <div id="member-card-inner" class="card rounded-3 text-center" :class="memberClass">
          <UserAvatar id="card-avatar" avatar-class="portrait-large mt-3" v-if="!isSingleMember && !isEmulatingMember"/>
          <h2 class="display-6 mt-4 mb-4 text-wrap-words" v-resize-text="{ratio: 1, minFontSize: 18, maxFontSize: 40, delay: 20}">{{ memberData.firstName }} {{ memberData.surname }}</h2>
          <div id="qrcode-wrapper">
            <div class="qr-code-loader" v-if="!qrCode">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-qr-code" viewBox="0 0 16 16">
                <path d="M2 2h2v2H2V2Z"/>
                <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z"/>
                <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z"/>
                <path d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z"/>
                <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z"/>
              </svg>
            </div>
            <div class="m-auto qr-code" v-else>
              <img :src="'data:image/png;base64,' + qrCode" alt="QR-Code" class="img-fullwidth">
            </div>
          </div>
          <p class="mt-4 mb-1">{{ $t('section.member.memberNr') }}:</p>
          <strong class="mb-4 fs-5">{{ memberData.memberId }}</strong>
          <p class="mb-1">{{ $t('personalData.person.birthday') }}</p>
          <span class="mb-4 fs-5">{{ formatDate(memberData.birthday) }}</span>
        </div>

        <DefaultButton
          class="btn btn-outline-dark btn-block mt-3"
          icon="bi bi-printer"
          :label="$t('print', {object: $t('section.member.titleMembercard')})"
          @click="printMemberCard"
        />
        <DefaultButton
          class="btn btn-outline-dark btn-block mt-3"
          icon="bi bi-download"
          :label="$t('save', {object: $t('section.member.titleMembercard')})"
          :disabled="isLoading"
          @click.prevent="saveCardAsImage"
        />
        <div class="wallet-buttons" v-if="addToGoogleWalletJWT">
          <a href="" class="wallet-button" :class="{disabled: applePassLoading}" @click.prevent="downloadApplePass">
            <img v-if="$i18n.locale === 'fr'" src="@/assets/img/wallet/FR_Add_to_Apple_Wallet_RGB_102921.svg" alt="Ajouter à l'app Cartes Apple" class="wallet-button-style"/>
            <img v-else src="@/assets/img/wallet/DE_Add_to_Apple_Wallet_RGB_101421.svg" alt="Zu Apple Wallet hinzufügen" class="wallet-button-style"/>
          </a>
          <a :href="addToGoogleWalletJWT" :class="{disabled: !addToGoogleWalletJWT}" target="_blank" rel="noopener noreferrer" @click="startupdateGoogleWalletPass">
            <img v-if="$i18n.locale === 'de'" src="@/assets/img/wallet/de_add_to_google_wallet_add-wallet-badge.svg" alt="Zu Google Wallet hinzufügen" class="wallet-button-style"/>
            <img v-else-if="$i18n.locale === 'fr'" src="@/assets/img/wallet/frFR_add_to_google_wallet_add-wallet-badge.svg" alt="Ajouter au Google Wallet" class="wallet-button-style"/>
            <img v-else src="@/assets/img/wallet/enUS_add_to_google_wallet_add-wallet-badge.svg" alt="Add to Google Wallet" class="wallet-button-style"/>
          </a>
        </div>
      </div>
      <!-- Member Infos-->
      <div class="col-12 col-md-7 flex-shrink-1 mt-md-0 membercard-info" :class="{'mt-4': !addToGoogleWalletJWT}">
        <div>
          <strong class="d-block">{{ $t('club', isActiveClubMember ? clubMemberships.length : 1) }}</strong>
          <ul class="list-unstyled" v-if="isActiveClubMember">
            <li v-for="(club, index) in clubMemberships" :key="index">{{ club }}</li>
          </ul>
          <em class="text-muted" v-else>
            {{ $t('section.membership.noClub') }}
          </em>
        </div>

        <div v-if="associationMembership.length > 0">
          <strong class="d-block mt-4">{{ $t('association', associationMembership.length) }}</strong>
          <ul class="list-unstyled">
            <li v-for="(association, index) in associationMembership" :key="index">
              {{ association }}
            </li>
          </ul>
        </div>

        <strong class="d-block mt-4 mb-2" v-if="practisedSports && practisedSports.length > 0">
          {{ $t('sport.title', practisedSports.length) }}
        </strong>
        <div class="container-fluid row overflow-auto p-0 m-0">
          <div class="col d-flex gap-3 flex-nowrap align-items-stretch p-0 mb-3">
            <card-container card-class="text-center sports-card" v-for="sport in practisedSports" :key="sport">
              <template #content>
                <svg width="60" height="60">
                  <use :xlink:href="require('@/assets/img/sport-icons.svg') + '#' + sport"/>
                </svg>
                <small>{{ $t('sport.' + sport) }}</small>
              </template>
            </card-container>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12" v-if="isActiveMember">
            <membership-card :card-title="$t('membership.sportsInsurance.title')" icon="bi-dribbble" card-type="insurance">
              <div class="d-flex py-2">
                <div class="col-6">
                  <img src="@/assets/img/LOGO-2019-SVK-CAS__Konvertiert_.jpg" :alt="$t('membership.sportsInsurance.title')" class="img-fluid">
                </div>
              </div>
              <div class="d-flex py-2 mb-2">
                <div class="col-12">
                  <Translation keypath="membership.sportsInsurance.text" tag="p">
                    <template #link>
                      <a :href="$t('membership.sportsInsurance.infoLink')" target="_blank" class="text-primary d-block mt-3">{{ $t('membership.sportsInsurance.infoLinkText') }}</a>
                    </template>
                  </Translation>
                </div>
              </div>
            </membership-card>
          </div>
          <div class="col-12" v-if="sortedHonors.length > 0">
            <membership-card :card-title="$t('membership.voluntaryWorks')" icon="bi-gem" card-type="honorary">
              <div class="d-flex py-2 mb-2" v-for="(honor, index) in sortedHonors" :key="index">
                <div class="col-12">
                  <h6 class="mb-0">{{ honor.club ? honor.club : $t('section.member.honor') }}</h6>
                  {{ honor.description }} ({{ formatDate(honor.dateOfHonors) }})
                </div>
              </div>
            </membership-card>
          </div>
          <div class="col-12" v-if="competitiveSports">
            <membership-card :card-title="$t('membership.leistungsSportLizenz')" icon="bi-trophy" card-type="olympic">
              <div class="d-flex py-2 mb-2">
                <div class="col-3">
                  <svg width="60" height="60" :title="$t('sport.' + getSquadByKey(competitiveSports.kindOfSport))">
                    <use :xlink:href="require('@/assets/img/sport-icons.svg') + '#' + getSquadByKey(competitiveSports.kindOfSport)"/>
                  </svg>
                </div>
                <div class="col">
                  <h6>{{ competitiveSports.club }}</h6>
                  {{ competitiveSports.kindOfSport }} – {{ competitiveSports.year }}
                </div>
              </div>
            </membership-card>
          </div>

          <div class="col-12" v-if="currentlyValidBrevets && currentlyValidBrevets.length > 0">
            <membership-card :card-title="$t('membership.richterbrevet', currentlyValidBrevets.length)" icon="bi-clipboard" card-type="brevet">
              <div class="d-flex py-2 mb-2" v-for="(brevet, index) in currentlyValidBrevets" :key="index">
                <div class="col-3">
                  <svg width="60" height="60">
                    <use :xlink:href="require('@/assets/img/sport-icons.svg') + '#' + getSquadByKey(brevet.type)"/>
                  </svg>
                </div>
                <div class="col ps-2">
                  <h6 class="mb-0">{{ brevet.curse }}</h6>
                  <strong>{{ brevet.level }}</strong> <br>
                  {{ brevet.type }}
                </div>
              </div>
            </membership-card>
          </div>
          <div class="col-12" v-if="memberData.freeEntry">
            <membership-card :card-title="$t('membership.eintritt.panel')" icon="bi-ticket" card-type="entrance">
              <div class="d-flex py-2 mb-2">
                <div class="col">
                  <h6 class="mb-0">{{ $t('membership.eintritt.title') }}</h6>
                  {{ $t('membership.eintritt.text') }}
                </div>
              </div>
            </membership-card>
          </div>
        </div>

      </div>
    </div>

    <MembercardAds keywords="membercard"/>

    <template v-if="!isSingleMember">
      <hr class="mt-5">
      <div class="alert alert-info d-flex align-items-start">
        <i class="bi bi-info-circle me-3" style="font-size: 1.5rem; line-height: 1;"></i>
        <div v-html="$t('section.member.infobox')"/>
      </div>
      <ShowMembership :member-data="memberData"/>
      <hr>
      <ShowFunction :member-data="memberData"/>
      <hr>
      <ShowEducation :member-data="memberData"/>
    </template>
    <LoaderOverlay v-if="isLoading"/>
  </section-container>

  <section-container id="faq-info" class="p-0 mt-4">
    <FaqPanel class="m-0 border-0"/>
  </section-container>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {useMemberStore} from "@/store/modules/member/member.js";
import {getSquadByCode, getSquadByKey} from "@/helpers/functions";
import html2canvas from 'html2canvas';

import SectionContainer from "@/components/ui/SectionContainer";
import CardContainer from "@/components/ui/CardContainer";
import MembershipCard from "@/components/overview/MembershipCard";
import UserAvatar from "@/components/personal-data/UserAvatar";

import ShowMembership from "@/components/personal-data/ShowMembership";
import ShowFunction from "@/components/personal-data/ShowFunction";
import ShowEducation from "@/components/personal-data/ShowEducation";

import openPrintWindow from "@/helpers/print-single-card";
import DefaultButton from "@/components/ui/DefaultButton";
import FaqPanel from "@/components/ui/FaqPanel.vue";

import VueResizeText from "vue3-resize-text";
import LoaderOverlay from "@/components/ui/LoaderOverlay.vue";
import {Translation} from "vue-i18n";
import MembercardAds from "@/components/adbutler/MembercardAds.vue";
import moment from "moment/moment";

export default {
  data() {
    return {
      qrCode: null,
      isLoading: false,
      applePassLoading: false,
    }
  },
  props: {
    memberData: {
      type: Object,
      required: true,
    },
    cardTitle: {
      type: String,
      required: true
    },
    isSingleMember: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEmulatingMember: {
      type: Boolean,
      required: true,
    },
    isClubMember: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  components: {
    MembercardAds,
    LoaderOverlay,
    DefaultButton,
    SectionContainer,
    MembershipCard,
    CardContainer,
    ShowMembership,
    ShowFunction,
    ShowEducation,
    UserAvatar,
    FaqPanel,
    Translation,
  },
  directives: {
    ResizeText: VueResizeText.ResizeText,
  },
  methods: {
    async printMemberCard() {
      await openPrintWindow(this.memberData, this.memberClass, this.clubMemberships, this.associationMembership, this.practisedSports, this.isActiveMember, []);
    },
    saveCardAsImage() {
      this.isLoading = true;

      const cardContainer = document.querySelector('#member-card');

      const membercard = document.querySelector('#member-card-inner');
      const membercardClone = membercard.cloneNode(true);
      membercardClone.removeAttribute('id');
      const clonedAvatar = membercardClone.querySelector('#card-avatar');

      const stvLogo = require('@/assets/img/stv-fsg.png');
      const stvLogoHTML = `<img src="${stvLogo}" alt="STV FSG" style="width: 75px; height: auto;">`;
      if (clonedAvatar) {
        clonedAvatar.removeAttribute('id');
        clonedAvatar.style.background = '#fff';
        clonedAvatar.innerHTML = stvLogoHTML;
      } else {
        const stvAvatar = document.createElement('div');
        stvAvatar.classList.add('img-portrait-large', 'mt-3');
        stvAvatar.style.background = '#fff';
        stvAvatar.innerHTML = stvLogoHTML;
        membercardClone.prepend(stvAvatar);
      }

      cardContainer.removeChild(membercard);
      cardContainer.prepend(membercardClone);

      setTimeout(async () => {
        html2canvas(membercardClone, {
          width: membercardClone.width,
          height: membercardClone.height,
        }).then(canvas => {
          const link = document.createElement('a');
          link.download = this.memberData.firstName.split(' ').join('_') + '-' + this.memberData.surname.split(' ').join('_') + '.png';
          link.href = canvas.toDataURL('image/png');
          link.click();
        }).catch(error => {
          console.log('error drawing canvas', error);
        }).finally(() => {
          cardContainer.removeChild(membercardClone);
          cardContainer.prepend(membercard);
          this.isLoading = false;
        });
      }, 500);
    },
    async downloadApplePass() {
      this.applePassLoading = true;

      const response = await this.loadAppleWalletPass(this.generateWalletPayload());
      if (response.data && response.data.length > 0) {
        let responseData = atob(decodeURI(response.data));
        let n = responseData.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = responseData.charCodeAt(n);
        }

        let fileURL = window.URL.createObjectURL(new File([ u8arr ], '', {type: 'application/vnd.apple.pkpass'}));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', this.replaceUmlauts(this.memberData.firstName) + '_' + this.replaceUmlauts(this.memberData.surname) + '_mystv.pkpass');
        document.body.appendChild(fileLink);
        fileLink.click();
      }
      this.applePassLoading = false;
    },
    generateWalletPayload() {
      let walletPayload = {memberClass: this.memberClass};
      if (this.isEmulatingMember && this.releaseMemberData) {
        walletPayload.releaseMemberId = this.releaseMemberData.memberId;
      } else if (this.isSingleMember) {
        walletPayload.reducedMemberId = this.memberData.memberId;
      }
      if (this.clubId) {
        walletPayload.clubId = this.clubId
      }
      return walletPayload;
    },
    async initQrCodeForMember() {
      if (this.memberData.memberId) {
        const data = {
          memberId: this.memberData.memberId || '',
          firstName: this.memberData.firstName || '',
          lastName: this.memberData.surname || '',
          email: this.memberData.emailPrivate || '',
          dateOfBirth: this.memberData.birthday || '',
          sex: this.memberData.sex || '',
          gratisAccess: this.memberData.freeEntry || false,
          competitiveSport: this.competitiveSports !== null,
          competitiveSportDe: this.competitiveSports !== null ? this.$t('sport.' + getSquadByKey(this.competitiveSports.kindOfSport), 1, {locale: 'de'}) : '',
          competitiveSportFr: this.competitiveSports !== null ? this.$t('sport.' + getSquadByKey(this.competitiveSports.kindOfSport), 1, {locale: 'fr'}) : '',
          format: 'png'
        };
        this.qrCode = await this.loadQrCodeForMember(data);

        await this.loadGoogleWalletLink(this.generateWalletPayload());
      }
    },
    startupdateGoogleWalletPass() {
      if (this.addToGoogleWalletJWT) {
        this.updateGoogleWalletPass(this.generateWalletPayload());
      }
    },
    getSquadByKey,
    getSquadByCode,
    ...mapActions(useMemberStore, [
      "loadQrCodeForMember",
      "loadGoogleWalletLink",
      "updateGoogleWalletPass",
      "loadAppleWalletPass",
      "resetGoogleWalletPass",
    ])
  },
  watch: {
    memberData: {
      immediate: true,
      deep: true,
      handler() {
        this.resetGoogleWalletPass();
        this.initQrCodeForMember();
      },
    }
  },
  computed: {
    sortedHonors() {
      // eslint-disable-next-line
      return (this.memberData.honors && this.memberData.honors.length > 0) ? this.memberData.honors.sort(this.sortMethods(null, 'dateOfHonors', 1)) : [];
    },
    competitiveSports() {
      const currentYear = new Date().getFullYear();
      if (this.memberData.competitiveSports && this.memberData.competitiveSports.paidAt.length > 0) {
        return this.memberData.competitiveSports.year >= currentYear ? this.memberData.competitiveSports : null;
      }
      return null;
    },
    memberClass() {
      if (this.isHonorMember) {
        return 'honor-member';
      }
      if (this.isActiveClubMember) {
        return 'base-member';
      }
      if (this.isFunctionMember) {
        return 'function-member';
      }
      return 'inactive-member';
    },
    isHonorMember() {
      const stvHonors = [ 1, 3 ];
      if (this.memberData.honors && this.memberData.honors.length > 0) {
        // Nur Ehrungen für clubId 1 (Schweizerischer Turnverband)
        return this.memberData.honors.some(h => (stvHonors.indexOf(parseInt(h.honorId)) > -1) && h.clubId.trim() === "1");
      }
      return false
    },
    isActiveClubMember() {
      const activeMemberships = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 12, 13, 16, 17, 22, 28, 29 ];
      if (this.memberData.memberships) {
        return this.memberData.memberships.some(m => m.active && activeMemberships.indexOf(parseInt(m.category.code)) > -1);
      }
      return false;
    },
    isFunctionMember() {
      const functionMemberships = [ 17 ]
      if (this.memberData.memberships) {
        return this.memberData.memberships.some(m => functionMemberships.indexOf(parseInt(m.category.code)) > -1);
      }
      return false;
    },

    clubMemberships() {
      if (this.memberData.memberships) {
        return [ ...new Set(this.memberData.memberships.filter(m => m.active && m.verein !== null && m.verband !== null).flatMap(m => m.verein.description === null ? m.verein.code : m.verein.description)) ].sort((a, b) => {
          return a < b ? -1 : 1;
        });
      }
      return [];
    },
    associationMembership() {
      if (this.memberData.memberships) {
        return [
          ...new Set(this.memberData.memberships.filter(m => m.active && (m.verband !== null)).flatMap(m => m.verband.description))
        ].sort();
      }
      return [];
    },
    isActiveMember() {
      if (this.memberData.memberships) {
        return this.memberData.memberships.filter(membership => membership.active).length > 0;
      }
      return false;
    },
    practisedSports() {
      if (this.isEmulatingMember && this.releaseMemberData) {
        return this.releaseMemberSports;
      } else if (this.isSingleMember) {
        return this.singleMemberSports
      }
      return this.sports;
    },
    currentlyValidBrevets() {
      const currentDate = this.formatDate(new Date(), 'YYYY-MM-DD');
      return this.memberData.educations.filter(e => this.dateValid(e.brevetValidUntil) && moment(e.brevetValidUntil).isSameOrAfter(currentDate));
    },
    ...mapState(useMemberStore, {
      addToGoogleWalletJWT: "getGoogleWalletLink",
      releaseMemberData: "getReleaseMemberData",
      sports: "getSportsData",
      singleMemberSports: "getSingleMemberSportsData",
      releaseMemberSports: "getReleaseMemberSportsData",
    }),
  },
  mounted() {
    this.clubId = this.$route.query.clubId;
  }
};
</script>

<style lang="scss">
.wallet-buttons {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  a {
    flex: 0 0 48%;
    position: relative;
    opacity: 1;
    transition: opacity 500ms;

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: .05;
    }

    > img {
      width: 100%;
    }
  }
}
</style>
