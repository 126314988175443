<template>
  <div :class="'img-'+ avatarClass" v-if="profileImage">
    <img :src="profileImage" alt="Avatar" :id="avatarId">
    <slot></slot>
  </div>
  <div v-else-if="!profileImage && isNavigation" class="d-none d-lg-block d-xl-none">
    <i class="bi bi-person-circle"></i>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useProfileStore } from "@/store/modules/profile/profile.js";

export default {
  props: {
    avatarClass: {
      type: String,
      required: false,
      default: 'portrait-small'
    },
    avatarId: {
      type: String,
      required: false
    },
    isNavigation: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    ...mapState(useProfileStore, {
      profileImage: "getProfileImage"
    })
  },
}
</script>
