<template>
  <section class="mystv-container" :class="additionalClass">
    <slot></slot>
  </section>
</template>

<script>
export default {
  props: {
    additionalClass: {
      type: String,
      required: false,
    }
  }
}
</script>
