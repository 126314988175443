<template>
  <MetaNavigation/>
  <section-container id="clubMemberSection" additional-class="membercard-container py-4">
    <div class="col-12">
      <h1>{{ vereinName }}</h1>
      <h6 class="text-secondary mt-5" v-if="verbandName">{{ $t('association') }}</h6>{{ verbandName }}
      <hr class="my-5">
      <h4>{{ $t('section.clubMembers.members') }}</h4>

      <div class="table-responsive">
        <table class="table" id="clubMembersTable" v-if="filteredMembers">
          <thead>
          <tr>
            <th class="border-0" v-if="isSelectable"></th>
            <th scope="col" class="border-0" :class="{'text-primary': sortKey.name === 'surname', 'cursor-pointer': isSortable}">
              <span @click="sortBy({name: 'surname', type: 'String'})">{{ $t('personalData.person.surname') }}
                <i class="bi" :class="sortOrder === -1 ? 'bi-sort-down' : 'bi-sort-up'" v-if="sortKey.name === 'surname'"></i>
              </span>
            </th>
            <th scope="col" class="border-0" :class="{'text-primary': sortKey.name === 'firstName', 'cursor-pointer': isSortable}">
              <span @click="sortBy({name: 'firstName', type: 'String'})">{{ $t('personalData.person.firstName') }}
                <i class="bi" :class="sortOrder === -1 ? 'bi-sort-down' : 'bi-sort-up'" v-if="sortKey.name === 'firstName'"></i>
              </span>
            </th>
            <th scope="col" class="border-0" :class="{'text-primary': sortKey.name === 'birthday', 'cursor-pointer': isSortable}">
              <span @click="sortBy({name: 'birthday', type: 'Date'})">{{ $t('personalData.person.birthday') }}
                <i class="bi" :class="sortOrder === -1 ? 'bi-sort-down' : 'bi-sort-up'" v-if="sortKey.name === 'birthday'"></i>
              </span>
            </th>
            <th scope="col" class="border-0" :class="{'text-primary': sortKey.name === 'squads', 'cursor-pointer': isSortable}">
              <span @click="sortBy({name: 'squads', type: 'Array'})">{{ $t('personalData.person.squads') }}
                <i class="bi" :class="sortOrder === -1 ? 'bi-sort-down' : 'bi-sort-up'" v-if="sortKey.name === 'squads'"></i>
              </span>
            </th>
            <th scope="col" class="border-0" :class="{'text-primary': sortKey.name === 'onboardingStatus', 'cursor-pointer': isSortable}">
              <span @click="sortBy({name: 'onboardingStatus', type: 'String'})">{{ $t('section.clubMembers.status.title') }}
                <i class="bi" :class="sortOrder === -1 ? 'bi-sort-down' : 'bi-sort-up'" v-if="sortKey.name === 'onboardingStatus'"></i>
              </span>
            </th>
          </tr>
          <tr>
            <th class="ps-1" v-if="isSelectable"><input type="checkbox" v-model="selectAll"></th>
            <th class="pt-0"><input type="search" id="surname-filter" class="form-control input-filter" :placeholder="$t('section.clubMembers.filter', {filter: $t('personalData.person.surname')})" v-model="surnameFilter"></th>
            <th class="pt-0"><input type="search" id="firstName-filter" class="form-control input-filter" :placeholder="$t('section.clubMembers.filter', {filter: $t('personalData.person.firstName')})" v-model="firstNameFilter"></th>
            <th class="pt-0"></th>
            <th class="pt-0"><input type="search" id="squad-filter" class="form-control input-filter" :placeholder="$t('section.clubMembers.filter', {filter: $t('personalData.person.squads')})" v-model="squadFilter"></th>
            <th class="pt-0">
              <button class="btn btn-outline-secondary input-filter-button" @click="resetFilters" v-if="filtered"><i class="bi bi-x-lg"></i></button>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr :class="{'cursor-pointer': isSelectable, 'table-active': selectedMembers.findIndex(m => m.memberId === member.memberId) !== -1}"
              v-for="(member, index) in displayedMembers"
              :key="index"
              @click="isSelectable && toggleSelect(member)">
            <td v-if="isSelectable" class="ps-1"><input type="checkbox" :value="member" v-model="selectedMembers"></td>
            <td class="table-cell-link">
              <router-link :to="{name: 'singleMember', query: {memberId: member.memberId, clubId: currentClubId}}" class="table-link" :class="{'disabled' : isSelectable}">
                {{ formatEntry('String', member.surname) }}
              </router-link>
            </td>
            <td class="table-cell-link">
              <router-link :to="{name: 'singleMember', query: {memberId: member.memberId, clubId: currentClubId}}" class="table-link" :class="{'disabled' : isSelectable}">
                {{ formatEntry('String', member.firstName) }}
              </router-link>
            </td>
            <td class="table-cell-link">
              <router-link :to="{name: 'singleMember', query: {memberId: member.memberId, clubId: currentClubId}}" class="table-link" :class="{'disabled' : isSelectable}">
                {{ formatEntry('Date', member.birthday) }}
              </router-link>
            </td>
            <td class="table-cell-link">
              <router-link :to="{name: 'singleMember', query: {memberId: member.memberId, clubId: currentClubId}}" class="table-link" :class="{'disabled' : isSelectable}">
                {{ formatEntry('Array', member.squads) }}
              </router-link>
            </td>
            <td class="table-cell-link">
              <a class="table-link">
                <i class="bi" :class="[getStatusIcon(member.onboardingStatus.toLowerCase()), 'text-' + member.onboardingStatus.toLowerCase()]" data-bs-toggle="tooltip" :data-bs-original-title="$t('section.clubMembers.status.' + member.onboardingStatus)" :aria-label="$t('section.clubMembers.status.' + member.onboardingStatus)" :title="$t('section.clubMembers.status.' + member.onboardingStatus)"></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table-navigation">
        <nav aria-label="Member Table Navigation">
          <div class="w-100 text-center alert-info mb-3" v-if="displayedMembers === undefined || displayedMembers === null || displayedMembers.length===0">
            {{ $t('section.clubMembers.noMembersFound') }}
          </div>
          <ul class="pagination justify-content-end align-items-center m-0">
            <li class="page-item disabled flex-grow-1 text-muted">
              {{ $t('section.clubMembers.pagination.info', {from: ((page * perPage) - (perPage - 1)), to: (page < pages.length ? (page * perPage) : sortedMembers.length), total: sortedMembers.length}) }}
            </li>
            <li class="page-item disabled flex-grow-1 text-muted ms-3" v-if="isSelectable">
              {{ $t('section.clubMembers.selected', selectedMembers.length) }}
            </li>
            <li class="page-item" :class="page === 1 ? 'disabled' : ''">
              <button type="button" class="page-link" :disabled="page === 1" @click="page = 1"><i class="bi bi-arrow-bar-left"></i></button>
            </li>
            <li class="page-item" :class="page === 1 ? 'disabled' : ''">
              <button type="button" class="page-link" :disabled="page === 1" @click="page--"><i class="bi bi-arrow-left"></i></button>
            </li>
            <li class="page-item disabled text-muted px-2 d-none d-md-inline">
              {{ $t('section.clubMembers.pagination.page', {page: page, totalPages: pages.length}) }}
            </li>
            <li class="page-item" :class="page >= pages.length ? 'disabled' : ''">
              <button type="button" class="page-link" :disabled="page >= pages.length" @click="page++"><i class="bi bi-arrow-right"></i></button>
            </li>
            <li class="page-item" :class="page >= pages.length ? 'disabled' : ''">
              <button class="page-link" :disabled="page >= pages.length" @click="page = pages.length"><i class="bi bi-arrow-bar-right"></i></button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="w-100 text-center">
        <button
          type="button"
          class="btn btn-outline-secondary"
          :class="{'me-2': isSelectable}"
          @click="showSelection">
          {{ isSelectable ? $t('cancel') : $t('select') }}
        </button>
        <div class="btn-group" v-if="isSelectable">
          <button class="btn btn-outline-secondary dropdown-toggle"
                  type="button"
                  id="printDropdownToggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  :class="{'disabled': isSelectable && selectedMembers.length < 1}"
                  :disabled="isSelectable && selectedMembers.length < 1">
            {{ $t('print') }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="printDropdownToggle">
            <li><a href="#" class="dropdown-item" @click.prevent="printSingleCards">{{ $t('section.clubMembers.printSingleCard') }}</a></li>
            <li><a href="#" class="dropdown-item" @click.prevent="printCards">{{ $t('section.section.clubMembers.printCollection') }}</a></li>
          </ul>
        </div>
      </div>
    </div>
    <LoaderOverlay v-if="isLoading"/>
  </section-container>

  <section-container id="faq-info" class="p-0 mt-4">
    <FaqPanel class="m-0 border-0"/>
  </section-container>

</template>

<script>
import {mapActions, mapState} from "pinia";
import {SINGLE_MEMBER_PAGE} from "@/router/urls";

import {Tooltip} from "bootstrap";

import MetaNavigation from '@/components/navigation/MetaNavigation';
import SectionContainer from "@/components/ui/SectionContainer";

import {openPrintWindow, openSingleCardsPrintWindow} from '@/helpers/print-multiple-cards';

import {useMemberStore} from "@/store/modules/member/member.js";
import FaqPanel from "@/components/ui/FaqPanel.vue";
import LoaderOverlay from "@/components/ui/LoaderOverlay.vue";

export default {
  components: {
    LoaderOverlay,
    FaqPanel,
    MetaNavigation,
    SectionContainer,
  },
  data() {
    return {
      columns: [
        {name: 'surname', type: 'String'},
        {name: 'firstName', type: 'String'},
        {name: 'birthday', type: 'Date'},
        {name: 'squads', type: 'Array'}
      ],
      showFilters: false,
      // page: 1,
      perPage: 10,
      isSelectable: false,
      isSortable: true,
      selectedMembers: [],
      memberLink: SINGLE_MEMBER_PAGE,
      isLoading: false,
    };
  },
  computed: {
    filtered() {
      return (this.squadFilter.length > 0 || this.firstNameFilter.length > 0 || this.surnameFilter.length > 0);
    },
    sortKey: {
      get() {
        return this.clubMemberSortKey;
      },
      set(value) {
        this.setClubMemberSortKey(value);
      },
    },
    sortOrder: {
      get() {
        return this.clubMemberSortOrder;
      },
      set(value) {
        this.setClubMemberSortOrder(value);
      },
    },
    sortedMembers() {
      const type = this.sortKey.type;
      const sortOrder = this.sortOrder;
      const column = this.sortKey.name;

      // eslint-disable-next-line
      return (this.filteredMembers && this.filteredMembers.length > 0) ? (column === 'onboardingStatus' ? this.sortByStatus(sortOrder) : this.filteredMembers.sort(this.sortMethods(type, column, sortOrder))) : [];
    },
    squadFilter: {
      get() {
        if (this.clubMemberFilter.squad) {
          return this.clubMemberFilter.squad;
        }
        return '';
      },
      set(value) {
        this.page = 1
        this.setClubMemberFilter({
          squad: value,
          firstName: this.firstNameFilter,
          surname: this.surnameFilter
        });
      },
    },
    firstNameFilter: {
      get() {
        if (this.clubMemberFilter.firstName) {
          return this.clubMemberFilter.firstName;
        }
        return ''
      },
      set(value) {
        this.setClubMemberFilter({
          firstName: value,
          surname: this.surnameFilter,
          squad: this.squadFilter,
          page: 1,
        });
      },
    },
    surnameFilter: {
      get() {
        if (this.clubMemberFilter.surname) {
          return this.clubMemberFilter.surname;
        }
        return ''
      },
      set(value) {
        this.setClubMemberFilter({
          surname: value,
          firstName: this.firstNameFilter,
          squad: this.squadFilter,
          page: 1,
        });
      },
    },
    page: {
      get() {
        if (this.clubMemberFilter.page) {
          return this.clubMemberFilter.page;
        }
        return 1;
      },
      set(value) {
        this.setClubMemberFilter({
          firstName: this.firstNameFilter,
          surname: this.surnameFilter,
          squad: this.squadFilter,
          page: value
        })
      },
    },
    filteredMembers() {
      return this.filterByLastName(
        this.filterByFirstName(
          this.filterBySquad(
            this.clubMembers
          )
        )
      );
    },
    selectAll: {
      get() {
        return this.filteredMembers ? this.selectedMembers.length === this.filteredMembers.length : false;
      },
      set(checked) {
        let selected = [];
        if (checked) {
          this.filteredMembers.forEach(m => {
            selected.push(m);
          });
        }
        this.selectedMembers = selected;
      },
    },
    displayedMembers() {
      return this.paginate(this.sortedMembers);
    },
    pages() {
      const numOfPages = this.filteredMembers ? Math.ceil(this.filteredMembers.length / this.perPage) : 0;
      let pages = [];
      for (let i = 1; i <= numOfPages; i++) {
        pages.push(i);
      }
      return pages;
    },
    vereinName() {
      let club = null;
      if (this.memberData.functions) {
        club = this.memberData.functions.find(f => {
          return !!f && f.organId === this.currentClubId;
        });
      }

      return club != null && club.organ !== null ? club.organ : '';
    },
    verbandName() {
      let membership = null;
      if (this.memberData.memberships) {
        membership = this.memberData.memberships.find(m => {
          return !!m.verein && m.verein.code === this.currentClubId
        });
      }
      return membership != null && membership.verband !== null ? membership.verband.description : '';
    },
    ...mapState(useMemberStore, {
      clubMembers: "getClubMembers",
      memberData: "getMemberData",
      clubMemberFilter: "getClubMemberFilter",
      clubMemberSortKey: "getClubMemberSortKey",
      clubMemberSortOrder: "getClubMemberSortOrder",
      currentClubId: "getCurrentClubId",
    }),
  },
  methods: {
    getStatusIcon(memberStatus) {
      switch (memberStatus) {
        case 'initiated':
        case 'offboard':
        case 'pending':
        case 'reminded':
          return 'bi-circle-fill';
        case 'email_missing':
        case 'email_duplicate':
          return 'bi-exclamation-triangle-fill';
        case 'quarantine':
          return 'bi-wrench-adjustable-circle-fill';
        default:
          return 'bi-circle-fill';
      }
    },
    filterBySquad(members) {
      return this.squadFilter ? members.filter(m => m.squads.some(s => !s.description.toLowerCase().indexOf(this.squadFilter.toLowerCase()))) : members;
    },
    filterByFirstName(members) {
      return this.firstNameFilter ? members.filter(m => (!m.firstName.toLowerCase().indexOf(this.firstNameFilter.toLowerCase()))) : members;
    },
    filterByLastName(members) {
      return this.surnameFilter ? members.filter(m => (!m.surname.toLowerCase().indexOf(this.surnameFilter.toLowerCase()))) : members;
    },
    resetFilters() {
      this.squadFilter = '';
      this.firstNameFilter = '';
      this.surnameFilter = '';
      this.setClubMemberSortKey({name: 'surname', type: 'String'});
      this.setClubMemberSortOrder(1);
      this.page = 1;
    },
    sortBy(column) {
      if (this.sortKey.name === column.name) {
        this.sortOrder *= -1;
      } else {
        this.sortOrder = 1;
      }
      this.sortKey = column;
    },
    sortByStatus(direction) {
      const statusScores = {'ONBOARD': 1, 'PENDING': 2, 'REMINDED': 3, 'QUARANTINE': 4, 'EMAIL_DUPLICATE': 5, 'EMAIL_INVALID': 6, 'EMAIL_MISSING': 7, 'OFFBOARD': 8, 'OPT_OUT': 9};
      return this.filteredMembers.sort((m1, m2) => {
        if (direction === 1) {
          return statusScores[m1.onboardingStatus] - statusScores[m2.onboardingStatus];
        }
        return statusScores[m2.onboardingStatus] - statusScores[m1.onboardingStatus];
      });
    },
    paginate(members) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return members.slice(from, to);
    },
    formatEntry(type, value) {
      switch (type) {
        case 'Date':
          return this.formatDate(value);
        case 'Array':
          return [ ...new Set(Array.prototype.map.call(value, s => s.description)) ].join(', ');
        default:
          return value;
      }
    },
    showSelection() {
      this.isSelectable = !this.isSelectable;
      if (!this.isSelectable) {
        this.selectedMembers = [];
      }
    },
    async toggleSelect(member) {
      let index = this.selectedMembers.findIndex(m => m.memberId === member.memberId);
      if (index > -1) {
        this.selectedMembers.splice(this.selectedMembers.findIndex(mem => mem.memberId === member.memberId), 1);
      } else {
        this.selectedMembers.push(member);
      }
      this.selectedMembers.sort((a, b) => a.surname > b.surname ? 1 : -1);
    },
    printCards() {
      this.isLoading = true;
      openPrintWindow(this.selectedMembers, this.vereinName, this.verbandName, []).then(() => {
        this.isLoading = false;
      });
    },
    printSingleCards() {
      this.isLoading = true;
      openSingleCardsPrintWindow(this.selectedMembers, this.vereinName, this.verbandName, []).then(() => {
        this.isLoading = false;
      });
    },
    reloadClubMembers(clubId) {
      if (clubId !== this.currentClubId) {
        this.isLoading = true;
        this.setCurrentClubId(clubId);
        this.setCurrentClubName(this.vereinName);
        this.loadClubMembers(clubId).then(() => {
          this.setClubMemberFilter(null);
          this.setClubMemberSortKey(null);
          this.setClubMemberSortOrder(null);
          this.isLoading = false;
        });
      }
    },
    ...mapActions(useMemberStore, [
      "loadClubMembers",
      "setClubMemberFilter",
      "setClubMemberSortKey",
      "setClubMemberSortOrder",
      "setCurrentClubId",
      "setCurrentClubName"
    ])
  },
  watch: {
    '$route.query': {
      async handler() {
        if (this.$route.query.clubId && (this.$route.query.clubId !== this.currentClubId)) {
          this.reloadClubMembers(this.$route.query.clubId);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {

    this.selectedMembers.length > 0 ? this.isSelectable = true : this.isSelectable = false;

    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },
};
</script>
