import {createI18n} from "vue-i18n";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

function getLocale() {
  const userLanguage = getLanguageFromUrl();
  if(userLanguage != null) {
    localStorage.setItem("lang", userLanguage);
    return localStorage.getItem('lang');
  } else if (localStorage.getItem('lang')) {
    return localStorage.getItem('lang');
  } else {
    const availableLocales = window.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');
    const browserLanguage = window.navigator.language.substring(0, 2);
    if (!localStorage.getItem('lang') && availableLocales.includes(browserLanguage)) {
      return browserLanguage;
    }
  }
  return window.VUE_APP_I18N_LOCALE || 'de';
}

function getLanguageFromUrl() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if(urlParams.has("lang")) {
    return urlParams.get("lang");
  }
  return null;
}

const instance = createI18n({
  locale: getLocale(),
  fallbackLocale: window.VUE_APP_I18N_FALLBACK_LOCALE || "fr",
  messages: loadLocaleMessages(),
  legacy: false
});

export default instance;

export const i18n = instance.global;
