<template>
    <profile-layout :title="memberData.firstName + ' ' + memberData.surname + ' / ' + $t('profile.security.edit')" :is-loading="isSubmitting">
      <template #content>
        <div class="row mb-4 align-items-end">
          <div class="col-12 flex align-self-baseline text-hyphens"> 
            <p class="form-label">{{ $t('profile.security.explanation.title') }}</p>
            <p>{{ $t('profile.security.explanation.text') }}</p>
          </div>
        </div>

        <div class="row mb-4 align-items-end">
          <div class="table-responsive">
            <table class="table table-hover" id="devicesTable">
              <thead>
                <tr>
                  <th scope="col" class="border-0 text-center text-black">
                    #
                  </th>
                  <th scope="col" class="border-0 text-black">
                    <span >{{ $t('profile.security.deviceName.label') }}
                    </span>
                  </th>
                  <th scope="col" class="border-0 text-right text-black">
                    <router-link :to="{name: 'add-device'}" class="btn btn-outline-dark" v-if="devices !== undefined && devices !== null && devices.length===0">
                      {{ $t('profile.security.devices.add') }}
                    </router-link>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(device, index) in devices"
                    :key="index">
                  <td class="align-middle text-center">
                    {{ index + 1 }}
                  </td>
                  <td class="align-middle">
                    {{ device.label }}
                  </td>
                  <td class="align-middle text-right">
                    <DefaultButton 
                    :label="$t('profile.security.devices.delete')"
                    @click="selectDevice(device)"
                    @keyup.enter="selectDevice(device)"
                    class="btn-outline-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    >
                  </DefaultButton>
                  </td>
                </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="5" class="pb-0">
                  <nav aria-label="Member Table Navigation">
                    <div class="w-100 text-center alert-info mb-3" v-if="devices === undefined || devices === null || devices.length===0">
                      {{ $t('profile.security.devices.noDevicesFound') }}
                    </div>
                  </nav>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div id="toastWrapper" class="position-fixed bottom-0 end-0 p-3" style="z-index: 1000;"></div>


        <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="$t('profile.security.device.delete.title')" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">{{ $t('profile.security.device.delete.title') }}</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" :aria-label="$t('profile.security.device.delete.cancel')"></button>
              </div>
              <div class="modal-body">
                <Translation keypath="profile.security.device.delete.text" tag="p">
                  {{ deviceName }}
                </Translation>
              </div>
              <div class="modal-footer">
                <DefaultButton 
                  :label="$t('profile.security.device.delete.cancel')"
                  @click="cancelSelection"
                  @keyup.enter="cancelSelection"
                  class="btn-outline-dark"
                  data-bs-dismiss="modal"
                  >
                </DefaultButton>

                <DefaultButton 
                  :label="$t('profile.security.device.delete.confirm')"
                  @click="deleteSelectedDevice"
                  @keyup.enter="deleteSelectedDevice"
                  class="btn-outline-danger"
                  data-bs-dismiss="modal"
                  >
                </DefaultButton>
              </div>
            </div>
          </div>
        </div>

      </template>
    </profile-layout>
  </template>
  
  <script>
  import {mapActions, mapState} from "pinia";
  import {useMemberStore} from "@/store/modules/member/member.js";
  import {useLoginStore} from "@/store/modules/login/login.js";
  import ProfileLayout from "@/components/profile/ProfileLayout";
  import DefaultButton from '@/components/ui/DefaultButton.vue';
  import {Toast} from "bootstrap";
  import {Translation} from 'vue-i18n'
  
  export default {
    data() {
      return {
        hasDevice: false,
        isLoading: false,
        showToast: false,
        successMessage: '',
        deviceId: null,
        deviceName: null,
        isSubmitting: false
      };
    },
    computed: {
      ...mapState(useMemberStore, {memberData: "getMemberData", devices: "getTwoFactorDevices"})
    },
    methods: {
      createToastMessage(type, message) {
        const toastWrapper = document.getElementById('toastWrapper');
        const toastMessage = document.createElement('div');
        toastMessage.setAttribute('id', 'toastMessage');
        toastMessage.setAttribute('role', 'alert');
        toastMessage.setAttribute('aria-live', 'assertive');
        toastMessage.setAttribute('aria-atomic', 'true');
        toastMessage.classList.add('toast', 'align-items-center', 'text-white', type);
        toastMessage.innerHTML = `<div class="d-flex"><div class="toast-body">${message}</div><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div>`;
        toastWrapper.appendChild(toastMessage);
      },
      selectDevice(device) {
        this.deviceId = device.otpId;
        this.deviceName = device.label
      },
      cancelSelection() {
        this.deviceId = null;
        this.deviceName = null;
      },
      deleteSelectedDevice() {
        this.isSubmitting = true;

        this.deleteDevice(
          this.deviceId
        ).then(async response => {
          if(response && response.status === 200) {
            this.cancelSelection();
            this.createToastMessage('bg-success', this.$t('profile.security.device.delete.success'));
            const toastMessage = document.getElementById('toastMessage');
            const toast = new Toast(toastMessage);
            toast.show();

            await this.load2FADevices();
          }  else {
            this.createToastMessage('bg-danger', this.$t('profile.security.device.delete.failure'));
            const toastMessage = document.getElementById('toastMessage');
            const toast = new Toast(toastMessage);
            toast.show();
          }
        }).catch(() => {
            this.createToastMessage('bg-danger', this.$t('profile.security.device.delete.failure'));
            const toastMessage = document.getElementById('toastMessage');
            const toast = new Toast(toastMessage);
            toast.show();
        }).finally(() => {
          this.isSubmitting = false;
          window.scrollTo(0, 0);

        });
      },
      ...mapActions(useLoginStore, ["changePassword"]),
      ...mapActions(useMemberStore, ["load2FAInfo", "load2FADevices", "deleteDevice"]),
    },
    beforeMount() {
      this.load2FAInfo(this.memberData.memberId);
      this.load2FADevices(this.memberData.memberId)
      this.showToast = (this.$route.params.showToast);
      this.successMessage = this.$route.params.message;
    },
    mounted() {
      if (this.showToast) {
        this.createToastMessage(this.$route.params.type, this.$route.params.message);
        const toastMessage = document.getElementById('toastMessage');
        const toast = new Toast(toastMessage);
        toast.show();
      }
    },
    components: {
      ProfileLayout,
      DefaultButton,
      Translation
    }
  }
  </script>
  
  <style scoped lang="scss">
  .alert {
    margin-top: .5em;
    padding: .5em 1em;
  }
  
  .alert.alert-success {
    color: #4E824E;
    background-color: #E3FFE3;
    border-color: #99D199;
  }
  
  .bi {
    width: 1em;
    height: 1em;
    fill: currentcolor;
    margin-right: .5em
  }
  </style>
  
  