import QrCodeApiClient from "@/api/QrCodeApiClient";
import {getSquadByKey} from "@/helpers/functions";
import i18n from "@/i18n.js";

// eslint-disable-next-line no-unused-vars
const {global: {locale, t},} = i18n;

const QRCODE_BASE_PATH = '/api/qrcode';

async function generateQrCode(memberData) {
  return QrCodeApiClient.getRequest(QRCODE_BASE_PATH + '/picture/1000', {
    memberId: memberData.memberId || '',
    firstName: memberData.firstName || '',
    lastName: memberData.surname || '',
    email: memberData.emailPrivate || '',
    dateOfBirth: memberData.birthday || '',
    sex: memberData.sex || '',
    gratisAccess: memberData.freeEntry || false,
    competitiveSport: (memberData.competitiveSport !== null && memberData.competitiveSport !== undefined) || false,
    competitiveSportDe: (memberData.competitiveSports !== null && memberData.competitiveSport !== undefined) ? t('sport.' + getSquadByKey(memberData.competitiveSports.kindOfSport), 1, { locale: 'de' }): '',
    competitiveSportFr: (memberData.competitiveSports !== null && memberData.competitiveSport !== undefined) ? t('sport.' + getSquadByKey(memberData.competitiveSports.kindOfSport), 1, { locale: 'fr' }): '',
    format: 'png'
  });
}

export default generateQrCode;
