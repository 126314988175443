import app from "@/globals";
import i18n from "@/i18n.js";
import pinia from "@/store/index.js"
import router from "@/router/index.js";
import logger from "@/logger/index.js";
import keycloak from "@/keycloak.js"

import "bootstrap";
import "bootstrap/scss/bootstrap.scss";
import 'bootstrap-icons/font/bootstrap-icons.scss';

import "@/registerServiceWorker";
import DefaultButton from "@/components/ui/DefaultButton";
import CardContainer from "@/components/ui/CardContainer";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";

import withUUID from "vue-uuid";

const initializedApp = app;

initializedApp.component('DefaultButton', DefaultButton);
initializedApp.component('CardContainer', CardContainer);
initializedApp.component('BootstrapToast', BootstrapToast);

initializedApp.use(withUUID);
initializedApp.use(i18n);
initializedApp.use(router);
initializedApp.use(pinia);
initializedApp.use(keycloak);
initializedApp.use(logger);
initializedApp.mount("#app");
